.confirm-account-initial-pages-container {
  width: 546px;
  height: 478px;
  padding: 48px 55px 48px 55px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}

.confirm-account-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.image-confirm-account {
  width: 106px;
  height: 106px;
  margin-top: 0;
  margin-bottom: 0;
}

.confirm-account-container h3 {
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
}

#title-success {
  margin-top: 47px;
  margin-bottom: 25px;
}

#title-error {
  margin-top: 24px;
  margin-bottom: 24px;
  color: #e74c3c;
}

.text-message {
  width: 100%;
  text-align: center;
}

.text-message-margin-bottom {
  margin: 0 0 100px 0;
}

.text-message p {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

#message-first {
  margin-bottom: 20px;
}

#message-second {
  margin-bottom: 47px;
}

.login-signup-btn-container-link {
  margin-top: 0;
  margin-bottom: 0;
  width: 116px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #0e0333;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 15px 32px;
}

.login-signup-btn-container-link a {
  text-decoration: none;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.login-signup-btn-container {
  margin-top: 0;
  margin-bottom: 0;
}

.btn-confirmation-account {
  width: 266px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #0e0333;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 15px 32px;
  outline: none;
  border: none;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
}

// rules outside of @media queries apply to devices 1600px and above
// rules here apply to devices from 1024px to 1600px (inclusive)
@media only screen and (max-width: 1600px) {
  .confirm-account-initial-pages-container {
    width: 446px;
    height: 378px;
    padding: 38px 45px 38px 45px;
  }

  .image-confirm-account {
    width: 96px;
    height: 96px;
  }

  .confirm-account-container h3 {
    font-size: 21px;
  }

  #title-success {
    margin-top: 37px;
    margin-bottom: 15px;
  }

  #title-error {
    margin-top: 14px;
    margin-bottom: 14px;
  }

  .text-message-margin-bottom {
    margin: 0 0 58px 0;
  }

  .text-message p {
    font-size: 15px;
  }

  #message-first {
    margin-bottom: 10px;
  }

  #message-second {
    margin-bottom: 37px;
  }

  .login-signup-btn-container-link {
    width: 106px;
    height: 42px;
    padding: 10px 15px;
  }

  .login-signup-btn-container-link a {
    font-size: 15px;
  }

  .btn-confirmation-account {
    width: 256px;
    height: 42px;
    padding: 10px 15px;
    font-size: 15px;
  }
}

// rules here apply to devices from 0px to 1024px (inclusive)
@media only screen and (max-width: 1024px) {
  .confirm-account-initial-pages-container {
    width: 396px;
    height: 328px;
    padding: 28px 35px 28px 35px;
  }

  .image-confirm-account {
    width: 76px;
    height: 76px;
  }

  .confirm-account-container h3 {
    font-size: 20px;
  }

  #title-success {
    margin-top: 27px;
    margin-bottom: 5px;
  }

  #title-error {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .text-message-margin-bottom {
    margin: 0 0 40px 0;
  }

  .text-message p {
    font-size: 14px;
  }

  #message-first {
    margin-bottom: 5px;
  }

  #message-second {
    margin-bottom: 27px;
  }

  .login-signup-btn-container-link {
    width: 106px;
    height: 42px;
    padding: 5px 15px;
  }

  .login-signup-btn-container-link a {
    font-size: 14px;
  }

  .btn-confirmation-account {
    width: 256px;
    height: 42px;
    padding: 5px 15px;
    font-size: 14px;
  }
}

// tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .confirm-account-initial-pages-container {
    width: 446px;
    height: 378px;
    padding: 38px 45px 38px 45px;
  }

  .image-confirm-account {
    width: 86px;
    height: 86px;
  }

  .confirm-account-container h3 {
    font-size: 21px;
  }

  #title-success {
    margin-top: 37px;
    margin-bottom: 15px;
  }

  #title-error {
    margin-top: 14px;
    margin-bottom: 14px;
  }

  .text-message-margin-bottom {
    margin: 0 0 50px 0;
  }

  .text-message p {
    font-size: 15px;
  }

  #message-first {
    margin-bottom: 10px;
  }

  #message-second {
    margin-bottom: 37px;
  }

  .login-signup-btn-container-link {
    width: 106px;
    height: 42px;
    padding: 5px 15px;
  }

  .login-signup-btn-container-link a {
    font-size: 15px;
  }

  .btn-confirmation-account {
    width: 256px;
    height: 42px;
    padding: 5px 15px;
    font-size: 15px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .confirm-account-initial-pages-container {
    width: 446px;
    height: 378px;
    padding: 38px 45px 38px 45px;
  }

  .image-confirm-account {
    width: 86px;
    height: 86px;
  }

  .confirm-account-container h3 {
    font-size: 21px;
  }

  #title-success {
    margin-top: 37px;
    margin-bottom: 15px;
  }

  #title-error {
    margin-top: 14px;
    margin-bottom: 14px;
  }

  .text-message-margin-bottom {
    margin: 0 0 50px 0;
  }

  .text-message p {
    font-size: 15px;
  }

  #message-first {
    margin-bottom: 10px;
  }

  #message-second {
    margin-bottom: 37px;
  }

  .login-signup-btn-container-link {
    width: 106px;
    height: 42px;
    padding: 5px 15px;
  }

  .login-signup-btn-container-link a {
    font-size: 15px;
  }

  .btn-confirmation-account {
    width: 256px;
    height: 42px;
    padding: 5px 15px;
    font-size: 15px;
  }
}

// phone
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .confirm-account-initial-pages-container {
    width: 350px;
    height: 300px;
    padding: 30px 20px 30px 20px;
  }

  .image-confirm-account {
    width: 56px;
    height: 56px;
  }

  .confirm-account-container h3 {
    font-size: 20px;
  }

  #title-success {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  #title-error {
    margin-top: 14px;
    margin-bottom: 14px;
  }

  .text-message-margin-bottom {
    margin: 0 0 30px 0;
  }

  .text-message p {
    font-size: 14px;
  }

  #message-first {
    margin-bottom: 10px;
  }

  #message-second {
    margin-bottom: 27px;
  }

  .login-signup-btn-container-link {
    width: 106px;
    height: 42px;
    padding: 5px 15px;
  }

  .login-signup-btn-container-link a {
    font-size: 14px;
  }

  .btn-confirmation-account {
    width: 256px;
    height: 42px;
    padding: 5px 15px;
    font-size: 14px;
  }
}
