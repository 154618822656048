.reset-initial-pages-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 546px;
  height: 627px;
  border-radius: 4px;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
  padding: 35px 46px 35px 46px;
}

.reset-initial-pages-reset-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 546px;
  height: 444px;
  border-radius: 4px;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
  padding: 48px 80px 63px 80px;
}

.reset-initial-pages-error-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 546px;
  height: 444px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
  padding: 48px 41px 48px 41px;
}

.reset-form-container,
.reset-info-container,
.reset-error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.reset-info-container {
  justify-content: space-between;
  text-align: center;
}

.reset-error-container {
  justify-content: space-evenly;
  text-align: center;
}

.image {
  width: 106px;
  height: 106px;
  margin-bottom: 24px;
}

.image-info {
  width: 106px;
  height: 106px;
  margin-top: 0;
}

.title {
  margin-bottom: 30px;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
}

.title-info {
  margin: 24px 0 24px 0;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
}

.text {
  margin-bottom: 38px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.text-info {
  margin-bottom: 21px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.text-info-container {
  margin-bottom: 48px;
}

.info-container {
  height: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.reset-btn-login-container {
  width: 116px;
  height: 52px;
  padding: 15px 32px;
  display: flex;
  justify-content: center;
  background: rgba(44, 46, 74, 1);
  box-shadow: 1px 1px 16px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-bottom: 0;
  margin-top: 0;
}

.reset-btn-reset-container {
  width: 231px;
  height: 52px;
  padding: 15px 32px;
  display: flex;
  justify-content: center;
  background: rgba(44, 46, 74, 1);
  box-shadow: 1px 1px 16px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-bottom: 0;
  margin-top: 0;
}

.reset-btn-login-container a,
.reset-btn-reset-container a {
  padding: 0;
  margin: 0;
  color: #ffffff;
  text-decoration: none;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.reset-password-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.reset-input-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-bottom: 25px;
}

.reset-input {
  width: 450px;
  height: 56px;
  padding: 18px 16px;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: #f5f7fb;
  border-bottom: 2px solid rgba(44, 46, 74, 0.42);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.reset-input-section label {
  margin-bottom: 5px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.reset-password-show-password-icon-first,
.reset-password-show-password-icon-second {
  background: none;
  outline: none;
  border: none;
  position: absolute;
  margin: 0;
  padding: 0;
  width: 26px;
  height: 24px;
}

.reset-password-show-password-icon-first {
  bottom: 30%;
  right: 5%;
  transform: translate(0, 50%);
}

.reset-password-show-password-icon-second {
  bottom: 30%;
  right: 5%;
  transform: translate(0, 50%);
}

.input-pwd-valid {
  border-bottom: 2px solid rgba(44, 46, 74, 0.42);
}

.input-pwd-invalid {
  border-bottom: 2px solid red;
}

.input-populated {
  border-bottom: 2px solid rgba(44, 46, 74, 1);
}

.confirm-new-password-btn-container {
  margin-top: 10px;
}

.btn-confirm-password {
  border-radius: 4px;
  width: 260px;
  height: 52px;
  margin: 0 0 0 0;
  padding: 15px auto;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.btn-inactive-confirm-password {
  transition: all 0.1s linear;
  border: 1px solid rgba(44, 46, 74, 0.64);
  background-color: #ffffff;
  color: rgba(44, 46, 74, 0.64);
  cursor: not-allowed;
}

.btn-active-confirm-password {
  transition: all 0.1s linear;
  border: 1px #0e0333 solid;
  background-color: #0e0333;
  color: #fff;
  cursor: pointer;
}

// ! password-requirements box
.pwd-should-contain-section-reset-password {
  position: absolute;
  top: 41%;
  left: 100%;
  width: 395px;
  height: 187px;
  margin-left: 5%;
  padding: 10px;
  background: #eff0f6;
  border: 2px solid #7f8192;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.pwd-should-contain-section-reset-password:after,
.pwd-should-contain-section-reset-password:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.pwd-should-contain-section-reset-password:after {
  border-color: rgba(239, 240, 246, 0);
  border-right-color: #eff0f6;
  border-width: 15px;
  margin-top: -15px;
}

.pwd-should-contain-section-reset-password:before {
  border-color: rgba(127, 129, 146, 0);
  border-right-color: #7f8192;
  border-width: 18px;
  margin-top: -18px;
}

.pwd-should-contain-reset-title {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.pwd-should-contain-reset-group {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  span {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.pwd-check-status-reset {
  width: 20px;
  height: 20px;
}

// rules outside of @media queries apply to devices 1600px and above
// rules here apply to devices from 1024px to 1600px (inclusive)
@media only screen and (max-width: 1600px) {
  .reset-initial-pages-container {
    width: 446px;
    height: 567px;
    padding: 30px 36px 30px 36px;
  }

  .reset-initial-pages-reset-container {
    width: 446px;
    height: 344px;
    padding: 38px 31px 38px 31px;
  }

  .reset-initial-pages-error-container {
    width: 446px;
    height: 344px;
    padding: 38px 31px 38px 31px;
  }

  .image {
    width: 96px;
    height: 96px;
    margin-bottom: 16px;
  }

  .image-info {
    width: 96px;
    height: 96px;
    margin-top: 0;
  }

  .title {
    margin-bottom: 38px;
    font-size: 22px;
  }

  .title-info {
    margin: 14px 0 14px 0;
    font-size: 21px;
  }

  .text {
    margin-bottom: 30px;
    font-size: 16px;
  }

  .text-info {
    margin-bottom: 10px;
    font-size: 15px;
  }

  .text-info-container {
    margin-bottom: 28px;
  }

  .info-container {
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .reset-btn-login-container {
    width: 106px;
    height: 42px;
    padding: 10px 10px;
  }

  .reset-btn-reset-container {
    width: 220px;
    height: 42px;
    padding: 10px 10px;
  }

  .reset-btn-login-container a,
  .reset-btn-reset-container a {
    font-size: 15px;
  }

  .reset-password-form {
    width: 100%;
    height: 100%;
  }

  .reset-input-section {
    margin-bottom: 20px;
  }

  .reset-input {
    width: 100%;
    height: 46px;
    padding: auto 16px;
    font-size: 13px;
  }

  .reset-input-section label {
    margin-bottom: 6px;
    font-size: 16px;
  }

  .confirm-new-password-btn-container {
    margin-top: 16px;
  }

  .btn-confirm-password {
    width: 250px;
    height: 42px;
    padding: auto auto;
    font-size: 15px;
  }

  // ! password-requirements box
  .pwd-should-contain-section-reset-password {
    top: 41%;
    width: 325px;
    height: 200px;
  }

  .pwd-should-contain-reset-title {
    font-size: 18px;
    font-weight: 600;
  }

  .pwd-should-contain-reset-group {
    gap: 10px;
    span {
      font-size: 12px;
    }
  }
}

// rules here apply to devices from 0px to 1024px (inclusive)
@media only screen and (max-width: 1024px) {
  .reset-initial-pages-container {
    width: 396px;
    height: 477px;
    padding: 25px 36px 25px 36px;
  }

  .reset-initial-pages-reset-container {
    width: 420px;
    height: 344px;
    padding: 38px 15px 38px 15px;
  }

  .reset-initial-pages-error-container {
    width: 420px;
    height: 344px;
    padding: 38px 15px 38px 15px;
  }

  .image {
    width: 76px;
    height: 76px;
    margin-bottom: 10px;
  }

  .image-info {
    width: 76px;
    height: 76px;
    margin-top: 0;
  }

  .title {
    margin-bottom: 15px;
    font-size: 20px;
  }

  .title-info {
    margin: 14px 0 14px 0;
    font-size: 20px;
  }

  .text {
    margin-bottom: 15px;
    font-size: 14px;
  }

  .text-info {
    margin-bottom: 10px;
    font-size: 15px;
  }

  .text-info-container {
    margin-bottom: 28px;
  }

  .info-container {
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .reset-btn-login-container {
    width: 106px;
    height: 42px;
    padding: 10px 10px;
  }

  .reset-btn-reset-container {
    width: 220px;
    height: 42px;
    padding: 10px 10px;
  }

  .reset-btn-login-container a,
  .reset-btn-reset-container a {
    font-size: 15px;
  }

  .reset-password-form {
    width: 100%;
    height: 100%;
  }

  .reset-input-section {
    margin-bottom: 10px;
  }

  .reset-input {
    width: 100%;
    height: 46px;
    padding: auto 16px;
    font-size: 12px;
  }

  .reset-input-section label {
    margin-bottom: 6px;
    font-size: 14px;
  }

  .confirm-new-password-btn-container {
    margin-top: 15px;
  }

  .btn-confirm-password {
    width: 250px;
    height: 42px;
    padding: auto auto;
    font-size: 14px;
  }

  // ! password-requirements box
  .pwd-should-contain-section-reset-password {
    top: 38%;
    width: 250px;
    height: 175px;
    padding: 15px;
  }

  .pwd-should-contain-reset-title {
    font-size: 15px;
    font-weight: 600;
  }

  .pwd-check-status-reset {
    width: 15px;
    height: 15px;
  }

  .pwd-should-contain-reset-group {
    gap: 10px;
    span {
      font-size: 12px;
    }
  }
}

// breakpoint - password requirements box in narrow windows
@media only screen and (max-width: 900px) {
  //! pwd requirements portrait mode
  .pwd-should-contain-section-reset-password {
    position: absolute;
    top: 2%;
    left: 0;
    width: 395px;
    height: 187px;
    margin-left: 0%;
    padding: 10px;
    background: #eff0f6;
    border: 2px solid #7f8192;
    opacity: 0.95;
  }
  .pwd-should-contain-section-reset-password:after,
  .pwd-should-contain-section-reset-password:before {
    top: 110%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .pwd-should-contain-section-reset-password:after {
    border-color: rgba(127, 129, 146, 0);
    border-top-color: #eff0f6;
    border-width: 16px;
    margin-left: -16px;
    position: absolute;
    top: 107%;
  }
  .pwd-should-contain-section-reset-password:before {
    border-color: rgba(127, 129, 146, 0);
    border-top-color: #7f8192;
    border-width: 17px;
    margin-left: -17px;
  }

  .pwd-should-contain-reset-title {
    font-size: 18px;
  }

  .pwd-should-contain-reset-group {
    span {
      font-size: 14px;
    }
  }
}

// tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .reset-initial-pages-container {
    width: 446px;
    height: 527px;
    padding: 25px 36px 25px 36px;
  }

  .reset-initial-pages-reset-container {
    width: 446px;
    height: 344px;
    padding: 38px 30px 38px 30px;
  }

  .reset-initial-pages-error-container {
    width: 446px;
    height: 344px;
    padding: 38px 30px 38px 30px;
  }

  .image {
    width: 96px;
    height: 96px;
    margin-bottom: 15px;
  }

  .image-info {
    width: 96px;
    height: 96px;
    margin-top: 0;
  }

  .title {
    margin-bottom: 20px;
    font-size: 21px;
  }

  .title-info {
    margin: 15px 0 15px 0;
    font-size: 21px;
  }

  .text {
    margin-bottom: 25px;
    font-size: 15px;
  }

  .text-info {
    margin-bottom: 10px;
    font-size: 15px;
  }

  .text-info-container {
    margin-bottom: 25px;
  }

  .reset-btn-login-container {
    width: 106px;
    height: 42px;
    padding: 10px 32px;
  }

  .reset-btn-reset-container {
    width: 221px;
    height: 42px;
    padding: 10px auto;
  }

  .reset-btn-login-container a,
  .reset-btn-reset-container a {
    font-size: 15px;
  }

  .reset-input-section {
    margin-bottom: 15px;
  }

  .reset-input {
    width: 100%;
    height: 46px;
    padding: 10px 15px;
    font-size: 13px;
  }

  .reset-input-section label {
    margin-bottom: 10px;
    font-size: 15px;
  }

  .confirm-new-password-btn-container {
    margin-top: 10px;
  }

  .btn-confirm-password {
    width: 250px;
    height: 42px;
    padding: 10px auto;
    font-size: 15px;
  }

  //! pwd requirements portrait mode
  .pwd-should-contain-section-reset-password {
    position: absolute;
    top: 10%;
    left: 0;
    width: 395px;
    height: 187px;
    margin-left: 5%;
    padding: 10px;
    background: #eff0f6;
    border: 2px solid #7f8192;
    opacity: 0.95;
  }
  .pwd-should-contain-section-reset-password:after,
  .pwd-should-contain-section-reset-password:before {
    top: 110%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .pwd-should-contain-section-reset-password:after {
    border-color: rgba(127, 129, 146, 0);
    border-top-color: #eff0f6;
    border-width: 16px;
    margin-left: -16px;
    position: absolute;
    top: 107%;
  }
  .pwd-should-contain-section-reset-password:before {
    border-color: rgba(127, 129, 146, 0);
    border-top-color: #7f8192;
    border-width: 17px;
    margin-left: -17px;
  }

  .pwd-should-contain-reset-title {
    font-size: 18px;
  }

  .pwd-should-contain-reset-group {
    span {
      font-size: 14px;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .reset-initial-pages-container {
    width: 446px;
    height: 527px;
    padding: 25px 36px 25px 36px;
  }

  .reset-initial-pages-reset-container {
    width: 446px;
    height: 344px;
    padding: 38px 30px 38px 30px;
  }

  .reset-initial-pages-error-container {
    width: 446px;
    height: 344px;
    padding: 38px 30px 38px 30px;
  }

  .image {
    width: 86px;
    height: 86px;
    margin-bottom: 15px;
  }

  .image-info {
    width: 86px;
    height: 86px;
    margin-top: 0;
  }

  .title {
    margin-bottom: 20px;
    font-size: 21px;
  }

  .title-info {
    margin: 14px 0 14px 0;
    font-size: 21px;
  }

  .text {
    margin-bottom: 25px;
    font-size: 15px;
  }

  .text-info {
    margin-bottom: 10px;
    font-size: 15px;
  }

  .text-info-container {
    margin-bottom: 28px;
  }

  .reset-btn-login-container {
    width: 106px;
    height: 42px;
    padding: 10px auto;
  }

  .reset-btn-reset-container {
    width: 221px;
    height: 42px;
    padding: 10px auto;
  }

  .reset-btn-login-container a,
  .reset-btn-reset-container a {
    font-size: 15px;
  }

  .reset-input-section {
    margin-bottom: 15px;
  }

  .reset-input {
    width: 100%;
    height: 46px;
    padding: auto 16px;
    font-size: 13px;
  }

  .reset-input-section label {
    margin-bottom: 10px;
    font-size: 15px;
  }

  .confirm-new-password-btn-container {
    margin-top: 10px;
  }

  .btn-confirm-password {
    width: 250px;
    height: 42px;
    padding: 10px auto;
    font-size: 15px;
  }

  // ! password-requirements box
  .pwd-should-contain-section-reset-password {
    top: 41%;
    width: 300px;
  }

  .pwd-should-contain-reset-title {
    font-size: 18px;
  }

  .pwd-should-contain-reset-group {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    span {
      font-size: 12px;
    }
  }
}

// phone
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .reset-initial-pages-container {
    width: 346px;
    height: 500px;
    padding: 20px 20px 20px 20px;
  }

  .reset-initial-pages-reset-container {
    width: 346px;
    height: 344px;
    padding: 15px 10px 15px 10px;
  }

  .reset-initial-pages-error-container {
    width: 346px;
    height: 344px;
    padding: 15px 10px 15px 10px;
  }

  .image {
    width: 56px;
    height: 56px;
    margin-bottom: 30px;
  }

  .image-info {
    width: 56px;
    height: 56px;
    margin-top: 0;
  }

  .title {
    margin-bottom: 30px;
    font-size: 20px;
  }

  .title-info {
    margin: 24px 0 24px 0;
    font-size: 20px;
  }

  .text {
    margin-bottom: 20px;
    font-size: 14px;
  }

  .text-info {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .text-info-container {
    margin-bottom: 28px;
  }

  .reset-btn-login-container {
    width: 106px;
    height: 42px;
    padding: auto 15px;
    margin-bottom: 20px;
  }

  .reset-btn-reset-container {
    width: 221px;
    height: 42px;
    padding: auto 15px;
  }

  .reset-btn-login-container a,
  .reset-btn-reset-container a {
    font-size: 14px;
  }

  .reset-input-section {
    margin-bottom: 15px;
  }

  .reset-input {
    width: 100%;
    height: 46px;
    padding: 5px 16px;
    font-size: 13px;
  }

  .reset-input-section label {
    margin-bottom: 5px;
    font-size: 14px;
  }

  .confirm-new-password-btn-container {
    margin-top: 15px;
  }

  .btn-confirm-password {
    width: 240px;
    height: 42px;
    padding: 5px auto;
    font-size: 14px;
  }

  // ! password-requirements box
  .pwd-should-contain-section-reset-password {
    position: absolute;
    top: 8%;
    left: 0;
    width: 300px;
    height: 175px;
    margin-left: 5%;
    padding: 10px;
    background: #eff0f6;
    border: 2px solid #7f8192;
    opacity: 0.95;
  }
  .pwd-should-contain-section-reset-password:after,
  .pwd-should-contain-section-reset-password:before {
    top: 110.5%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .pwd-should-contain-section-reset-password:after {
    border-color: rgba(127, 129, 146, 0);
    border-top-color: #eff0f6;
    border-width: 16px;
    margin-left: -16px;
    position: absolute;
    top: 107%;
  }
  .pwd-should-contain-section-reset-password:before {
    border-color: rgba(127, 129, 146, 0);
    border-top-color: #7f8192;
    border-width: 17px;
    margin-left: -17px;
  }

  .pwd-should-contain-reset-title {
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  .pwd-should-contain-reset-group {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    span {
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}
