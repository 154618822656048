// ------------ tabs ------------

.profile-header-tabs-container {
  display: flex;
  align-items: center;
  gap: 40px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.profile-header-tabs-container li {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5vh;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-family: Inter;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  padding: 16px;
}

.profile-header-tabs-container li.current {
  font-weight: 700;
  border-bottom: 2px solid #0e0333;
}

.profile-field-container:focus-within .profile-field-text {
  color: #0038ff;
}

.profile-information-container {
  position: relative;
  width: 90vw;
  height: 85%;
  margin: 30px 0 0 0;
  padding: 48px 80px;
  background-color: #ffffff;
  box-shadow: (1px 1px 16px rgba(0, 0, 0, 0.16));
  border-radius: 4px;
}

.profile-information-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.profile-panelhook-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.profile-field-container {
  position: relative;
  width: 450px;
  height: auto;
  margin: 0 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.profile-field-text {
  font-family: Inter;
  color: #0e0333;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.profile-field-text-vat {
  font-family: Inter;
  color: #0e0333;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label-valid {
  color: #0e0333;
}

.label-invalid {
  font-family: Inter;
  color: red;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.input-valid-black {
  border-bottom: 2px solid #0e0333;
}

.input-invalid {
  border-bottom: 2px solid red;
}

.input-default {
  border-bottom: 1px solid #0e0333;
}

.profile-show-password-icon {
  position: absolute;
  left: 100%;
  top: 96%;
  transform: translate(-150%, -125%);
  width: fit-content;
  height: 28px;
  border: none;
  background: none;
  cursor: pointer;
}

.security-fields {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin: 0 0;
}

.security-requirements {
  position: absolute;
  top: 120px;
}

.cancel-text {
  font-size: 12px;
  color: #757575;
  padding: 12px;
}

.profile-save-information-button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  height: 100%;
  align-items: center;
}

.profile-save-information-button {
  min-width: 170px;
  height: 42px;
  border-radius: 8px;
  border: none;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0 12px 0 12px;
}

.button-active {
  transition: all 0.1s linear;
  border: 1px #0e0333 solid;
  background-color: #0e0333;
  color: #fff;
  cursor: pointer;
}

.button-inactive {
  transition: all 0.1s linear;
  border: 1px solid rgba(44, 46, 74, 0.64);
  background-color: #ffffff;
  color: rgba(44, 46, 74, 0.64);
}
// -------------- button ------------

// -------------- password requirements box -------------------
.pwd-should-contain-section-profile {
  width: 447px;
  height: 215px;
  padding: 16px;
  background: #f7f7f8;
  border: 2px solid #7f8192;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.pwd-should-contain-title-profile {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0px 0 10px 0;
}

.pwd-should-contain-profile {
  display: flex;
  align-items: center;
  gap: 16px;
}

.pwd-should-contain-section-profile img {
  width: 24px;
  height: 24px;
  object-fit: cover;
}

.pwd-should-contain-profile span {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

// -------------- password requirements box -------------------

.arrow-country-dropdown {
  position: absolute;
  right: 15px;
  bottom: 22px;
  pointer-events: none;
  cursor: pointer;
}

.profile-blocks {
  display: flex;
  gap: 73px;
}

// ---------------- notification box -------------

.new-delete-account-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #f2f6ff;
  width: 380px;
  height: 150px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #0e0333 a3;
  padding: 16px;
}

.security-information {
  position: relative;
}

.delete-profile-modal-bg {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #0e0333 52;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-profile-modal-container {
  width: 692px;
  height: 407px;
  background: #fff;
  border-radius: 16px;
  filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.2));
}

.delete-profile-modal-header {
  font-weight: 400;
  font-size: 20px;
  background: #0e0333;
  height: 56px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-profile-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
}

.delete-profile-modal-text {
  margin: 20px 0;
}

.rbt:focus-within .rbt-input-main {
  border-bottom: #0038ff 2px solid;
}

// ----------- countries dropdown ----------------
.profile-countries-container {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  .rbt-input-main {
    height: 52px;
    min-width: 250px;
    width: 100%;
    padding: 16px 48px 16px 16px;
    font-size: 16px;
    background: #f2f6ff;
    border: none;
    border-bottom: 2px solid #0e0333;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;
  }

  .rbt-menu {
    border-radius: 4px;
    background-color: #fff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    height: 180px;
  }

  .dropdown-menu {
    display: flex !important;
    flex-direction: column;
  }

  .dropdown-item {
    padding: 1rem 1rem 1rem 1rem;
    text-decoration: none;
    color: #8b8d9b;
  }

  .dropdown-item:hover {
    color: #2c2f48;
    background-color: #f3f5f9;
  }
}

// rules outside of @media queries apply to devices 1600px and above
// rules here apply to devices from 1024px to 1600px (inclusive)
@media only screen and (max-width: 1600px) {
  .profile-information-container {
    margin: 25px 0 0 0;
    padding: 38px 80px;
  }

  .profile-field-container {
    width: 400px;
    gap: 8px;
  }

  .security-fields {
    gap: 45px;
  }

  // ----------- button --------------
  .profile-save-information-button {
    font-size: 17px;
  }
  // ----------- button ------------

  // ----------- password requirements box -------------------
  .pwd-should-contain-section-profile {
    width: 397px;
    padding: 13px;
  }

  .pwd-should-contain-profile {
    gap: 13px;
  }

  .pwd-should-contain-section-profile img {
    width: 22px;
    height: 22px;
    object-fit: cover;
  }

  // ----------- countries dropdown ----------------
  .profile-countries-container {
    .rbt-menu {
      height: 180px;
    }
  }
  // ----------- countries dropdown ----------------
}

// breakpoint to make drop down options a bit taller
@media only screen and (min-height: 832px) {
  .profile-countries-container {
    .rbt-menu {
      height: 250px;
    }
  }
}

// rules here apply to devices from 0px to 1024px (inclusive)
@media only screen and (max-width: 1024px) {
  .profile-information-container {
    width: 100%;
    height: 85%;
    margin: 20px 0 0 0;
    padding: 28px 40px;
  }

  .profile-field-container {
    width: 100%;
    min-width: 225px;
    gap: 4px;
  }

  .profile-field-text {
    font-size: 15px;
  }

  .security-fields {
    gap: 25px;
  }
  // ------------- button --------------
  .profile-save-information-button {
    width: 173px;
    height: 42px;
    font-size: 15px;
  }
  // -------------- button ------------

  // -------------- password requirements box -------------------
  .pwd-should-contain-section-profile {
    width: 250px;
    padding: 10px;
    gap: 8px;
  }

  .pwd-should-contain-title-profile {
    font-size: 13px;
  }

  .pwd-should-contain-profile {
    gap: 10px;
  }

  .pwd-should-contain-section-profile img {
    width: 20px;
    height: 20px;
  }

  .pwd-should-contain-profile span {
    font-size: 13px;
  }

  // ----------- countries dropdown ----------------
  .profile-countries-container {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    .rbt-input-main {
      padding: 0 48px 0 16px;
      font-size: 13px;
    }

    .rbt-menu {
      height: 150px;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023.9px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .profile-information-container {
    width: 100%;
    height: 85%;
    margin: 30px 0 0 0;
    padding: 38px 80px;
  }

  .security-information {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .profile-field-container {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .profile-field-text {
    font-size: 15px;
  }

  .security-fields {
    width: 60%;
    gap: 35px;
  }

  // ------------- button --------------
  .profile-save-information-button {
    width: 173px;
    height: 42px;
    font-size: 15px;
  }
  // -------------- button ------------

  // -------------- password requirements box -------------------
  .pwd-should-contain-section-profile {
    width: 400px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;
  }

  .pwd-should-contain-profile {
    gap: 16px;
  }

  .pwd-should-contain-section-profile img {
    width: 22px;
    height: 22px;
    object-fit: cover;
  }

  .pwd-should-contain-profile span {
    font-size: 14px;
  }

  // ---------------- notification box -------------

  // ----------- countries dropdown ----------------
  .profile-countries-container {
    .rbt-input-main {
      padding: 0px 48px 0px 16px;
      font-size: 13px;
    }

    .rbt-menu {
      height: 600px;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023.9px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .profile-information-container {
    width: 100%;
    height: 85%;
    margin: 30px 0 0 0;
    padding: 38px 80px;
  }

  .profile-field-container {
    width: 400px;
    gap: 8px;
  }

  .profile-save-information-button {
    width: 173px;
    height: 42px;
    font-size: 15px;
  }
  // -------------- button ------------

  // -------------- password requirements box -------------------
  .pwd-should-contain-section-profile {
    width: 447px;
  }

  .pwd-should-contain-profile {
    gap: 13px;
  }

  .pwd-should-contain-section-profile img {
    width: 22px;
    height: 22px;
    object-fit: cover;
  }

  // ----------- countries dropdown ----------------
  .profile-countries-container {
    .rbt-menu {
      height: 165px;
    }
  }
}
