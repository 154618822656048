.onboarding-content-layout {
  height: calc(100% - 50px);
}

.highlight-logout {
  width: 40px !important;
  height: 30px !important;
}

.highlight-analytics {
  margin-top: 8px !important;
}

.search-input-container:focus-within .search-input {
  border: 1px solid #0038ff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.dashboard-layout {
  display: flex;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #f2f6ff;

  .content-wrapper {
    grid-area: content;
  }

  .dashboard {
    width: calc(100vw - 80px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: calc(100% - 50px) 50px;
    grid-template-areas:
      'content'
      'footer';
    margin-left: 80px;
    margin-right: 32px;
    padding-left: 32px;
    padding-top: 28px;

    .dashboard-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dashboard-main-links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);

        .btn-dashboard {
          height: 50px;
          font-size: 16px;
          border: none;
          color: #0e0333;
          background-color: transparent;
          cursor: pointer;
          padding: 0 16px;
          font-family: Inter;
        }

        .btn-dashboard-active {
          border-bottom: 2px solid #0e0333;
          h3 {
            font-weight: 700;
          }
        }
        .btn-dashboard-inactive {
          border-bottom: 2px solid transparent;
          h3 {
            font-weight: 300;
          }
        }
      }

      .btn-create-tour {
        min-width: 170px;
        height: 42px;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        cursor: pointer;
        border: none;
        border-radius: 8px;
        background-color: #d50057;
        color: white;
        padding: 0 12px 0 12px;
        a {
          text-decoration: none;
        }
      }
    }

    .dashboard-search-and-filter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 50px;
      margin-bottom: 24px;

      .search-icon {
        position: absolute;
        border: none;
        cursor: pointer;
        background: none;
        width: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 56px;
        right: 0;
        top: 0;
      }
    }
    .only-sort-container {
      width: auto;
      align-items: center;
      justify-content: flex-end;
    }
    .sort-and-filter-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 25px;

      .filter-container {
        gap: 20px;
        display: flex;
        justify-content: space-between;

        .filter-btn-view {
          margin-top: 2px;
          border: none;
          color: inherit;
          background: none;
          cursor: pointer;
        }
      }
    }
    .sort-by-container {
      position: relative;
      gap: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .sort-by-label {
        width: 4rem;
        font-size: 1rem;
        color: #0e0333;
        font-family: Inter;
      }
    }
  }

  .dashboard-display-tours-section {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.dash-number-of-tours {
  padding: 3vh 0 12px;
  border-bottom: 1px solid #0e0333;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 30px;
  width: 100%;
  color: #0e0333;
  cursor: default;
}

@media (max-width: 1150px) {
  .dashboard-layout {
    display: flex;
    height: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #f2f6ff;

    .content-wrapper {
      grid-area: content;
    }

    .dashboard {
      width: calc(100vw - 80px);
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: calc(100% - 50px) 50px;
      grid-template-areas:
        'content'
        'footer';
      margin-left: 80px;
      margin-right: 32px;
      padding-left: 32px;
      padding-top: 28px;

      .dashboard-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dashboard-main-links {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 0;
          box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);

          .btn-dashboard {
            font-size: 16px;
            height: 50px;
            border: none;
            color: #0e0333;
            background-color: transparent;
            cursor: pointer;
            padding: 0 16px;
            font-family: Inter;
          }

          .btn-dashboard-active {
            border-bottom: 2px solid #0e0333;
            h3 {
              font-weight: 700;
            }
          }
          .btn-dashboard-inactive {
            border-bottom: 2px solid transparent;
            h3 {
              font-weight: 300;
            }
          }
        }
      }
    }

    .dashboard-display-tours-section {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .dashboard-layout {
    .dashboard {
      width: calc(100vw - 80px);
      margin-left: 80px;
      margin-right: 30px;
      padding-top: 20px;
      padding-left: 30px;
      padding-right: 0px;

      .dashboard-header {
        .dashboard-main-links {
          width: 22rem;

          .btn-dashboard {
            width: 10rem;
          }
        }
        .btn-create-tour {
          height: 7vh;
          width: 16vw;
        }
      }

      .dashboard-search-and-filter {
        margin-top: 20px;
        margin-bottom: 20px;

        .search-input-container {
          width: 34vw;

          .search-input {
            padding: 0 3rem 0 1rem;
            height: 3rem;
            -webkit-appearance: none;
            -webkit-box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
            box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
          }

          .search-icon {
            width: 3rem;
            height: 3rem;
          }
        }

        .sort-and-filter-container {
          .filter-container {
            width: 4.75rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .dashboard-layout {
    .dashboard {
      width: calc(100vw - 60px);
      margin-left: 60px;
      margin-right: 0px;
      padding-left: 20px;
      padding-right: 20px;

      .dashboard-header {
        .dashboard-main-links {
          width: 22rem;

          .btn-dashboard {
            width: 10rem;
          }
        }
        .btn-create-tour {
          height: 6vh;
          width: 16vw;
        }
      }
      .dashboard-search-and-filter {
        margin-top: 30px;
        margin-bottom: 20px;

        .search-input-container {
          width: 35vw;

          .search-input {
            padding: 0 3rem 0 1rem;
            height: 3rem;
            -webkit-appearance: none;
            -webkit-box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
            box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
          }

          .search-icon {
            width: 3rem;
            height: 3rem;
          }
        }

        .sort-and-filter-container {
          width: 21rem;

          .filter-container {
            width: 4rem;
          }
        }

        .sort-by-container {
          width: 15rem;

          .sort-by-label {
            width: 3.25rem;
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}
