.dots-dropdown-parent-row {
  position: relative;
  top: 1px;
}

.dots-btn {
  background: no-repeat;
}

.dots-btn:hover {
  background-image: url('../../../images/dashboard-icons/card-icons/btn-hover.svg');
}

.dots-dropdown-content {
  display: none;
  width: 177px;
  position: absolute;
  top: 0;
  right: 7px;
  z-index: 1;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}

.dots-dropdown-content-active {
  display: block;
  width: 177px;
  position: absolute;
  top: 0;
  right: 7px;
  z-index: 1;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}

.dots-dropdown-menu {
  padding: 4% 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dots-options {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .link-style-adjustment {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .dots-options-icons {
    margin-left: 15px;
  }
  .dots-options-text {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dots-options:hover path {
  fill: #ffffff;
}
.dots-options:hover {
  background-color: #0e0333;
  color: #ffffff;
}

.remove-default {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .dots-dropdown-parent-card {
    position: relative;
    left: 0.5rem;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .dots-dropdown-parent-card {
    position: relative;
    left: 0.5rem;
  }
}
