.dots {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
}

.img-row-view {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    object-fit: cover;
}

.row-image {
    height: auto;
    width: 30%;
    border-right: 1px solid #0e0333;
}

.three-dot-options {
    height: 22%;
    border-bottom: #0e0333 1px solid;
}

.container-info-tour {
    display: flex;
}

.row-info {
    width: 60%;
    padding: 20px 0px 4px 22px;
    border-right: 1px solid #0e0333;

    .row-info-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        .row-info-top {
            height: auto;
            position: relative;

            .updated-info {
                font-size: 0.875rem;
                font-style: italic;
                color: rgba(44, 46, 74, 0.72);
            }
        }
    }
}

.details-text-down {
    font-size: 0.875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 100px;
    max-width: 500px;
}

.row-info-bottom {
    display: flex;
    width: 100%;
    position: relative;

    .left-half-info {
        height: 4rem;
        width: 60%;
    }

    .right-half-info {
        height: 4rem;
        width: 40%;
    }

    .info-details-containers {
        display: flex;
        height: 50%;
        align-items: center;
        width: 100%;

        .details-icons {
            margin-right: 0.5rem;
        }

        .invalid-link {
            color: rgba(44, 46, 74, 0.4);
        }
    }
}

.remove-default {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.icon-share-dropdown {
    margin-left: 15px;
    height: 20px;
    width: 30px;
    background-image: url('../../../images/dashboard-icons/card-icons/options-icons/share-icon.svg');
    background-repeat: no-repeat;
}

.icon-duplicate-dropdown {
    margin-left: 15px;
    height: 20px;
    width: 30px;
    background-image: url('../../../images/dashboard-icons/card-icons/options-icons/duplicate-icon.svg');
    background-repeat: no-repeat;
}

.icon-transfer-dropdown {
    margin-left: 15px;
    height: 20px;
    width: 30px;
    background-image: url('../../../images/dashboard-icons/card-icons/options-icons/transfer-icon.svg');
    background-repeat: no-repeat;
}

.icon-download-dropdown {
    margin-left: 15px;
    height: 20px;
    width: 30px;
    background-image: url('../../../images/dashboard-icons/card-icons/options-icons/download-icon.svg');
    background-repeat: no-repeat;
}

.icon-lock-dropdown {
    margin-left: 15px;
    height: 20px;
    width: 30px;
    background-image: url('../../../images/dashboard-icons/card-icons/options-icons/lock-icon.svg');
    background-repeat: no-repeat;
}

.icon-open-lock-dropdown {
    margin-left: 15px;
    height: 20px;
    width: 30px;
    background-image: url('../../../images/dashboard-icons/card-icons/options-icons/open-lock-icon.svg');
    background-repeat: no-repeat;
}

.icon-archive-dropdown {
    margin-left: 15px;
    height: 20px;
    width: 30px;
    background-image: url('../../../images/dashboard-icons/card-icons/options-icons/archive-icon.svg');
    background-repeat: no-repeat;
}

.icon-delete-dropdown {
    margin-left: 15px;
    height: 20px;
    width: 30px;
    background-image: url('../../../images/dashboard-icons/card-icons/options-icons/delete-icon.svg');
    background-repeat: no-repeat;
}

.icon-reassign-dollhouse-button {
    margin-left: 15px;
    height: 20px;
    width: 30px;
    background-image: url('../../../images/dashboard-icons/card-icons/options-icons/reassign-default.svg');
    background-repeat: no-repeat;
}

.icon-validate-dollhouse-button {
    margin-left: 15px;
    height: 20px;
    width: 30px;
    background-image: url('../../../images/dashboard-icons/card-icons/options-icons/validate-default.svg');
    background-repeat: no-repeat;
}

.dots-options:hover {
    .icon-share-dropdown {
        height: 20px;
        width: 30px;
        background-image: url('../../../images/dashboard-icons/card-icons/options-icons/share-icon-hover.svg');
        background-repeat: no-repeat;
    }

    .icon-duplicate-dropdown {
        height: 20px;
        width: 30px;
        background-image: url('../../../images/dashboard-icons/card-icons/options-icons/duplicate-icon-hover.svg');
        background-repeat: no-repeat;
    }

    .icon-transfer-dropdown {
        height: 20px;
        width: 30px;
        background-image: url('../../../images/dashboard-icons/card-icons/options-icons/transfer-icon-hover.svg');
        background-repeat: no-repeat;
    }

    .icon-download-dropdown {
        height: 20px;
        width: 30px;
        background-image: url('../../../images/dashboard-icons/card-icons/options-icons/download-icon-hover.svg');
        background-repeat: no-repeat;
    }

    .icon-lock-dropdown {
        height: 20px;
        width: 30px;
        background-image: url('../../../images/dashboard-icons/card-icons/options-icons/lock-icon-hover.svg');
        background-repeat: no-repeat;
    }

    .icon-open-lock-dropdown {
        height: 20px;
        width: 30px;
        background-image: url('../../../images/dashboard-icons/card-icons/options-icons/open-lock-icon-hover.svg');
        background-repeat: no-repeat;
    }

    .icon-archive-dropdown {
        height: 20px;
        width: 30px;
        background-image: url('../../../images/dashboard-icons/card-icons/options-icons/archive-icon-hover.svg');
        background-repeat: no-repeat;
    }

    .icon-delete-dropdown {
        height: 20px;
        width: 30px;
        background-image: url('../../../images/dashboard-icons/card-icons/options-icons/delete-icon-hover.svg');
        background-repeat: no-repeat;
    }

    .icon-reassign-dollhouse-button {
        height: 20px;
        width: 30px;
        background-image: url('../../../images/dashboard-icons/card-icons/options-icons/reassign-hover.svg');
        background-repeat: no-repeat;
    }

    .icon-validate-dollhouse-button {
        height: 20px;
        width: 30px;
        background-image: url('../../../images/dashboard-icons/card-icons/options-icons/validate-hover.svg');
        background-repeat: no-repeat;
    }
}

@media (min-width: 1750px) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .tour-row {
        height: 10.5rem;
        margin: 10px 0;
        -webkit-appearance: none;
        -webkit-box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.25);
        box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.25);
    }

    .row-image {
        width: 20%;
    }

    .row-info {
        width: 80%;
        padding: 10px 20px 10px 10px;

        .row-info-content {
            justify-content: space-evenly;

            .row-info-top {
                flex-grow: 1;

                .updated-info {
                    font-size: 0.75rem;
                }
            }
        }
    }
    .row-info-bottom {
        flex-grow: 2;
        align-items: center;

        .left-half-info {
            width: 65%;
            height: 5rem;
        }
        .right-half-info {
            width: 35%;
            height: 5rem;
        }
        .info-details-containers {
            .details-icons {
                margin-left: 0.5rem;
                margin-right: 0.5rem;
            }
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .tour-row {
        height: 12rem;
        margin: 15px 0;
        -webkit-appearance: none;
        -webkit-box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.25);
        box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.25);
    }
    .row-image {
        height: 12rem;
        width: 30%;
    }
    .row-info {
        padding: 20px 20px 10px 20px;
    }

    .row-info-bottom {
        height: 6rem;

        .left-half-info {
            height: 5rem;
        }
        .right-half-info {
            height: 5rem;
            margin-left: 1rem;
        }
        .info-details-containers {
            height: 3rem;
            align-items: center;

            .details-icons {
                margin-right: 1rem;
            }
        }
    }
}
