.bottom-help-text {
  grid-area: footer;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-link {
  color: #757575;
  text-decoration: none;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
}

.link-divider {
  color: #757575;
  margin: 0 25px;
}

.survey-btn {
  color: #757575 !important;
  font-size: 14px !important;
  font-weight: 400;
  text-decoration: none;
}

// tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .bottom-help-text {
    grid-area: footer;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-fixed {
    position: fixed;
    bottom: 0;
    margin-bottom: 2%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .bottom-help-text {
    grid-area: footer;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-fixed {
    position: fixed;
    bottom: 0;
    margin-bottom: 1%;
  }
}

// phone
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .bottom-help-text {
    grid-area: footer;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-link {
    font-size: 12px;
  }

  .link-divider {
    margin: 0 15px;
  }

  .footer-fixed {
    position: fixed;
    bottom: 0;
    margin-bottom: 2%;
  }

  .survey-btn {
    color: inherit;
    font-size: 12px !important;
    font-weight: 400;
    text-decoration: none;
  }
}
