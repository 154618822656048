.App-mobile {
  margin: 0;
  padding: 64px 0 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f2f6ff;
}

.lock-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

.blue-section {
  width: 390px;
  height: 98px;
  background: #0e0333;
  color: white;
  padding: 0 45px 0 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

.container-blue {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  text-decoration: none;
}

.button-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 250px;
}

.content-button {
  display: flex;
  gap: 10px;
}

.button-apple {
  width: 180px;
  height: 53px;
  background: #0e0333;
  padding: 10px 13px 10px 13px;
  border-radius: 8px;
  color: white;
  border: none;
}

.sub {
  font-size: 13px;
}

.main {
  font-size: 18px;
}

.button-google {
  border-radius: 8px;
  width: 180px;
  height: 53px;
  background: #0e0333;
  padding: 10px 13px 10px 13px;
  border-radius: 8px;
  color: white;
  margin-bottom: 38px;
  border: none;
}

.helper-mobile {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.title-mobile {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #0e0333;
  margin-bottom: 32px;
  padding: 0 5px 0 5px;
}

.end-text {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #0e0333;
}
