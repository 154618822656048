.tour-row {
  height: 180px;
  width: 100%;
  margin: 0 0 30px 0;
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #0e0333;
}

.tour-row:hover {
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
}

.tour-row-edit {
  height: 180px;
  width: 98%;
  margin: 15px auto 0 2%;
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #0e0333;
}

.tour-row-edit:hover {
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
}

.row-image {
  height: auto;
  width: 30%;
  border-right: 1px solid #0e0333;
  position: relative;
}

.row-image-doll {
  height: auto;
  width: 30%;
  border-right: 1px solid #0e0333;
  position: relative;
}

.img-row-view {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  object-fit: cover;
  border-radius: 8px 0 0 8px;
}

.info-row-view {
  border-right: 1px solid #0e0333;
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px 22px;
  cursor: default;
}

.info-row-view-doll {
  border-right: 1px solid #0e0333;
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px 22px;
  cursor: default;
}

.container-title-info {
  display: flex;
  flex-direction: column;
  height: 50%;
  width: 70%;
  gap: 4px;
  margin-bottom: 10px;
}

.container-title-info-dollhouse {
  display: flex;
  flex-direction: column;
  height: 50%;
  width: 70%;
  gap: 8px;
}

.tour-row-address {
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #0e0333;
}

.container-info-tour {
  display: flex;
  gap: 10px;
  align-items: center;
  height: 40%;
  width: 100%;
}

.status-dollhouse {
  color: #0e0333;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  position: relative;
  top: 5px;
}

.btns-row-view {
  width: 235px;
  display: flex;
  flex-direction: column;
}

.btns-row-view-dollhouse {
  width: 235px;
  display: flex;
  height: 100%;
  align-items: center;
}

.row-image:hover .edit-link {
  background: rgba(14, 3, 51, 0.2);
  display: flex;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.row-image-doll:hover .edit-link {
  background: rgba(14, 3, 51, 0.2);
  display: flex;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.edit-link {
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: none;
}

.edit-link a {
  display: flex;
  justify-content: center;
  text-decoration: none;
  border: 1px solid #0e0333;
  background: #ffffff;
  padding: 14px 15px;
  height: 52px;
  border-radius: 8px;
  gap: 12px;
}

.edit-link a:hover {
  background: #0e0333;
  color: white;
  border: 1px solid white;
}

.icon-on-card {
  background: rgba(14, 3, 51, 0.7);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  width: 28px;
  height: 28px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lock-leads-icons {
  position: absolute;
  display: flex;
  width: 100%;
  margin-top: 12px;
  padding-left: 12px;
  gap: 12px;
}

.card-sub-info {
  display: flex;
  padding: 0 10px;
  gap: 10px;
  align-items: center;
}

.text-sub-info {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #757575;
}

.container-bottom-info {
  width: 100%;
  display: flex;
  position: relative;
  gap: 52px;
  top: 10px;
  right: 3px;
}

.container-bottom-info-dollhouse {
  width: 100%;
  gap: 52px;
  display: flex;
  position: relative;
  top: 15px;
  right: 3px;
}

.container-left-info {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.container-left-info-edit {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.container-right-info {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.container-right-info-dollhouse {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.info-details-location {
  display: flex;
  gap: 10px;
  align-items: center;
}

.info-email-detail {
  display: flex;
  gap: 10px;
  margin-left: 2.5px;
  align-items: center;
}

.info-url-doll {
  display: flex;
  max-width: 225px;
  gap: 10px;
  margin-left: 2.5px;
  align-items: center;
}

.text-info-bottom {
  max-width: 235px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0e0333;
}

.text-info-bottom-doll {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0e0333;
}

.text-info-bottom-row {
  max-width: 500px;
  width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0e0333;
}

.text-info-bottom-edit {
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0e0333;
}

.text-info-bottom-invalid-doll {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  pointer-events: none;
  font-size: 14px;
  line-height: 17px;
  color: #818aa6;
}

.info-url {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: -2px;
}

.info-url-users {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: -1px;
}

.order-dollhouse-btn {
  height: 35%;
  border-bottom: #0e0333 1px solid;
  background-color: #00269a;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 9px 0 11px 16px;
  align-items: center;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  border-bottom-right-radius: 8px;
}

.icons-doll-floor {
  display: flex;
  flex-direction: column;
}

.ordered-dollhouse-btn {
  border: none;
  border-top: #0e0333 1px solid;
  border-bottom: #0e0333 1px solid;
}

.ordered-dollhouse-btn {
  height: 35%;
  background-color: #13b597;
  color: white;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 12px;
  cursor: default;
  padding: 9px 0 11px 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  border-bottom-right-radius: 8px;
}

.ordered-dollhouse-btn-card {
  height: 35%;
  background-color: #13b597;
  color: white;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 12px;
  cursor: default;
  padding-left: 26px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.start-live-sharing-button {
  cursor: pointer;
  height: 30%;
  background-color: #d50057;
  color: white;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 16px;
  gap: 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  border-bottom-right-radius: 8px;
}

.order-dollhouse-btn:hover {
  background-color: #0e0333;
}

.start-live-sharing-button:hover {
  background-color: #0e0333;
}

.go-to-studio-btn-container {
  width: 100%;
  flex-direction: column;
  height: 30%;
}

.archive-btn-container {
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 8px;
}

.btn-restore-container {
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 8px;
}

.account-studio-container {
  width: 100%;
  height: 30%;
}

.account-3d-container {
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 8px;
}

.go-to-studio-btn {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: left;
  padding-left: 16px;
  align-items: center;
  gap: 12px;
  background-color: white;
  border: none;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.unarchive-btn {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background-color: white;
  border: none;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 0px;
}

.delete-btn-card {
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background-color: white;
  border: none;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  border-bottom-right-radius: 8px;
}

.restore-btn {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background-color: white;
  border: none;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 0px;
}

.restore-btn:hover {
  background-color: #0e0333;
  color: white;
}

.connect-goto-studio-dollhouse {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background-color: white;
  border: none;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 0px;
}

.connect-goto-studio {
  height: 100%;
  width: 100%;
  display: flex;
  padding-left: 26px;
  align-items: center;
  gap: 12px;
  background-color: white;
  border: none;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  border-bottom: #0e0333 1px solid;
}

.connect-goto-studio:hover .go-to-account-icon {
  background-size: 0, auto;
}

.connect-goto-studio:hover .go-to-studio-icon {
  background-size: 0, auto;
}
.connect-goto-studio-dollhouse:hover .go-to-studio-icon {
  background-size: 0, auto;
}

.connect-goto-studio-dollhouse:hover {
  background-color: #0e0333;
  color: white;
}

.connect-goto-studio:hover {
  background-color: #0e0333;
  color: white;
}

.delete-btn-card:hover {
  background-color: #0e0333;
  color: white;
}

.go-to-studio-big {
  width: 100%;
  height: 60%;
}

.details-icons {
  width: 24px;
  height: 24px;
}

.go-to-3d-icon {
  background-image: url('../../../images/dashboard-icons/dollhouse-blue.svg'), url('../../../images/dashboard-icons/dollhouse-white.svg');
  width: 24px;
  background-repeat: no-repeat;
  height: 24px;
  background-size: auto, 0;
}

.connect-goto-studio-dollhouse:hover .go-to-3d-icon {
  background-size: 0, auto;
}

.go-to-studio-icon {
  background-image: url('../../../images/dashboard-icons/card-icons/icon-edit.svg'),
    url('../../../images/dashboard-icons/card-icons/icon-edit-hover.svg');
  width: 24px;
  background-repeat: no-repeat;
  height: 24px;
  background-size: auto, 0;
}

.go-to-studio-btn:hover .go-to-studio-icon {
  background-size: 0, auto;
}

.unarchive-btn:hover .unarchive-icon {
  background-size: 0, auto;
}

.unarchive-icon {
  background-image: url('../../../images/dashboard-icons/card-icons/unarchive-icon.svg'),
    url('../../../images/dashboard-icons/card-icons/unarchive-hover.svg');
  width: 24px;
  background-repeat: no-repeat;
  height: 24px;
  background-size: auto, 0;
}

.delete-icon {
  background-image: url('../../../images/dashboard-icons/card-icons/delete-icon.svg'),
    url('../../../images/dashboard-icons/card-icons/delete-hover.svg');
  width: 24px;
  background-repeat: no-repeat;
  height: 24px;
  background-size: auto, 0;
}

.restore-icon {
  background-image: url('../../../images/dashboard-icons/card-icons/restore-icon.svg'),
    url('../../../images/dashboard-icons/card-icons/restore-hover.svg');
  width: 24px;
  background-repeat: no-repeat;
  height: 24px;
  background-size: auto, 0;
}

.go-to-account-icon {
  background-image: url('../../../images/dashboard-icons/card-icons/go-to-studio.svg'),
    url('../../../images/dashboard-icons/card-icons/go-to-studio-hover.svg');
  width: 24px;
  background-repeat: no-repeat;
  height: 24px;
  background-size: auto, 0;
}

.go-to-studio-btn:hover .go-to-studio-icon {
  background-size: 0, auto;
}

.delete-btn-card:hover .delete-icon {
  background-size: 0, auto;
}

.restore-btn:hover .restore-icon {
  background-size: 0, auto;
}

.go-to-studio-btn:hover {
  background-color: #0e0333;
  color: white;
}

.unarchive-btn:hover {
  background-color: #0e0333;
  color: white;
}

.img-icon-card {
  background-image: url('../../../images/dashboard-icons/card-icons/icon-tour-detail.svg'),
    url('../../../images/dashboard-icons/card-icons/tour-detail-hover.svg');
  width: 24px;
  background-repeat: no-repeat;
  height: 24px;
  background-size: auto, 0;
}

.edit-link a:hover .img-icon-card {
  background-size: 0, auto;
}

.circle-container-dot {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: #0e0333 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-container-dot:hover {
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14));
}

.circle-container-dot-grid {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: #0e0333 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.circle-container-disable {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: #818aa6 1px solid;
  display: flex;
  pointer-events: none;
  justify-content: center;
  align-items: center;
}

.cirlce-container-focus {
  box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.25);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: #0e0333 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.three-dot-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px 4px;
}

.three-dot-options-grid {
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px;
  position: absolute;
  right: 0;
  z-index: 1;
}

.three-dot-options-dollhouse {
  height: 50%;
  border-bottom: #0e0333 1px solid;
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px;
}

.icon-button-card {
  width: 24px;
  height: 24px;
}

.button-element {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon-reassign-dollhouse-button {
  background-image: url('../../../images/dashboard-icons/card-icons/assign-editor-doll.svg'),
    url('../../../images/dashboard-icons/card-icons/assign-editor-hover.svg');
  background-size: auto, 0;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}

.assign {
  display: flex;
  gap: 8px;
}

.icon-reassign-dollhouse-button:hover {
  background-size: 0, auto;
}

.icon-validation {
  background-image: url('../../../images/dashboard-icons/card-icons/validate-icon-doll.svg'),
    url('../../../images/dashboard-icons/card-icons/validate-icon-hover.svg');
  background-size: auto, 0;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}

.validate {
  display: flex;
  gap: 8px;
}

.validate:hover .icon-validation {
  background-size: 0, auto;
  background-repeat: no-repeat;
}

.icon-decline {
  background-image: url('../../../images/dashboard-icons/card-icons/decline-icon-doll.svg'),
    url('../../../images/dashboard-icons/card-icons/decline-icon-hover.svg');
  background-size: auto, 0;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}

.decline {
  display: flex;
  gap: 8px;
}

.decline:hover .icon-decline {
  background-size: 0, auto;
  background-repeat: no-repeat;
}

.dots-options-text-dollhouse {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 14px;
  color: #0e0333;
}

.dots-options-text-dollhouse:hover path {
  fill: #ffffff;
}
.dots-options-text-dollhouse:hover {
  background-color: #0e0333;
  color: #ffffff;
}

.back-edit {
  display: flex;
  gap: 8px;
}

.back-edit:hover .go-to-3d-icon {
  background-size: 0, auto;
}

@media (max-width: 1224px) {
  .order-dollhouse-btn {
    font-size: 15px;
  }

  .start-live-sharing-button {
    font-size: 15px;
  }

  .ordered-dollhouse-btn {
    font-size: 15px;
  }

  .go-to-studio-btn {
    font-size: 15px;
  }
}

@media (max-width: 1130px) {
  .order-dollhouse-btn {
    font-size: 12px;
  }

  .start-live-sharing-button {
    font-size: 12px;
  }

  .ordered-dollhouse-btn {
    font-size: 12px;
  }

  .go-to-studio-btn {
    font-size: 12px;
  }
}
