.password-initial-pages-container {
  background-color: #ffffff;
  margin-right: auto;
  margin-left: auto;
  width: 65%;
  height: 70%;
  gap: 50px;
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  padding: 40px 50px;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
}

.title-forgot-password {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #0e0333;
}

.forget-password-img {
  width: 50%;
}

.sub-text-forgot-password {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #757575;
  margin-top: 29px;
}

.password-form-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.forgot-password-input-section label {
  color: #0e0333;
  width: auto;
  display: flex;
  margin-bottom: 16px;
  margin-top: 45px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.forgot-input-active {
  border-bottom: 2px solid rgba(44, 46, 74, 1);
}

.forgot-input-validate {
  border-bottom: 2px solid #1abc9c;
}

.forgot-input-invalid {
  border-bottom: 2px solid #e7433c;
}

.success-forgot-password {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -120%);
  width: 90%;
  height: 50px;
  padding: 12px 20px;
  background: #f6fcfb;
  border: 2px solid #1abc9c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}

.error-email-forgot-pwd {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -120%);
  width: 90%;
  height: 50px;
  padding: 12px 20px;
  background: rgba(231, 76, 60, 0.08);
  border: 2px solid rgba(231, 76, 60, 0.72);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}

.forgot-pwd-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.btn-forgot-pwd-container {
  margin: 0 0 0 0;
}

.btn-forgot-pwd {
  padding: 15px 30px;
  border-radius: 4px;
  height: 42px;
  background: #ffffff;
  border-radius: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
}

.btn-inactive-password {
  transition: all 0.1s linear;
  background-color: #ffffff;
  color: rgba(44, 46, 74, 0.64);
  border: 1px solid rgba(44, 46, 74, 0.64);
  border-radius: 8px;
}

.btn-active-password {
  transition: all 0.1s linear;
  background-color: #0e0333;
  color: #ffffff;
  border: 1px #0e0333 solid;
  text-decoration: none;
  box-shadow: 1px 1px 16px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  cursor: pointer;
}

// rules outside of @media queries apply to devices 1600px and above
// rules here apply to devices from 1024px to 1600px (inclusive)
@media only screen and (max-width: 1600px) {
  .success-forgot-password {
    height: 40px;
    font-size: 16px;
  }

  .error-email-forgot-pwd {
    height: 40px;
    font-size: 16px;
  }
}

// rules here apply to devices from 0px to 1024px (inclusive)
@media only screen and (max-width: 1024px) {
  .success-forgot-password {
    height: 40px;
    padding: 12px 20px;
    font-size: 15px;
  }

  .error-email-forgot-pwd {
    height: 40px;
    padding: 12px 20px;
    font-size: 15px;
  }
}

// tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .success-forgot-password {
    height: 50px;
    padding: auto 15px;
    font-size: 15px;
  }

  .error-email-forgot-pwd {
    height: 50px;
    padding: auto 15px;
    font-size: 15px;
  }

  .btn-forgot-pwd-container {
    margin: 0 0 15px 0;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .success-forgot-password {
    padding: auto 15px;
    font-size: 15px;
  }

  .error-email-forgot-pwd {
    padding: auto 15px;
    font-size: 15px;
  }

  .btn-forgot-pwd-container {
    margin: 0 0 15px 0;
  }
}

// phone
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .success-forgot-password {
    height: 40px;
    padding: auto 20px;
    font-size: 14px;
  }

  .error-email-forgot-pwd {
    height: 40px;
    padding: auto 20px;
    font-size: 14px;
  }
}
