.label-container {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 10% 10% 10% 7% 8% 8% 10% 10% 10% 10%;
  border-bottom: 1px solid #0e0333;
  gap: 10px;
}

.master-label-container {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-bottom: 1px solid #0e0333;
  gap: 10px;
}

.label-field {
  min-width: 0;
  color: #0e0333;
  font-size: 18px;
  font-weight: 600;
  align-self: center;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label-field:last-child {
  min-width: 100px;
  padding-left: 5px;
}

.data-container {
  width: 100%;
  height: auto;
  color: #0e0333;
  display: grid;
  gap: 10px;
  grid-template-columns: 10% 10% 10% 7% 8% 8% 10% 10% 10% 10%;
}

.master-data-container {
  width: 100%;
  height: auto;
  color: #0e0333;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(7, 1fr);
}

.data-field {
  color: #0e0333;
  align-self: center;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  font-size: 16px;
}

#data-box:nth-child(even) {
  background-color: #f2f6ff;
}

.field-disable {
  color: #00b1e0;
}

.ic-general {
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.noHover {
  pointer-events: none;
}

.ic-edit-inactive {
  background-image: url('../../../images/menu-icons/edit-profile.svg');
  background-size: 32px 32px;
}

.ic-connect-inactive {
  background-image: url('../../../images/menu-icons/connect-account.svg');
  background-size: 32px 32px;
}

.first {
  width: 50px;
  height: 20px;
}

// rules outside of @media queries apply to devices 1600px and above
// rules here apply to devices from 1024px to 1600px (inclusive)
@media only screen and (max-width: 1600px) {
}

// rules here apply to devices from 0px to 1024px (inclusive)
@media only screen and (max-width: 1024px) {
  .label-field {
    min-width: 0;
    font-size: 16px;
  }

  .data-field {
    min-width: 0;
    font-size: 14px;
  }
}

//tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1023.9px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023.9px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}
