.input-mod {
  -webkit-appearance: none;
  width: 100%;
}

.input-mod:focus {
  outline: none;
}

.input-mod::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #bcc2cd;
  border-radius: 1.3px;
}

.input-mod::-webkit-slider-thumb {
  height: 20px;
  width: 8px;
  background: #0e0333;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

.input-con {
  -webkit-appearance: none;
  width: 100%;
}

.input-con:focus {
  outline: none;
}

.input-con::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #bcc2cd;
  border-radius: 1.3px;
}

.input-con::-webkit-slider-thumb {
  height: 20px;
  width: 8px;
  background: #0e0333;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}
.input-sat {
  -webkit-appearance: none;
  width: 100%;
}

.input-sat:focus {
  outline: none;
}

.input-sat::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #bcc2cd;
  border-radius: 1.3px;
}

.input-sat::-webkit-slider-thumb {
  height: 20px;
  width: 8px;
  background: #0e0333;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

.image-edit-top-menu {
  position: relative;
  height: 60px;
  background-color: #0e0333;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit-menu-icon {
  cursor: pointer;
  padding: 0 10px;
}

.edit-image {
  overflow: hidden;
  width: 386px;
  height: 541px;
  background-color: #e6ebf4;
  position: absolute;
  top: 12%;
  right: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  cursor: default;
}

.add-annotation {
  overflow: hidden;
  width: 386px;
  height: 55%;
  background-color: #e6ebf4;
  position: absolute;
  top: 18%;
  right: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
}

.add-blur {
  overflow: hidden;
  width: 386px;
  height: 65%;
  background-color: #e6ebf4;
  position: absolute;
  top: 18%;
  right: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  cursor: default;
}

.hd-button {
  position: absolute;
  background-color: #0e0333;
  width: 100px;
  height: 25px;
  color: #fff;
  top: 76px;
  right: 16px;
  z-index: 999;
  font-family: Inter;
  font-weight: 400;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 4px;
  font-size: 12px;
}

.left-toolbar {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 20%;
  width: 40px;
  box-shadow: 0px 8px 10px #00000024, 0px 3px 14px #0000001f, 0px 5px 5px #00000033;
  left: -1px;
  background: #f2f3f7;
  border-top-right-radius: 10px;
  z-index: 2;
  border-bottom-right-radius: 10px;
}

.left-toolbar-icon {
  width: 40px;
  height: 40px;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
}

.toolbar-icon-separator {
  border-bottom: 1px solid rgba(44, 46, 74, 0.2);
}

.left-toolbar-icon-active path {
  fill: #f2f6ff;
}

.label-dropdown .rbt-input-main {
  font-weight: 400;
  color: #fff;
  font-size: 15px;
  width: 208px;
  height: 35px;
  background: rgba(163, 171, 192, 0.2);
  border-radius: 0;
  border-top-left-radius: 5px;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.82);
  margin: 0 1px 0 0;
  line-height: 30px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

.label-dropdown .rbt-input-main::placeholder {
  font-weight: 400;
  color: #fff;
  font-size: 15px;
}

.label-dropdown .rbt-highlight-text {
  background-color: #fff;
}

.custom-floor {
  font-family: Inter;
  width: 85px;
  background: rgba(163, 171, 192, 0.2);
  border: none;
  display: flex;
  outline: none;
  align-items: center;
  justify-content: flex-end;
  color: white;
  height: 32px;
  font-size: 14px;
  padding: 0px 15px;
  font-weight: 400;
  cursor: pointer;
}

.custom-indoor {
  font-family: Inter;
  width: 122px;
  background: rgba(163, 171, 192, 0.2);
  border: none;
  gap: 10px;
  height: 32px;
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  padding: 8px 12px 8px 8px;
  font-weight: 400;
  border-radius: 0px 8px 0px 0px;
  cursor: pointer;
}

.arrow-floor {
  display: none;
}

.arrow-indoor {
  padding-top: 1px;
}

.label-floor {
  position: absolute;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
}

.dropdown-floor-indoor {
  position: absolute;
  width: 100%;
  top: 35.5px;
  overflow: auto;
  overflow-x: hidden;
  background: #ffffff;
  border-radius: 4px;
}

.floor-custom-container-focus {
  position: relative;
  font-weight: 400;
  color: #fff;
  width: 85px;
  margin-right: 1px;
  border-radius: 0px 8px 0px 0px;
  line-height: 30px;
  border-bottom: 2px solid #0038ff;
}

.floor-number {
  position: relative;
  font-weight: 400;
  color: #fff;
  width: 85px;
  margin-right: 1px;
  border-radius: 0px 8px 0px 0px;
  line-height: 30px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.82);
}

.floor-number-indoor {
  position: relative;
  font-weight: 400;
  color: #fff;
  width: 122px;
  margin-right: 1px;
  border-radius: 0px 8px 0px 0px;
  line-height: 30px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.82);
}

.floor-number-indoor-focus {
  position: relative;
  font-weight: 400;
  color: #fff;
  width: 122px;
  margin-right: 1px;
  border-radius: 0px 8px 0px 0px;
  line-height: 30px;
  border-bottom: 2px solid #0038ff;
}

.floor-number:hover,
.label-dropdown .rbt-input-main:hover,
.floor-number-indoor:hover {
  border-bottom: 2px solid #00b1e0;
}

.floor-number select {
  color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 48px;
  font-size: 16px;
  font-weight: 400;
  padding: 0 10px;
}

.edit-image-tabs-presets {
  display: flex;
  align-items: center;
}

.presets-separator {
  border-right: 1px solid #757575;
  height: 24px;
}

ul.edit-image-tabs {
  margin: 10px 73px 0 73px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

ul.edit-image-tabs li {
  position: relative;
  border-bottom: 2px solid transparent;
  transition: all 0.1s linear;
  font-size: 14px;
  margin: 16px 10px;
  padding-bottom: 5px;
  width: 100%;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.edit-image-tabs li.current .image-options-active {
  display: block;
}

.image-options-active {
  display: none;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #00b1e0;
  position: absolute;
  top: -10px;
  left: 9px;
}

ul.still-image-tabs {
  font-weight: 500;
  font-size: 18px;
  color: #0e0333;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  width: 60%;
  margin: 5px auto;
  display: flex;
}

ul.still-image-tabs li {
  border-bottom: 2px solid transparent;
  transition: all 0.1s linear;
  padding: 10px;
  width: 50%;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.still-image-tabs li.current {
  border-bottom: 2px solid #0e0333;
}

.edit-panel {
  width: 266px;
  height: 175px;
  margin: 15px auto;
}

.manual-editing {
  position: relative;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.label-editing-image {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  padding-bottom: 13px;
  justify-content: space-between;
}

.range-custom {
  pointer-events: none;
  width: 8px;
  height: 8px;
  background: #0e0333;
  border-radius: 1px;
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.range-custom-snail-trail {
  pointer-events: none;
  width: 8px;
  height: 4px;
  background: #0e0333;
  border-radius: 1px;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.preset-preview-toggle {
  position: absolute;
  right: 37px;
  bottom: -15px;
  opacity: 0.5;
}

.manual-image-edit {
  border: 1px solid #0e0333;
  border-radius: 4px;
  padding: 2px;
  font-size: 8px;
  width: 21px;
  text-align: center;
}

.preset-container {
  margin: 10px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0 20px;
  height: 176px;
  overflow: auto;
}

.preset-image {
  margin: 10px 0;
  border-radius: 10px;
}

.panorama-select-container {
  z-index: 2;
  overflow: hidden;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #f2f3f7;
  height: 148px;
  display: flex;
  align-items: center;
  cursor: default;
}

.thumbnail-preview {
  border-radius: 8px;
  margin: 0px 12px;
  width: 158px;
  height: 88px;
}

.thumbnail-preview-text {
  font-size: 14px;
  text-align: center;
}

.thumbnail-preview-text-input {
  font-size: 14px;
  text-align: center;
  border: none;
  background: none;
}

.tpt-loading {
  margin-top: 5px;
}

.active-panorama-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #00b1e0;
  position: absolute;
  top: -15px;
  left: 49%;
}

.processing-active-panorama-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #1abc9c;
  position: absolute;
  top: 10px;
  left: 49%;
}

.add-an-image-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.active-panorama,
.inactive-panorama {
  margin: 5px auto;
  width: 158px;
  margin-bottom: 0;
  border: 3px solid #00b1e0;
  background-color: #00b1e0;
  border-radius: 10px;
}

.inactive-panorama {
  border: 3px solid transparent;
  background-color: transparent;
}

.viewer-button-sliders {
  transition: all 0.1s linear;
  border: 0;
  background: none;
  z-index: 1;
  flex-grow: 1;
  cursor: pointer;
}

.panorama-container {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.list-panoramas-bottom {
  width: 75vw;
  height: 148px;
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
}

.preview-panorama-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.uploading-image-spinner {
  position: absolute;
  top: 27px;
  left: 58px;
}

.info-box {
  z-index: 2;
  height: 52px;
  width: auto;
  padding: 15px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 150%);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 8px;
  background: #0e0333;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  cursor: unset;
}

.info-box-active {
  cursor: pointer;
}

.info-box img {
  margin-right: 15px;
}

.starting-pano-flag {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.starting-pano-bg {
  background: #0e03338a;
  border-radius: 8px;
  height: 88px;
  width: 158px;
  position: absolute;
}

.starting-pano-click-section {
  border-radius: 8px;
  height: 88px;
  width: 141px;
  position: absolute;
  left: 12px;
}

.tooltip-editor {
  position: absolute;
  top: 22px;
  left: 115px;
}

.dropdown-item.disabled {
  display: none;
}

/*--------------------------------------   Big Screens   -------------------------------------------*/
@media (min-width: 1600px) {
  .list-panoramas-bottom {
    width: 82vw;
  }
}

/*--------------------------------------   FireFox   -------------------------------------------*/
@-moz-document url-prefix() {
  .floor-number select {
    text-indent: 40px;
    padding: 0;
  }
}
