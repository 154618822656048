.search-input-container {
  width: 29.8vw;
  position: relative;
}

.search-input-container-floor {
  width: 32.6vw;
  position: relative;
  left: 20px;
}

.search-input {
  font-size: 1rem;
  padding: 0 56px 0 16px;
  font-weight: 400;
  width: 100%;
  height: 3.5rem;
  background: #ffffff;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  -webkit-appearance: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.search-state {
  background-image: url('../../../images/dashboard-icons/ic-search-active.svg'),
    url('../../../images/dashboard-icons/card-icons/ic-search-focus.svg');
  width: 24px;
  background-repeat: no-repeat;
  height: 24px;
  background-size: auto, 0;
}

.search-input-container:focus-within .search-state {
  background-size: 0, auto;
}

.search-icon {
  position: absolute;
  border: none;
  cursor: pointer;
  background: none;
  width: 56px;
  height: 56px;
  right: 0;
}

.search-icon-floor {
  position: relative;
  border: none;
  cursor: pointer;
  background: none;
  width: 56px;
  height: 56px;
  right: 47px;
  top: 5px;
}

.search-focus {
  font-size: 1rem;
  padding: 0 56px 0 16px;
  font-weight: 400;
  width: 100%;
  height: 3.5rem;
  background: #ffffff;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  -webkit-appearance: none;
  border: 1px solid #0e0333;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}
