.activate-account-initial-pages-container {
  background-color: #ffffff;
  margin-right: auto;
  margin-left: auto;
  width: 65%;
  height: 70%;
  grid-gap: 50px;
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  padding: 40px 50px;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
}

.title-activate-account {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #0e0333;
}

.sub-text-activate-account {
  color: #757575;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.activate-account-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 29px;
}

.image-activated {
  width: 106px;
  height: 106px;
  margin-bottom: 30px;
}

.activate-account-container h3 {
  margin-bottom: 30px;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
}

.message {
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.message .bold {
  position: relative;
  right: 55px;
  bottom: 25px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.name-activate-account {
  position: absolute;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.a-ac-btn-container-link {
  margin-top: 10px;
  min-width: 170px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #0e0333;
  border: none;
  border-radius: 8px;
  padding: 0 12px 0 12px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}

.a-ac-btn-container-link a {
  text-decoration: none;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.a-ac-btn-container {
  margin-top: 50px;
}

.btn-activate-account {
  width: 230px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #0e0333;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 15px 32px;
  outline: none;
  border: none;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.no-received-email {
  position: relative;
  bottom: 0;
  left: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #757575;
}

// rules outside of @media queries apply to devices 1600px and above
// rules here apply to devices from 1024px to 1600px (inclusive)
@media only screen and (max-width: 1600px) {
  .image-activated {
    width: 96px;
    height: 96px;
    margin-bottom: 20px;
  }

  .activate-account-container h3 {
    margin-bottom: 20px;
    font-size: 21px;
  }

  .message {
    margin-bottom: 25px;
    font-size: 15px;
  }

  .name-activate-account {
    font-size: 15px;
  }

  .a-ac-btn-container {
    margin-top: 30px;
  }

  .btn-activate-account {
    width: 220px;
    height: 42px;
    padding: 10px 15px;
    font-size: 15px;
  }
}

// rules here apply to devices from 0px to 1024px (inclusive)
@media only screen and (max-width: 1024px) {
  .image-activated {
    width: 76px;
    height: 76px;
    margin-bottom: 10px;
  }

  .activate-account-container h3 {
    margin-bottom: 10px;
    font-size: 20px;
  }

  .message {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .message .bold {
    font-size: 14px;
  }

  .a-ac-btn-container {
    margin-top: 60px;
  }

  .btn-activate-account {
    width: 220px;
    height: 42px;
    padding: 5px 15px;
    font-size: 14px;
  }
}

// tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .image-activated {
    width: 86px;
    height: 86px;
    margin-bottom: 20px;
  }

  .activate-account-container h3 {
    margin-bottom: 20px;
    font-size: 21px;
  }

  .message {
    margin-bottom: 35px;
    font-size: 15px;
  }

  .name-activate-account {
    font-size: 15px;
  }

  .a-ac-btn-container {
    margin-top: 30px;
  }

  .btn-activate-account {
    width: 220px;
    height: 42px;
    padding: 5px 15px;
    font-size: 15px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .image-activated {
    width: 86px;
    height: 86px;
    margin-bottom: 20px;
  }

  .activate-account-container h3 {
    margin-bottom: 20px;
    font-size: 21px;
  }

  .message {
    margin-bottom: 35px;
    font-size: 15px;
  }

  .message .bold {
    font-size: 15px;
  }

  .a-ac-btn-container {
    margin-top: 30px;
  }

  .btn-activate-account {
    width: 220px;
    height: 42px;
    padding: 5px 15px;
    font-size: 15px;
  }
}

// phone
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .image-activated {
    width: 56px;
    height: 56px;
    margin-bottom: 10px;
  }

  .activate-account-container h3 {
    margin-bottom: 10px;
    font-size: 20px;
  }

  .message {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .name-activate-account {
    font-size: 14px;
  }

  .a-ac-btn-container {
    margin-top: 30px;
  }

  .btn-activate-account {
    width: 220px;
    height: 42px;
    padding: 5px 15px;
    font-size: 14px;
  }
}
