.image-loading-spinner {
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-loading-spinner img {
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #0e0333;
  border-right: 10px solid #0e0333;
  border-bottom: 10px solid #0e0333;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Smaller Spinner

.bg-loader-smaller {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 20;
  height: 100vh;
  width: 100vw;
  background: #0e033352;
}

.image-loading-spinner-smaller {
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-loading-spinner-smaller img {
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-smaller {
  border: 10px solid #fff;
  border-radius: 50%;
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  width: 60px;
  height: 60px;
  -webkit-animation: spin-smaller 2s linear infinite;
  animation: spin-smaller 2s linear infinite;
}

@-webkit-keyframes spin-smaller {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin-smaller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/***** Uploading spinner *******/

.uploading-loader {
  border: 6px solid #c1c2c56b;
  border-radius: 50%;
  border-top: 6px solid #0e0333;
  width: 40px;
  height: 40px;
  -webkit-animation: uploading-spin 2s linear infinite; /* Safari */
  animation: uploading-spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes uploading-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes uploading-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
