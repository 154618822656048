.full-screen {
  z-index: 2;
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: #00000065;
}

.am-modal {
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 700px;
  height: auto;
  background: #ffffff;
  text-align: center;
  border-radius: 4px;
}

.am-modal-header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #0e0333;
  height: 8vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.am-modal-header-title {
  color: white;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.am-header-close-icon {
  height: 20px;
  width: 20px;
  margin: 0 0 0 auto;
  position: absolute;
  top: 25px;
  right: 25px;
}

.am-modal-body {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
  padding: 35px 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 30px;
}

.am-modal-body-delete {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
  padding: 30px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 25px;
}

.am-modal-body-text {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.modal-body-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  height: 42px;
}

.modal-button {
  width: fit-content;
  height: 42px;
  text-align: center;
  padding: 10px 12%;
  border-radius: 8px;
  border: 1px solid rgba(44, 46, 74, 1);
}

.disable-button {
  border: 1px solid rgba(44, 46, 74, 0.64);
  color: rgba(44, 46, 74, 0.64);
  background-color: #fff;
  cursor: default;
}

.confirm-button {
  background-color: #0e0333;
  color: #ffffff;
}

.delete-button {
  color: #ffffff;
  background-color: #e74c3c;
  border: none;
}

.confirm-email-input {
  height: 45px;
  max-width: 315px;
  padding: 1rem 3rem 1rem 1rem;
  font-size: 1rem;
  background: #f5f7fb;
  border: none;
  border-bottom: 2px solid rgba(44, 46, 74, 0.42);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.input-black {
  border-bottom: 2px solid #292c44;
}

// rules outside of @media queries apply to devices 1600px and above
// rules here apply to devices from 1024px to 1600px (inclusive)
@media only screen and (max-width: 1600px) {
  .am-modal-header-title {
    color: white;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }

  .am-modal-body-text {
    width: auto;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
  }

  .am-modal-body {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 100%;
    padding: 35px 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 30px;
  }

  .am-modal-body-delete {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 100%;
    padding: 30px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
  }
}

// rules here apply to devices from 0px to 1024px (inclusive)
@media only screen and (max-width: 1024px) {
  .am-modal-header-title {
    color: white;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }

  .am-modal-body-text {
    width: auto;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
  }

  .am-modal-body {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 100%;
    padding: 35px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 30px;
  }

  .am-modal-body-delete {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 100%;
    padding: 30px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
  }
}

// tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}
