.radio-button {
  border-radius: 50%;
  background-color: #0e0333;
  border-color: none;
  cursor: pointer;
}

.radio-circle {
  border-radius: 50%;
  border: 2px solid #0e0333;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
