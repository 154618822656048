.container-image-preview {
  position: relative;
  height: 150px;
  margin: 0 8px;
}

.viewer-preview-image-upload {
  width: 200px;
  height: 100px;
  border-radius: 8px;
}

.image-name-container {
  width: 200px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-image-preview .btn-cross {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  right: -10px;
  top: 0px;
  height: 24px;
}

.container-image-preview:hover .btn-cross {
  visibility: visible;
}

.btn-retry-general {
  z-index: 5;
  position: absolute;
}

.btn-error-general {
  z-index: -187px;
  position: absolute;
}

.image-error-container {
  z-index: 1;
  position: absolute;
  right: 0x;
  top: 10px;
  background: linear-gradient(0deg, rgba(231, 76, 60, 0.5), rgba(231, 76, 60, 0.5));
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 200px;
  height: 100px;
  border-radius: 8px;
}

.image-error-icon {
  position: absolute;
  left: 187px;
  z-index: 10;
}

.image-retry-icon {
  position: absolute;
  left: -8px;
  z-index: 10;
}

.image-pending-container {
  z-index: 1;
  position: absolute;
  right: 0px;
  top: 10px;
  background: #f2f6ff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 200px;
  height: 100px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-image-name {
  border: none;
  background-color: transparent;
  font-weight: 400;
  font-size: 14px;
  font-family: Inter;
  color: #0e0333;
  margin-left: 10px;
}

.edit-name-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.pen-edit-btn {
  margin-left: -5px;
}
