//tooltip

.highlight-panorama {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.highlight-drag-drop {
  overflow-x: hidden !important;
  overflow-y: auto;
}

.highlight-tour-type {
  width: 67% !important;
  height: 105px !important;
}

.highlight-tour-input {
  height: 350px !important;
}

.highlight-tour-map {
  width: 65px !important;
  height: 65px !important;
}

.highlight-edit-card {
  width: 1380px;
  height: 195px;
}

.highlight-edit-panorama {
  width: 208px !important;
  height: 150px !important;
}

.lock-leads-icons-cards-grid {
  display: flex;
  gap: 16px;
  justify-content: space-evenly;
  position: absolute;
  left: 0px;
  padding: 16px 18px;
  top: 0px;
}

.create-tour-container {
  position: relative;
  width: 90%;
  height: 100%;
  min-width: 1100px;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  margin: 10px auto 0 auto;
}

.create-tour-steps {
  background: #0e0333;
  font-size: 1.25rem;
  display: flex;
  gap: 80px;
  height: 68px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  justify-content: center;
  align-items: center;
}

.create-tour-info {
  margin: 20px auto 0 auto;
  width: 80%;
}

.create-tour-info-choose-settings {
  margin: 70px auto 0 auto;
  width: 80%;
}

.countries-input .rbt-input-main:hover {
  background: #e8efff;
}

.countries-input .rbt-input-main {
  background: #f2f6ff;
  border: none;
  border-bottom: 2px solid #0e0333;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  height: 52px;
  min-width: 250px;
  padding: 16px 48px 16px 16px;
  width: 100%;
}

.countries-input .rbt-input-main:focus {
  border-bottom: 2px solid #0038ff;
}

.address-input-setting:focus-within .create-tour-input-labels {
  color: #0038ff;
}

.create-tour-input:focus-within .create-tour-labels {
  color: #0038ff;
}
.create-tour-input:focus-within .create-tour-input-labels {
  color: #0038ff;
}

.countries-input .rbt-input-main::placeholder {
  font-family: Inter;
  font-size: 14px;
  color: #757575;
}

.create-tour-btn-size {
  height: 42px;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.measurements-sections {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.measurements-btn-popup {
  font-weight: 400;
  font-size: 12px;
  text-decoration: underline;
  color: #0e0333;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.how-to-measure-tick {
  position: absolute;
  right: 20px;
  top: 20px;
}
.switch {
  margin-top: 20px;
  position: relative;
  display: inline-block;
  width: 51px;
  height: 31px;
  left: 210px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 1px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #0e0333;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0e0333;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(23px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.help-text-pictures {
  width: 554px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 16px;
  height: 34px;
  left: 50%;
  transform: translate(-50%, 20%);
  color: #0e0333;
  font-size: 18px;
  font-weight: 400;
  cursor: default;
}

.container-upload-btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;
}

.btn-upload-images {
  min-width: 170px;
  height: 42px;
  border: 1px #0e0333 solid;
  background: #fff;
  font-style: normal;
  font-weight: normal;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  text-align: center;
  color: #818aa6;
  left: 50%;
  pointer-events: none;
  padding: 0 12px 0 12px;
}

.btn-upload-images-active {
  background: #0e0333;
  color: white;
  pointer-events: visible;
  cursor: pointer;
}

.upload-images-container {
  margin: 30px 0;
  width: 90%;
  height: 70%;
  display: flex;
  flex-direction: column;
}

.upload-panoramas-go-to-studio {
  width: 100%;
  align-items: center;
  position: relative;
  display: flex;
  bottom: 20px;
  justify-content: center;
}

//*------------------------------------------------------------------------------------------------------------------
.center-input {
  position: absolute;
  cursor: pointer;
  left: 50%;
  // height: 300px;
  width: 100%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  color: transparent;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
}

.center-input-still-image {
  position: absolute;
  cursor: pointer;
  left: 50%;
  height: 200px;
  width: 100%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  color: transparent;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
}

.helper-text {
  position: relative;
  top: 50%;
  left: 50%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 18px;
  color: #0e0333;
  font-weight: 400;
  line-height: 21px;
  width: 25%;
  padding: 15px 0 0 0;
}

.files-preview-container {
  display: flex;
  flex-wrap: wrap;
  height: 350px;
  width: 100%;
  overflow: scroll;
  gap: 20px;
}

.file-preview-inactive {
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  height: 350px;
  width: 100%;
  display: none;
}

.file-drop-zone {
  overflow: hidden;
  position: relative;
  height: 130px;
  border: 2px dashed rgba(44, 46, 74, 0.54);
  margin-top: 4vh;
  box-sizing: border-box;
  margin-bottom: 16px;
  border-radius: 8px;
}

.file-drop-zone-big {
  overflow: hidden;
  position: relative;
  height: 480px;
  border: 2px dashed rgba(44, 46, 74, 0.54);
  margin-top: 4vh;
  box-sizing: border-box;
  margin-bottom: 16px;
  border-radius: 8px;
}

.edit-tour-container {
  background-color: #ffffff;
  border-radius: 8px;
  width: 98%;
  margin: 15px auto 0 2%;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
}

.no-links-state {
  width: 64%;
}

.edit-tour-edit-images-container {
  background-color: #ffffff;
  border-radius: 4px;
  width: 98%;
  margin: 16px 0 60px 2%;
  box-shadow: (1px 1px 16px rgba(0, 0, 0, 0.16));
  cursor: default;
}

.container-no-links {
  display: flex;
  margin-top: 56px;
}

.no-images-state {
  width: 60%;
}

.container-text-no-links {
  height: 380px;
  display: flex;
  flex-direction: column;
  margin-left: 60px;
}

.title-text-no-links {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #0e0333;
  width: 337px;
}

.sub-text-no-links {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #757575;
  width: 271px;
  margin-top: 29px;
}

.button-no-links {
  width: 170px;
  height: 42px;
  border-radius: 8px;
  color: white;
  background-color: #0e0333;
  margin-top: 86px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
  padding: 0 12px 0 12px;
}

.container-no-images {
  display: flex;
  gap: 130px;
  margin-top: 56px;
}

.container-text-no-images {
  height: 320px;
  display: flex;
  flex-direction: column;
  margin-left: 60px;
}

.title-text-no-images {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #0e0333;
  width: 234px;
}

.sub-text-no-images {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #757575;
  width: 245px;
  margin-top: 29px;
}

.button-no-images {
  width: 173px;
  height: 42px;
  border-radius: 8px;
  color: white;
  background-color: #0e0333;
  margin-top: 86px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}

.no-floor-plan {
  width: 60%;
  position: relative;
  right: 50px;
  bottom: 50px;
}

.container-floor-plan {
  display: flex;
  margin-top: 56px;
}

.container-text-no-floor {
  display: flex;
  flex-direction: column;
  margin-left: 60px;
}

.title-text-no-floor {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #0e0333;
  width: 85%;
}

.sub-text-no-floor {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #757575;
  width: 70%;
  margin-top: 29px;
}

.button-no-floor {
  width: 173px;
  height: 42px;
  border-radius: 8px;
  color: white;
  background-color: #0e0333;
  margin-top: 86px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
}

.button-no-floor-empty {
  width: 170px;
  height: 42px;
  border-radius: 8px;
  color: white;
  background-color: #0e0333;
  margin-top: 86px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
  padding: 0 12px 0 12px;
}

.button-no-floor-save-change {
  width: 173px;
  height: 42px;
  border-radius: 8px;
  position: absolute;
  bottom: 70px;
  color: white;
  background-color: #0e0333;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
}

.button-no-floor-sucess {
  width: 173px;
  height: 42px;
  border-radius: 8px;
  color: white;
  background-color: #0e0333;
  margin-top: 86px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
}

.button-no-floor-disabled {
  background-color: #c7ccda !important;
  color: #818aa6 !important;
  border: none;
}

.text-strong {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.contain-arrow {
  position: relative;
}

.arrow-back {
  width: 100%;
  padding: 0px 16px 20px 24px;
}

.go-back-edit-tour {
  display: flex;
  gap: 8px;
}

.tour-row-address-edit {
  display: flex;
  gap: 12px;
}

.edit-tour-name {
  border: none;
  background-color: transparent;
}

.back-edit-tour-text {
  font-family: Inter;
  font-size: 14px;
  color: #0e0333;
  font-weight: 500;
  text-decoration: none;
}

.dots-dropdown-menu-edit {
  padding: 8px 0;
  height: 85%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.edit-error {
  width: 84%;
  position: relative;
  left: 8.8vw;
  padding: 2vh;
}

.download-tour-main-image {
  height: 180px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 30%;
}

.image-for-download {
  position: relative;
  width: 200px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  border-radius: 8px;
}

.image-for-download-container {
  position: relative;
  margin: 30px;
  width: 200px;
}

.image-for-download-container-floorplan {
  cursor: pointer;
  margin: 0 20px;
}

.image-disable {
  opacity: 0.4;
  cursor: default;
}

.custom-context-menu-edit a {
  text-decoration: none;
}

.container-download-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 18px 18px;
}

.container-hotspot-images-floorplan {
  height: 340px;
  overflow: auto;
}

.container-download-images-floorplan {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 220px;
  overflow: auto;
  flex-wrap: wrap;
  width: 78%;
}

.edit-panorama-views {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.analytics-panorama-views {
  padding: 20px 0;
  border-radius: 8px;
}

.tour-item-text-edit {
  margin-left: 30px;
  text-decoration: none;
  cursor: pointer;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.edit-tour-analytics {
  margin: 0 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.edit-tour-analytics-doughnut {
  width: 70%;
  margin: 0 20px;
  padding: 10px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 33px 35px;
}

.show-btn:hover .on-hover-hide {
  visibility: visible;
}

.show-btn:hover .on-hover-hide-download-image {
  visibility: visible;
}

#download-panoramas:hover .on-hover-download-single {
  visibility: visible;
}
#edit-panoramas:hover .on-hover-download-single {
  visibility: visible;
}

.on-hover-hide-download-image {
  visibility: hidden;
}

.on-hover-download-single {
  visibility: hidden;
}

.download-equi-btn {
  width: 81px;
  height: 40px;
  background: #0e0333;
  position: absolute;
  top: 30px;
  border: none;
  right: 110px;
  border-radius: 4px;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.25);
  color: white;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.download-2d-btn {
  width: 81px;
  height: 40px;
  position: absolute;
  top: 30px;
  left: 110px;
  border: none;
  background: #0e0333;
  color: white;
  border-radius: 4px;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.25);
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.back-icon {
  height: 16px;
}

.edit-btn-hover-download-all {
  position: absolute;
  top: 50%;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.25);
  left: 50%;
  transform: translate(-50%, -50%);
  width: 255px;
  height: 44px;
  background: #0e0333;
  border-radius: 4px;
  color: #ffffff;
  border: 0;
  font-weight: 400;
  font-size: 1rem;
}

.img-label {
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #0e0333;
}

.archived-tours {
  padding: 3vh 0 2vh;
  border-bottom: 1px solid #0e0333;
  font-size: 1rem;
  font-weight: 400;
  color: #0e0333;
  width: 100%;
  margin-bottom: 30px;
  cursor: default;
}

.create-tour-input-container {
  display: flex;
  gap: 56px;
  width: 100%;
  cursor: default;
}

.create-tour-input {
  flex: 1;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.create-tour-labels {
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  color: #0e0333;
}

.create-tour-labels-disabled {
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  color: #818aa6;
}

.choose-tour-settings-labels {
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  color: #0e0333;
  width: 75%;
}

.create-tour-labels-focus {
  color: #0038ff;
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
}

.create-tour-input-labels {
  font-weight: 500;
  margin: 20px 0 10px 0;
  color: #0e0333;
}

.create-tour-input-labels-focus {
  font-weight: 400;
  margin: 20px 0 10px 0;
  color: #0038ff;
}

.maps-button {
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  right: 0;
  height: 42px;
  border-bottom: 2px solid rgba(44, 46, 74, 0.54);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.check-tour-text {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.create-tour-choose-settings {
  display: flex;
  gap: 40px;
  justify-content: space-between;
}

.rbt-menu {
  border-radius: 4px;
  background-color: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  height: auto;
}

.dropdown-menu {
  display: flex !important;
  flex-direction: column;
}

.dropdown-item {
  padding: 1rem 1rem 1rem 1rem;
  text-decoration: none;
  color: #8b8d9b;
}

.dropdown-item:hover {
  color: #2c2f48;
  background-color: #f3f5f9;
}

.rbt-input-main {
  height: 3rem;
  width: 28.125rem;
  padding: 1rem 3rem 1rem 1rem;
  font-size: 1rem;
  background: #f5f7fb;
  border: none;
  border-bottom: 2px solid grey;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.edit-tour-header-tabs-container {
  display: flex;
  align-items: center;
  gap: 30px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  color: #0e0333;
}

.edit-tour-header-tabs-container li {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5vh;
  cursor: pointer;
  font-family: Inter;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  margin: 0 70px 0 0px;
  padding: 0 10px;
}

.edit-tour-header-tabs-container li.current {
  font-weight: 700;
  border-bottom: 2px solid #0e0333;
}

.floorplan-images {
  display: flex;
}

.floorplan-container {
  width: 100%;
}

.floorplan-container-modal {
  width: 100%;
  height: 445px;
  padding: 30px;
}

.selected-floorplan-pop-up {
  height: 100%;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25));
  border-radius: 8px;
  object-fit: contain;
  user-select: none;
}

.floorplan-delete-cross {
  border: none;
  z-index: 1;
  cursor: pointer;
  background-image: url('../../../images/general-icons/cross-inactive.svg');
  position: absolute;
  right: 0;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
}

.floorplan-delete-cross:hover {
  background-image: url('../../../images/general-icons/cross.svg');
}

.expand-button {
  border: none;
  z-index: 1;
  cursor: pointer;
  background-image: url('../../../images/general-icons/expand.svg');
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  background-size: 40px 40px;
}

.expand-button:hover {
  background-image: url('../../../images/general-icons/expand-active.svg');
}

.minimise-button {
  border: none;
  z-index: 1;
  cursor: pointer;
  background-image: url('../../../images/general-icons/minimise.svg');
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  background-size: 40px 40px;
}

.minimise-button:hover {
  background-image: url('../../../images/general-icons/minimise-active.svg');
}

.expanded-floorplan-container {
  background: rgba(44, 46, 74, 0.32);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  z-index: 10;
  justify-content: center;
  align-items: center;
}

.profile-countries-container {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  .rbt-input-main {
    height: 52px;
    min-width: 250px;
    width: 100%;
    padding: 16px 48px 16px 16px;
    font-size: 16px;
    background: #f2f6ff;
    border: none;
    border-bottom: 2px solid #0e0333;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;
  }

  .rbt-menu {
    border-radius: 4px;
    background-color: #fff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    height: 350px;
  }

  .dropdown-menu {
    display: flex !important;
    flex-direction: column;
  }

  .dropdown-item {
    padding: 1rem 1rem 1rem 1rem;
    text-decoration: none;
    color: #8b8d9b;
  }

  .dropdown-item:hover {
    color: #2c2f48;
    background-color: #f3f5f9;
  }
}

.expanded-floorplan {
  position: relative;
  height: 70%;
}

.floorplan-selected-container {
  position: relative;
  height: 350px;
}

.floorplan-hotspot {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #0e0333;
  border-radius: 50%;
  display: inline-block;
  margin-left: -10px;
  margin-top: -10px;
}

.search-disabled-floorplan::placeholder {
  opacity: 0.4;
}

.container-floorplan-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 18px 35px;
}

.floorplan-option-container {
  padding: 10px;
  border: 1px solid rgba(200, 201, 209, 0.7);
  border-radius: 8px;
  background: transparent;
  margin: 0px 0 10px 0;
  width: 84px;
  height: 93px;
  cursor: pointer;
}

.hotspot-delete {
  cursor: pointer;
  position: absolute;
  bottom: 6px;
  left: -60px;
  background: #ffffff !important;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  width: 140px;
  display: flex;
  height: 34px;
  justify-content: space-evenly;
  color: #0e0333;
  align-items: center;
}

.hotspot-hover-container {
  text-align: center;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 8px;
  width: 240px;
  position: absolute;
  height: 153px;
  bottom: 9px;
  left: -120px;
}

.floorplan-list-left {
  display: flex;
  flex-direction: column;
  padding-right: 10%;
  height: 500px;
  width: 130px;
  overflow-x: hidden;
}

.floorplan-option {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

// big screen
@media (min-width: 1750px) {
}

@media (max-width: 1600px) {
  .button-no-floor {
    margin-top: 20px;
  }

  .button-no-floor-save-change {
    bottom: 10px;
  }

  .button-no-floor-sucess {
    margin-top: 45px;
  }

  .viewer-upload-preview {
    height: 125px;
  }
}

/*--------------------------------------   Mobile Phone   -------------------------------------------*/

@media (max-width: 800px) {
}
