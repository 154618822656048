.content-wrapper-account-management {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.am-header,
.am-header-no-content {
  width: 99%;
  display: flex;
  gap: 48px;
  flex-direction: row;
  align-items: center;
  gap: auto;
  margin-bottom: 40px;
}

.am-header-no-content {
  justify-content: flex-end;
}

.header-AM {
  display: flex;
  justify-content: space-between;
  height: 5vh;
}

.filter-admin {
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  .rbt-input-main {
    height: 52px;
    width: 100%;
    padding: 16px 48px 16px 16px;
    font-size: 16px;
    background: #ffffff;
    border: none;
    border-radius: 8px;
    box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
  }
}

.arrow-filter {
  position: absolute;
  top: 22px;
  right: 25px;
}

.arrow-filter-disabled {
  position: absolute;
  top: 15px;
  right: 25px;
}

.btn-create-new-account {
  height: 42px;
  position: relative;
  min-width: 170px;
  left: 0;
  padding: 0 12px 0 12px;
  border: none;
  border-radius: 8px;
  background-color: #d50057;
  color: white;
  cursor: pointer;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}

.btn-create-new-account-modal {
  margin-top: 57px;
  width: 60%;
  height: 42px;
  background: #0e0333;
  border-radius: 8px;
  color: white;
}

.btn-am-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  font-style: normal;
}

.sort-by-num-accounts-container {
  width: 99%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #0e0333;
  margin-bottom: 30px;
  color: #0e0333;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.AM-data-container {
  margin: 30px 0 0 0;
  height: 100%;
}

.AM-header-tabs-container {
  display: flex;
  align-items: center;
  gap: 40px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.AM-header-tabs-container li {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5vh;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-family: Inter;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  padding: 16px;
}

.AM-header-tabs-container li.current {
  font-weight: 700;
  border-bottom: 2px solid #0e0333;
}

.am-sort-by-container {
  position: relative;
  right: 12px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-end;
  gap: 15px;
  margin-bottom: 12px;
}

.am-sort-by-label {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #0e0333;
}

.am-custom-select {
  height: 40px;
  min-width: 200px;
  border: none;
  text-align: left;
  padding: 11px 42px 11px 24px;
  position: relative;
  background: #ffffff;
  font-size: 15px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #0e0333;
  cursor: pointer;
  border-bottom: 2px solid #0e0333;
}

.am-custom-select-focus {
  height: 40px;
  min-width: 200px;
  border: none;
  text-align: left;
  padding: 11px 42px 11px 24px;
  position: relative;
  background: #ffffff;
  font-size: 15px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #0e0333;
  cursor: pointer;
  border-bottom: 2px solid #0038ff;
}

.am-custom-arrow {
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translate(0, -50%);
}

.am-pagination-container {
  margin: auto 0 0 0;
}

.account-not-found-image {
  width: 100%;
}

.no-account-img {
  width: 50%;
  margin-right: 50px;
}

.no-account-container {
  background: #ffffff;
  border-radius: 16px;
  width: 65%;
  margin-top: 35px;
  height: 500px;
  display: flex;
  padding: 30px 0;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
  margin-left: auto;
  margin-right: auto;
}

.account-not-found-container {
  background: #ffffff;
  border-radius: 16px;
  width: 65%;
  margin-top: 35px;
  height: 431px;
  display: flex;
  padding: 50px 50px 10px 50px;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
}

.container-text-no-account {
  display: flex;
  flex-direction: column;
  gap: 29px;
  justify-content: center;
}

.container-text-account-not-found {
  display: flex;
  flex-direction: column;
  gap: 29px;
  width: 49%;
  height: 153px;
  position: absolute;
}

.title-account-not-found {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #0e0333;
}

.title-no-account {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #0e0333;
}

.sub-text-account-not-found {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #757575;
}

.sub-text-no-account {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #757575;
}

// ----------------- tooltips css styles -------------------
.am-name-email-table-highlight-layer {
  width: 600px !important;
  height: 300px !important;
  overflow: unset !important;
}

.am-name-email-table-tooltip-layer {
  transform: translate(211%, 0) !important;
}

.am-live-tours-created-login-table-highlight-layer {
  width: 700px !important;
  height: 300px !important;
}

.am-edit-account-btn-highlight-layer {
  transform: translate(0, 300%) !important;
}

.am-account-dashboard-btn-highlight-layer {
  transform: translate(0, 300%) !important;
}

.am-edit-account-btn-tooltip-layer {
  transform: translate(0, 70%) !important;
}

.am-account-dashboard-btn-tooltip-layer {
  transform: translate(0, 60%) !important;
}

// tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}
