/****************************   signup.tsx   *****************************/
.password-eye {
  width: 24px;
  height: 24px;
}

.signup-initial-pages-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 580px;
  width: 546px;
  padding: 40px 48px 48px 48px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
  cursor: default;
}

.signup-form-container,
.signup-agree-terms-container {
  border: 1px solid transparent;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.signup-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.signup-input-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  margin-top: 0;
}

#last-input-section-signup {
  position: relative;
  margin-bottom: 0;
}

.signup-input-section label {
  color: #0e0333;
  width: auto;
  display: inline-block;
  margin-bottom: 10px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.input-pwd-valid {
  border-bottom: 2px solid rgba(44, 46, 74, 0.42);
}

.input-pwd-invalid {
  border-bottom: 2px solid red;
}

.input-populated {
  border-bottom: 2px solid rgba(44, 46, 74, 1);
}

.login-input-show-password-icon {
  border: none;
  position: absolute;
  bottom: 45%;
  left: 100%;
  transform: translate(-150%, 100%);
  margin: 0;
  padding: 0;
  width: fit-content;
  background: none;
  cursor: pointer;
}

// ! password-requirements box
.pwd-should-contain-section-signup {
  position: absolute;
  top: 45%;
  left: 95%;
  width: 407px;
  height: 187px;
  margin-left: 10%;
  padding: 10px;
  background: #eff0f6;
  border: 2px solid #7f8192;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pwd-should-contain-section-signup:after,
.pwd-should-contain-section-signup:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.pwd-should-contain-section-signup:after {
  border-color: rgba(239, 240, 246, 0);
  border-right-color: #eff0f6;
  border-width: 15px;
  margin-top: -15px;
}

.pwd-should-contain-section-signup:before {
  border-color: rgba(127, 129, 146, 0);
  border-right-color: #7f8192;
  border-width: 18px;
  margin-top: -18px;
}

.pwd-should-contain-title-signup {
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.pwd-check-status-signup {
  width: 20px;
  height: 20px;
}

.pwd-should-contain-signup {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  span {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
}
// ! password-requirements box

.signup-agree-terms-section {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 50px;
}

// ! Custom checkbox
.signup-agree-terms-section input {
  width: 20.8px;
  height: 20.8px;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.custom-checkbox {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid rgba(0, 0, 0, 1);
  cursor: pointer;
  border-radius: 50%;
}
// When checkbox is checked, add a white background
.signup-agree-terms-section input:checked ~ .custom-checkbox {
  background-color: white;
}
// Create the checkmark/indicator (hidden when not checked)
.custom-checkbox:after {
  content: '';
  display: none;
  position: absolute;
}
// Show the checkmark when checked
.signup-agree-terms-section input:checked ~ .custom-checkbox:after {
  display: block;
}
// style the checkmark/indicator
.signup-agree-terms-section .custom-checkbox:after {
  left: 2px;
  top: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #0e0333;
}

.signup-agree-terms-section label {
  margin-left: 16px;
  color: #0e0333;
  font-weight: 400;
  margin-bottom: 1px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.login-signup-container-btn {
  position: relative;
  margin: 0 auto 0 auto;
}

.btn-signup {
  height: 42px;
  min-width: 170px;
  border-radius: 8px;
  color: white;
  background-color: #0e0333;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  cursor: pointer;
  padding: 0 12px 0 12px;
}

.btn_inactive {
  transition: all 0.1s linear;
  background-color: #ffffff;
  color: rgba(37, 38, 61, 0.64);
  border: 1px solid rgba(44, 46, 74, 0.64);
}

.btn_active {
  transition: all 0.1s linear;
  background-color: #0e0333;
  color: #ffffff;
  border: 1px #0e0333 solid;
  text-decoration: none;
  box-shadow: 1px 1px 16px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.btn_active a {
  text-decoration: none;
}

.signup-error-message {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -110%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  height: 50px;
  width: 90%;
  padding: 8px 16px;
  background: rgba(231, 76, 60, 0.08);
  border: 2px solid rgba(231, 76, 60, 0.72);
  box-sizing: border-box;
  border-radius: 8px;
}

/****************************   agree-terms.tsx   *****************************/
ul.login-signup-agree-terms-tabs {
  padding: 0;
  margin-top: -5px;
  gap: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
}

ul.login-signup-agree-terms-tabs li {
  margin: 0 auto;
  padding: 0.5rem 0;
  cursor: pointer;
  display: flex;
  color: #0e0333;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

ul.login-signup-agree-terms-tabs li.current {
  border-bottom: 2px solid #0e0333;
  font-weight: 700;
}

.login-signup-agree-terms-panel {
  max-width: 100%;
  height: 350px;
  margin-top: 0;
  overflow-y: scroll;
}

.btn-signup,
.btn-agree {
  height: 42px;
  border-radius: 8px;
  color: white;
  min-width: 170px;
  background-color: #0e0333;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  cursor: pointer;
  padding: 0 12px 0 12px;
}

.login-signup-agree-terms-panel h3 {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  color: #0e0333;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 4px;
}

.login-signup-agree-terms-panel p {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #0e0333;
}

.login-signup-agree-terms-panel div {
  padding: 10px;
}

.register-loading {
  font-size: 10px;
  position: absolute;
  top: 17px;
  right: -35px;
}

@media only screen and (max-width: 1600px) {
  //****************************   signup.tsx   *****************************
  .signup-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .signup-input-section {
    margin-bottom: 26px;
  }

  .signup-input-section label {
    font-size: 15px;
  }

  // ! password-requirements box
  .pwd-should-contain-section-signup {
    width: 307px;
    padding: 5px;
  }

  .signup-agree-terms-section {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  // ! Custom checkbox
  .signup-agree-terms-section input {
    width: 20px;
    height: 20px;
  }

  .custom-checkbox {
    position: absolute;
    width: 20px;
    height: 20px;
  }

  .signup-agree-terms-section label {
    font-size: 13px;
  }

  .signup-error-message {
    font-size: 15px;
    height: 40px;
  }

  //****************************   agree-terms.tsx   *****************************
  ul.login-signup-agree-terms-tabs li {
    padding: 8px 0;
    font-size: 15px;
  }

  .login-signup-agree-terms-panel {
    height: 275px;
  }

  .login-signup-agree-terms-panel p {
    font-size: 15px;
  }
}

// rules here apply to devices from 0px to 1024px (inclusive)
@media only screen and (max-width: 1024px) {
  //****************************   signup.tsx   *****************************

  .signup-input-section {
    margin-bottom: 15px;
  }

  #last-input-section {
    margin-bottom: 10px;
  }
  .signup-input-section label {
    font-size: 15px;
  }

  .signup-agree-terms-section {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .signup-agree-terms-section label {
    margin-left: 16px;
    font-size: 13px;
  }

  .pwd-should-contain-section-signup {
    top: 40%;
    width: 250px;
    height: 175px;
    padding: 15px;
  }

  .pwd-should-contain-title-signup {
    font-size: 15px;
    font-weight: 600;
  }

  .pwd-check-status-signup {
    width: 15px;
    height: 15px;
  }

  .pwd-should-contain-signup {
    gap: 10px;
    span {
      font-size: 12px;
    }
  }

  .signup-agree-terms-section input {
    width: 18.8px;
    height: 18.8px;
  }

  .custom-checkbox {
    width: 20px;
    height: 20px;
  }

  .signup-error-message {
    transform: translate(-50%, -120%);
    font-size: 15px;
  }

  //****************************   agree-terms.tsx   *****************************
  ul.login-signup-agree-terms-tabs {
    margin-top: -20px;
  }

  ul.login-signup-agree-terms-tabs li {
    padding: 6px 0;
    font-size: 15px;
  }

  ul.login-signup-agree-terms-tabs li.current {
    font-weight: 700;
  }

  .login-signup-agree-terms-panel {
    height: 250px;
  }

  .login-signup-agree-terms-panel h3 {
    font-size: 13px;
  }

  .login-signup-agree-terms-panel p {
    font-size: 15px;
  }

  .login-signup-agree-terms-panel div {
    padding: 10px;
  }

  .register-loading {
    font-size: 10px;
    position: absolute;
    top: 10px;
    right: -35px;
  }
}

// breakpoint - password requirements box in narrow windows
@media only screen and (max-width: 900px) {
  //! pwd requirements - portrait mode
  .pwd-should-contain-section-signup {
    position: absolute;
    top: 7%;
    left: 0;
    width: 395px;
    height: 187px;
    margin-left: 5%;
    padding: 10px;
    background: #eff0f6;
    border: 2px solid #7f8192;
    opacity: 0.95;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .pwd-should-contain-section-signup:after,
  .pwd-should-contain-section-signup:before {
    top: 110%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .pwd-should-contain-section-signup:after {
    border-color: rgba(127, 129, 146, 0);
    border-top-color: #eff0f6;
    border-width: 16px;
    margin-left: -16px;
    position: absolute;
    top: 107%;
  }
  .pwd-should-contain-section-signup:before {
    border-color: rgba(127, 129, 146, 0);
    border-top-color: #7f8192;
    border-width: 17px;
    margin-left: -17px;
  }

  .pwd-should-contain-title-signup {
    font-size: 18px;
  }

  .pwd-should-contain-signup {
    span {
      font-size: 14px;
    }
  }
}

// tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  /****************************   signup.tsx   *****************************/
  .signup-initial-pages-container {
    width: 446px;
    height: 557px;
    padding: 40px 36px 40px 36px;
  }

  .signup-form-container,
  .signup-agree-terms-container {
    border: 1px solid transparent;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .signup-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .signup-input-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 0;
  }

  #last-input-section-signup {
    position: relative;
    margin-bottom: 0;
  }

  .signup-input-section label {
    font-size: 15px;
  }

  // ! password-requirements box
  .pwd-should-contain-section-signup {
    position: absolute;
    top: 18%;
    left: 5%;
    width: 400px;
    height: 190px;
    margin-left: 0;
    padding: 10px;
    background: #eff0f6;
    border: 2px solid #7f8192;
    opacity: 0.95;
  }
  .pwd-should-contain-section-signup:after,
  .pwd-should-contain-section-signup:before {
    top: 110%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .pwd-should-contain-section-signup:after {
    border-color: rgba(127, 129, 146, 0);
    border-top-color: #eff0f6;
    border-width: 16px;
    margin-left: -16px;
    position: absolute;
    top: 107%;
  }
  .pwd-should-contain-section-signup:before {
    border-color: rgba(127, 129, 146, 0);
    border-top-color: #7f8192;
    border-width: 17px;
    margin-left: -17px;
  }

  .pwd-should-contain-title-signup {
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  .pwd-check-status-signup {
    width: 20px;
    height: 20px;
  }

  .pwd-should-contain-signup {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    span {
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  // ! password-requirements box

  .signup-agree-terms-section {
    margin-top: 0px;
    margin-bottom: 50px;
  }

  // ! Custom checkbox
  .custom-checkbox {
    width: 22px;
    height: 22px;
  }

  .signup-agree-terms-section label {
    margin-bottom: 0px;
    font-size: 14px;
  }

  .login-signup-container-btn {
    position: relative;
    margin: 0 auto 0 auto;
  }
  .btn-signup,
  .btn-agree {
    padding: auto;
    height: 42px;
    font-size: 14px;
  }

  .signup-error-message {
    font-size: 15px;
    padding: auto 16px;
  }

  /****************************   agree-terms.tsx   *****************************/
  ul.login-signup-agree-terms-tabs li {
    padding: auto 0;
    border-bottom: 2px solid transparent;
    font-size: 16px;
  }

  ul.login-signup-agree-terms-tabs li.current {
    border-bottom: 2px solid #0e0333;
  }

  .login-signup-agree-terms-panel {
    height: 360px;
    margin-top: 0;
  }

  .login-signup-agree-terms-panel h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .login-signup-agree-terms-panel p {
    font-size: 15px;
  }

  .login-signup-agree-terms-panel div {
    padding: 15px;
  }

  .register-loading {
    top: 10px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  /****************************   signup.tsx   *****************************/
  .signup-initial-pages-container {
    width: 446px;
    height: 557px;
    padding: 40px 36px 40px 36px;
  }

  .signup-input-section {
    margin-bottom: 28px;
  }

  .signup-input-section label {
    font-size: 15px;
  }

  // ! password-requirements box
  .pwd-should-contain-section-signup {
    top: 41%;
    width: 330px;
    height: 200px;
    padding: 10px;
    border-radius: 5px;
  }

  .pwd-should-contain-title-signup {
    font-size: 15px;
  }

  .pwd-should-contain-signup {
    span {
      font-size: 12px;
    }
  }
  // ! password-requirements box

  .signup-agree-terms-section {
    margin-top: 10px;
    margin-bottom: 60px;
  }

  // ! Custom checkbox
  .custom-checkbox {
    width: 22px;
    height: 22px;
  }

  .signup-agree-terms-section .custom-checkbox:after {
    width: 14px;
    height: 14px;
  }

  .signup-agree-terms-section label {
    margin-bottom: 0px;
    font-size: 14px;
  }

  .login-signup-container-btn {
    position: relative;
    margin: 0 auto 0 auto;
  }
  .btn-signup,
  .btn-agree {
    padding: auto 15px;
    height: 42px;
    font-size: 14px;
  }

  .signup-error-message {
    transform: translate(-50%, -120%);
    font-size: 15px;
    height: 50px;
    padding: auto 16px;
  }

  /****************************   agree-terms.tsx   *****************************/
  ul.login-signup-agree-terms-tabs li {
    padding: auto 0;
    border-bottom: 2px solid transparent;
    font-size: 16px;
  }

  ul.login-signup-agree-terms-tabs li.current {
    border-bottom: 2px solid #0e0333;
  }

  .login-signup-agree-terms-panel {
    height: 360px;
    margin-top: 0;
  }

  .login-signup-agree-terms-panel h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .login-signup-agree-terms-panel p {
    font-size: 15px;
  }

  .login-signup-agree-terms-panel div {
    padding: 15px;
  }

  .register-loading {
    top: 10px;
  }
}

// phone
@media only screen and (min-width: 320px) and (max-width: 480px) {
  /****************************   signup.tsx   *****************************/
  .signup-initial-pages-container {
    width: 346px;
    height: 500px;
    padding: 40px 20px 40px 20px;
  }

  .signup-input-section {
    margin-bottom: 0px;
  }

  #last-input-section-signup {
    position: relative;
    margin-bottom: 0;
  }

  .signup-input-section label {
    margin-bottom: 5px;
    font-size: 14px;
  }

  // ! password-requirements box
  .pwd-should-contain-section-signup {
    position: absolute;
    top: 4%;
    left: 2%;
    width: 330px;
    height: 200px;
    margin-left: 0;
    padding: 5px;
    background: #eff0f6;
    border: 2px solid #7f8192;
    opacity: 0.9;
  }
  .pwd-should-contain-section-signup:after,
  .pwd-should-contain-section-signup:before {
    top: 110%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .pwd-should-contain-section-signup:after {
    border-color: rgba(127, 129, 146, 0);
    border-top-color: #eff0f6;
    border-width: 16px;
    margin-left: -16px;
    position: absolute;
    top: 107%;
  }
  .pwd-should-contain-section-signup:before {
    border-color: rgba(127, 129, 146, 0);
    border-top-color: #7f8192;
    border-width: 17px;
    margin-left: -17px;
  }

  .pwd-should-contain-title-signup {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  .pwd-check-status-signup {
    width: 20px;
    height: 20px;
  }

  .pwd-should-contain-signup {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    span {
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  // ! password-requirements box

  .signup-agree-terms-section {
    margin-top: 0px;
    margin-bottom: 40px;
  }

  // ! Custom checkbox
  .signup-agree-terms-section input {
    width: 20px;
    height: 20px;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
  }

  .custom-checkbox {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(0, 0, 0, 1);
    cursor: pointer;
    border-radius: 50%;
  }

  .signup-agree-terms-section .custom-checkbox:after {
    left: 1px;
    top: 1px;
    width: 14px;
    height: 14px;
  }

  .signup-agree-terms-section label {
    margin-bottom: 0px;
    font-size: 12px;
  }

  .login-signup-container-btn {
    position: relative;
    margin: 0 auto 0 auto;
  }
  .btn-signup,
  .btn-agree {
    padding: 5px auto;
    height: 42px;
    font-size: 12px;
  }

  .btn_active a {
    text-decoration: none;
  }

  .signup-error-message {
    font-size: 14px;
    height: 40px;
    width: 90%;
    padding: auto 16px;
  }

  /****************************   agree-terms.tsx   *****************************/
  ul.login-signup-agree-terms-tabs {
    margin-top: -20px;
  }

  ul.login-signup-agree-terms-tabs li {
    padding: 5px 0;
    border-bottom: 2px solid transparent;
    font-size: 15px;
  }

  ul.login-signup-agree-terms-tabs li.current {
    border-bottom: 2px solid #0e0333;
  }

  .login-signup-agree-terms-panel {
    height: 330px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .login-signup-agree-terms-panel h3 {
    font-size: 16px;
  }

  .login-signup-agree-terms-panel p {
    font-size: 14px;
  }

  .login-signup-agree-terms-panel div {
    padding: 15px;
  }

  .register-loading {
    top: 10px;
  }
}
