//tooltip
.tooltip-nav {
  width: 100%;
  height: 52px;
  background-image: url('../../../images/general-icons/tooltip-dark.svg');
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom: 10%;
}

.tooltip-nav:hover {
  -webkit-box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.25);
  box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.25);
  transform: scale(1.2);
  transition: all 0.1s ease-in;
}
.tooltip-nav-active {
  width: 100%;
  height: 52px;
  background-image: url('../../../images/general-icons/tooltip-dark.svg');
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom: 10%;
}

.main-menu {
  position: fixed;
  left: 0;
  z-index: 1;
  height: 100vh;
  width: 80px;
  background: #ffffff;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  transition: all 0.3s linear;
}

.sidebar-items-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100px;
  .vieweet-logo {
    width: 55px;
    height: 32px;
    box-sizing: border-box;
    object-fit: cover;
    margin-left: 7px;
  }
}

.sidebar-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noHover {
  pointer-events: none;
}

.sidebar-items-logo:hover {
  background-color: none;
}

.icon-general {
  height: 60px;
  width: 80px;
  background-repeat: no-repeat;
}

.sidebar-items-inactive-dashboard {
  background-image: url('../../../images/menu-icons/ic-dashboard-inactive.svg');
}

.sidebar-items-active-dashboard {
  background-image: url('../../../images/menu-icons/ic-dashboard-pressed.svg');
}

.sidebar-items-inactive-dashboard:hover {
  background-image: url('../../../images/menu-icons/ic-dashboard-active.svg');
}

.sidebar-items-inactive-analytics {
  background-image: url('../../../images/menu-icons/ic-analytics-inactive.svg');
}

.sidebar-items-active-analytics {
  background-image: url('../../../images/menu-icons/ic-analytics-pressed.svg');
}

.sidebar-items-inactive-analytics:hover {
  background-image: url('../../../images/menu-icons/ic-analytics-active.svg');
}

.sidebar-items-inactive-settings {
  background-image: url('../../../images/menu-icons/ic-settings-inactive.svg');
}

.sidebar-items-active-settings {
  background-image: url('../../../images/menu-icons/ic-settings-pressed.svg');
}

.sidebar-items-inactive-settings:hover {
  background-image: url('../../../images/menu-icons/ic-settings-active.svg');
}

.sidebar-items-inactive-profile {
  background-image: url('../../../images/menu-icons/ic-profile-inactive.svg');
}

.sidebar-items-active-profile {
  background-image: url('../../../images/menu-icons/ic-profile-pressed.svg');
}

.sidebar-items-inactive-profile:hover {
  background-image: url('../../../images/menu-icons/ic-profile-active.svg');
}

.sidebar-items-inactive-admin {
  background-image: url('../../../images/menu-icons/ic-admin-inactive.svg');
}

.sidebar-items-active-admin {
  background-image: url('../../../images/menu-icons/ic-admin-pressed.svg');
}

.sidebar-items-inactive-admin:hover {
  background-image: url('../../../images/menu-icons/ic-admin-active.svg');
}

.sidebar-items-inactive-logout {
  background-image: url('../../../images/menu-icons/ic-logout.svg');
  position: absolute;
  bottom: 0%;
  background-repeat: no-repeat;
}

.sidebar-items-inactive-logout:hover {
  background-image: url('../../../images/menu-icons/logout-hover.svg');
  background-repeat: no-repeat;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .main-menu {
    width: 80px;
    height: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .main-menu {
    width: 60px;
    height: 100%;
  }

  .sidebar-items-logo {
    width: 60px;
  }

  .icon-general {
    height: 40px;
    width: 60px;
    margin: 0.5rem 0;
    background-repeat: no-repeat;
  }

  .sidebar-items-inactive-dashboard {
    background-image: url('../../../images/menu-icons/ic-dashboard-inactive.svg');
    background-size: 60px 40px;
  }

  .sidebar-items-active-dashboard {
    background-image: url('../../../images/menu-icons/ic-dashboard-pressed.svg');
    background-size: 60px 40px;
  }

  .sidebar-items-inactive-dashboard:hover {
    background-image: url('../../../images/menu-icons/ic-dashboard-active.svg');
    background-size: 60px 40px;
  }

  .sidebar-items-inactive-analytics {
    background-image: url('../../../images/menu-icons/ic-analytics-inactive.svg');
    background-size: 60px 40px;
  }

  .sidebar-items-active-analytics {
    background-image: url('../../../images/menu-icons/ic-analytics-pressed.svg');
    background-size: 60px 40px;
  }

  .sidebar-items-inactive-analytics:hover {
    background-image: url('../../../images/menu-icons/ic-analytics-active.svg');
    background-size: 60px 40px;
  }

  .sidebar-items-inactive-settings {
    background-image: url('../../../images/menu-icons/ic-settings-inactive.svg');
    background-size: 60px 40px;
  }

  .sidebar-items-active-settings {
    background-image: url('../../../images/menu-icons/ic-settings-pressed.svg');
    background-size: 60px 40px;
  }

  .sidebar-items-inactive-settings:hover {
    background-image: url('../../../images/menu-icons/ic-settings-active.svg');
    background-size: 60px 40px;
  }

  .sidebar-items-inactive-profile {
    background-image: url('../../../images/menu-icons/ic-profile-inactive.svg');
    background-size: 60px 40px;
  }

  .sidebar-items-active-profile {
    background-image: url('../../../images/menu-icons/ic-profile-pressed.svg');
    background-size: 60px 40px;
  }

  .sidebar-items-inactive-profile:hover {
    background-image: url('../../../images/menu-icons/ic-profile-active.svg');
    background-size: 60px 40px;
  }

  .sidebar-items-inactive-logout {
    background-image: url('../../../images/menu-icons/ic-logout.svg');
    position: absolute;
    left: 60px;
    transform: translateX(-67%);
    bottom: 0;
  }

  .sidebar-items-inactive-logout:hover {
    background-image: url('../../../images/menu-icons/logout-hover.svg');
  }
}
