.new-active-button {
  font-family: 'Inter', sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  height: 42px;
  min-width: 170px;
  outline: none;
  border: none;
  border-radius: 8px;
  background: #0e0333;
  cursor: pointer;
  padding: 0 12px 0 12px;
}

.points-list-container {
  margin: 10px;
  font-size: 12px;
}

.detected-item-container {
  padding: 3px 1px 1px 3px;
  height: 80%;
  background: #fff;
  margin: 15px 0;
  border-radius: 8px;
}

.delete-btn {
  background-image: url('../../images/viewer-icons/bin.svg');
  background-color: transparent;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  border: 0;
  cursor: pointer;
  outline: 0;
  margin: 0 8px;
}

.delete-btn-disabled {
  background-image: url('../../images/viewer-icons/bin.svg');
  background-color: transparent;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  border: 0;
  cursor: pointer;
  outline: 0;
  margin: 0 8px;
}

.delete-btn:hover {
  background-image: url('../../images/viewer-icons/bin-hover.svg');
}

.annotation-container {
  display: flex;
  align-self: center;
  margin: 10px;
  justify-content: space-between;
  align-items: center;
}

.annotation-name {
  font-weight: 400;
  font-size: 16px;
  width: 110px;
  line-height: 25px;
  cursor: default;
}

.annotation-colour {
  margin-top: 5px;
  margin-right: 10px;
}

.annotation-empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-annotation {
  position: absolute;
  right: 10px;
}

.btn-custom-select-annotation {
  outline: none;
  cursor: pointer;
  position: relative;
  text-align: left;
  outline: none;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #0e0333;
  width: 124px;
  box-shadow: inset 1px 2px 2px rgba(32, 34, 54, 0.25);
  height: 32px;
  border-radius: 8px;
  background: none;
}

.annotation-dropdown select {
  cursor: pointer;
}

.annotation-colour-stairs path {
  fill: #ff006a;
}

.annotation-colour-default path {
  fill: #dbdbdb;
}

.annotation-colour-door path {
  fill: #00ffff;
}

.annotation-colour-window path {
  fill: #9900ff;
}

.annotation-colour-wall_corner path {
  fill: #ff6600;
}

/*****************  Blur Annotation  *****************/

.blur-explain {
  margin: 30px 0 5px 0;
  color: #0e0333;
  font-weight: 400;
  font-size: 16px;
}

.blur-explain-first {
  text-align: center;
  margin: 15px;
  color: #757575;
  font-weight: 400;
  font-size: 16px;
}

.annotation-blur-empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blur-explain-points {
  margin: 0 40px 10px 40px;
  color: #757575;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.autoblur-btn {
  width: 288px;
  height: 32px;
  border-radius: 8px;
  border: none;
  outline: none;
  color: #ffffff;
  background: #00269a;
  cursor: pointer;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.start-blur-icon {
  margin-right: 10px;
  margin-top: 3px;
}

.apply-blur-icon {
  margin-right: 10px;
  margin-top: 3px;
}

.apply-blur-icon-inactive path {
  stroke: #818aa6;
}

.face-blur-icon {
  position: relative;
  top: 2px;
}

.new-blur-active-button {
  font-family: 'Inter', sans-serif;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  height: 42px;
  padding: 0 5px;
  min-width: 134px;
  outline: none;
  border: none;
  border-radius: 8px;
  background: #0e0333;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-blur-inactive-button {
  font-family: 'Inter', sans-serif;
  color: #818aa6;
  font-size: 14px;
  font-weight: 300;
  height: 32px;
  padding: 0 5px;
  min-width: 134px;
  outline: none;
  border: none;
  border-radius: 8px;
  background: #c7ccda;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.apply-layer-button {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 300;
  height: 42px;
  padding: 0 5px;
  min-width: 134px;
  outline: none;
  border: none;
  border: 1px solid rgba(44, 46, 74, 0.64);
  color: rgba(44, 46, 74, 0.64);
  border-radius: 8px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.apply-layer-button-inactive {
  font-family: 'Inter', sans-serif;
  color: #818aa6;
  font-size: 14px;
  font-weight: 300;
  height: 42px;
  padding: 0 5px;
  min-width: 134px;
  outline: none;
  border: none;
  border-radius: 8px;
  border: 1px solid #818aa6;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.apply-layer-disable {
  background: #fff;
  color: #818aa6;
}

.apply-layer-active {
  font-weight: 400;
  border: 1px solid #0e0333 !important;
  color: #0e0333 !important;
  cursor: pointer;
}

.apply-layer-inactive path {
  stroke: #818aa6;
}

.apply-layer-active path {
  stroke: #0e0333;
}

.apply-layer-active-apply-active {
  font-weight: 400;
  color: white;
  border: 1px solid #0e0333;
  background: #0e0333 !important;
}

.apply-layer-active-apply-active path {
  fill: #ffffff;
}

.finding-faces-text {
  color: #757575;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}

.blur-buttons-box {
  position: absolute;
  bottom: 25px;
  // left: 2px;
  width: 94%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;
}

.blur-toggle {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  gap: 20px;
}

.blur-toggle-image {
  display: flex;
  justify-content: center;
  margin-left: auto;
  right: 0;
  left: 0;
  margin-right: auto;
  text-align: center;
  gap: 20px;
}

.blur-btn-inactive {
  background: #c7ccda;
  color: #818aa6;
  border: none;
  pointer-events: none;
}
