.settings-header {
  width: 100%;
  min-width: 250px;
  display: flex;
}

.settings-header span {
  position: relative;
  left: -1rem;
}

.settings-header-tabs-container {
  display: flex;
  align-items: center;
  gap: 40px;
  height: 50px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.settings-header-tabs-container li {
  display: flex;
  align-items: center;
  color: #0e0333;
  justify-content: center;
  height: 50px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.settings-header-tabs-container li.current {
  border-bottom: 2px solid #0e0333;
  color: #0e0333;
  font-weight: 700;
}

.content-wrapper-settings {
  height: 100%;
  grid-area: content;
  display: flex;
  flex-direction: column;
}

// --------- coming soon -------
.container-text-soon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
}

.title-soon {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: #0e0333;
}

.sub-text-soon {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #0e0333;
}

.img-coming-soon {
  width: 60%;
}

// --------- content ----------
.settings-information-container {
  width: 100%;
  height: 95%;
  margin: 30px 0 0 0;
  padding: 40px 80px;
  background-color: #ffffff;
  box-shadow: (1px 1px 16px rgba(0, 0, 0, 0.16));
  border-radius: 4px;
}

.settings-information-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.panelhook-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 28px;
}

.your-branding-options {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bottom-set-settings {
  height: 100%;
  display: flex;
  padding: 0;
}

.field-text {
  color: #0e0333;
  margin-bottom: 15px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.field-text-disable {
  color: #818aa6;
  margin-bottom: 15px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.field-text-label {
  color: #0038ff;
  margin-bottom: 15px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.leads-generation-option:focus-within .field-text {
  color: #0038ff;
}

.leads-generation-option:focus-within .create-tour-input-labels {
  color: #0038ff;
}

.settings-config-option:focus-within .field-text {
  color: #0038ff;
}

.optimise-tripod-button {
  width: 206px;
  height: 32px;
  border: 1px solid #2C2E4A;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 24px;
  cursor: pointer;
  background-color: #FFFFFF;
}

.settings-config-option-tripod {
  display: flex;
  justify-content: space-between;
  width: 90%;
  gap: 18px;  
}

.field-subtext {
  color: #0e0333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.field-subtext-audio {
  color: #0e0333;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.field-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin-right: 128px;
}

.input-mod {
  margin-right: 0rem;
}

.input-default {
  border-bottom: 1px solid #0e0333;
}

// * -----------Slider Input-----------
.switch-settings {
  position: relative;
  width: 60px;
  height: 31px;
}

.switch-settings input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-settings {
  padding: 4px 4px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a3abc0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 2px solid #a3abc0;
}

.slider-settings:before {
  position: absolute;
  content: '';
  height: 25px;
  width: 25px;
  left: 1px;
  top: 1px;
  bottom: 0.5px;
  background-color: #fff;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

input:checked + .slider-settings {
  background-color: #0e0333;
  border: 2px solid #0e0333;
}

input:checked + .slider-settings:before {
  -webkit-transform: translateX(25px);
  position: absolute;
  content: '';
  -ms-transform: translateX(25px);
  transform: translateX(29px);
  background-color: #fff;
}

/* Rounded sliders */
.slider-settings.round-settings {
  border-radius: 34px;
}

.slider-settings.round-settings:before {
  border-radius: 50%;
}

.slider-settings-disable {
  padding: 4px 4px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e9ebef;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 2px solid #e9ebef;
}

.slider-settings-disable:before {
  position: absolute;
  content: '';
  height: 25px;
  width: 25px;
  left: 1px;
  top: 1px;
  bottom: 0.5px;
  background-color: #f5f5f5;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

input:checked + .slider-settings-disable {
  background-color: #e9ebef;
  border: 2px solid #e9ebef;
}

input:checked + .slider-settings-disable:before {
  -webkit-transform: translateX(25px);
  position: absolute;
  content: '';
  -ms-transform: translateX(25px);
  transform: translateX(29px);
  background-color: #f5f5f5;
}

/* Rounded sliders */
.slider-settings-disable.round-settings {
  border-radius: 34px;
}

.slider-settings-disable.round-settings:before {
  border-radius: 50%;
}

// * -----------Slider Input-----------

.slider-text-on {
  position: absolute;
  top: 8px;
  left: 6px;
  font-size: 9px;
  color: #fff;
  font-family: Inter;
  font-weight: 500;
}

.slider-text-off {
  position: absolute;
  top: 8px;
  right: 6px;
  font-size: 9px;
  color: #fff;
  font-family: Inter;
  font-weight: 500;
}
.settings-drag-drop-field-container {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  overflow: hidden;
}

.drag-drop-text-container {
  display: flex;
  flex-direction: column;
  width: auto;
  margin-bottom: 36px;
}

.drag-drop-input-container-branding {
  height: 34vh;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: unset;
  overflow: scroll;
  gap: 48px;
}

.drag-drop-container-circle {
  width: 150px;
  height: 150px;
  background-color: #f2f6ff;
  border: 1px solid #d5d7df;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.drag-drop-text-container-circle {
  position: absolute;
  width: fit-content;
  height: fit-content;
  pointer-events: none;
  text-align: center;
}

.drag-drop-text-title {
  color: #0e0333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
}

.drag-drop-text-title-btn {
  color: #0e0333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  background: #fff;
  border: 1px solid #0e0333;
  border-radius: 8px;
  padding: 5px 0px;
}

.drag-drop-text-title-btn-disabled {
  color: #c7ccda;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  background: #fff;
  border: 1px solid #c7ccda;
  border-radius: 8px;
  padding: 5px 0px;
}

.underlined {
  text-decoration: underline;
  text-underline-offset: 0.25rem;
}


//------------ user's logos ----------
.drag-drop-display {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: scroll;
  gap: 40px;
}

.logo-image-container {
  width: 140px;
  height: 178px;
  margin-right: 0;
  margin-bottom: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-image-container:hover .dd-cross-container-logo {
  visibility: visible;
}

.dd-cross-container-logo {
  width: 26px;
  height: 26px;
  background: url('../../../images/general-icons/cross.svg');
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
  cursor: pointer;
}

.dd-image-logo {
  width: 140px !important;
  height: 140px !important;
  box-sizing: border-box;
  object-fit: contain;
}

.dd-text-logo {
  margin-top: 8px;
  font-size: 14px;
  cursor: pointer;
}

.default-logo {
  text-decoration: underline;
  text-underline-offset: 0.25rem;
}

.not-default-logo {
  color: rgba(44, 46, 74, 0.56);
}

//------------ button ------------
.save-information-button-container {
  width: 100%;
  display: flex;
  gap: 36px;
  align-items: center;
  justify-content: center;
}

.save-information-button {
  height: 42px;
  border-radius: 8px;
  min-width: 170px;
  border: none;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0 12px 0 12px;
}

.button-active {
  border: 1px #0e0333 solid;
  background-color: #0e0333;
  color: #fff;
}

.button-inactive {
  transition: all 0.1s linear;
  border: 1px solid rgba(44, 46, 74, 0.64);
  background-color: #ffffff;
  color: rgba(44, 46, 74, 0.64);
  cursor: default;
}

.button-white {
  transition: all 0.1s linear;
  border: 1px solid rgba(44, 46, 74, 0.64);
  background-color: #ffffff;
  color: #0e0333;
}
//---------------------

.delete-account-btn {
  padding: 15px 32px;
  width: 224px;
  height: 52px;
  background: #ea4335;
  border-radius: 4px;
  color: #ffffff;
  border: 0;

  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.delete-modal-middle-text {
  color: #0e0333;
  text-align: center;
  display: flex;
  margin: 0px 30px;
  flex-direction: column;
  width: 70%;
}

#modal-btn-container-id {
  margin-top: 25px;
}

.settings-drag-drop-field-container .drag-drop-input-container .drag-drop-display .dd-image-container .dd-cross-container {
  cursor: pointer;
}

.column {
  flex-direction: column;
}

.settings-config-block {
  display: flex;
  width: 100%;
  gap: 50px;
  justify-content: space-evenly;
  margin-bottom: 60px;
}

.settings-config-block-tour {
  display: flex;
  width: 100%;
  gap: 50px;
  justify-content: space-evenly;
  margin-bottom: 25px;
}

.settings-config-block-ai-features {
  display: flex;
  width: 100%;
  gap: 50px;
  justify-content: space-evenly;
  margin-bottom: 30px;
}

.settings-config-block-tripod {
  display: flex;
  width: 100%;
  gap: 50px;
  margin-bottom: 48px;
}

.leads-config-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
  gap: 25px;
}

.settings-config-option {
  display: flex;
  justify-content: space-between;
  width: 40%;
  gap: 18px;
}

.media-drop-or-settings {
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  margin: 8px auto;
}

.media-drop-or-settings:before,
.media-drop-or-settings:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 50%;
  height: 1px;
  content: '\a0';
  background-color: #e6ebf4;
}

.media-drop-or-settings:before {
  margin-left: -50%;
  text-align: right;
}

.leads-generation-option {
  width: 100%;
}

.custom-select-leads-generation {
  width: 80% !important;
}

.custom-select-leads-generation-viewer-settings {
  width: 100% !important;
  max-width: none;
}

.custom-arrow-leads-generation {
  position: absolute;
  right: 23%;
  top: 14px;
}

.custom-arrow-leads-generation-viewer-settings {
  position: absolute;
  right: 13px;
  top: 14px;
}

.custom-dropdown-leads-generation {
  position: absolute;
  width: 80%;
  overflow: auto;
  overflow: hidden;
  overflow-x: hidden;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}

.custom-dropdown-leads-generation-viewer-settings {
  width: 100%;
  position: absolute;
  overflow: auto;
  overflow: hidden;
  overflow-x: hidden;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}

.subscription-list-table {
  margin: -25px 0;
  width: 100%;
}

.subscription-list-row {
  display: flex;
  justify-content: space-between;
  height: 35px;
}

.subscription-list-row-header {
  display: flex;
  justify-content: space-between;
}

.subscription-column-one {
  padding: 6px;
  flex: 3;
  border-top: 1px solid #cacace;
  border-bottom: 1px solid #cacace;
}

.subscription-column-two {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border: 1px solid #cacace;
}

.subscription-column-one-no-column {
  padding: 6px;
  flex: 3;
}

.subscription-column-three {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-top: 1px solid #cacace;
  border-bottom: 1px solid #cacace;
  background: rgba(242, 246, 255, 0.41);
}

.subscription-cell-price {
  text-align: center;
  display: flex;
  flex-direction: column;
  color: #0e0333;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 10px 0;
}

.container-inputs-branding {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 72px;
  margin-bottom: 46px;
}

.general-field-input-text-branding {
  height: 52px;
  min-width: 220px;
  // width: 100%;
  padding: 18px 15px;
  background: #f2f6ff;
  border-bottom: 1px solid #0e0333;
  border-top: none;
  border-left: none;
  border-right: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  cursor: text;
  text-align: left;
}

.general-field-input-text-disabled {
  background: #f2f3f5 !important;
}

.general-field-input-text-branding:focus {
  border-bottom: 2px solid #0038ff;
}

.branding-input-labels {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #0e0333;
}

.input-box {
  display: flex;
  flex-direction: column;
  width: 330px;
  gap: 16px;
  cursor: default;
}

.input-box:focus-within .branding-input-labels {
  color: #0038ff;
}

.your-plan-popup {
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  background: #d50057;
  pointer-events: none;
  width: 88px;
  height: 22px;
  border-radius: 4px;
  padding: 2px 12px 3px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.your-plan-popup-empty {
  background: #fff;
}

@media only screen and (max-width: 1286px) {
}

// tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  // * -----------Slider Input-----------
  .switch-settings {
    position: relative;
    width: 40px;
    height: 21px;
  }

  .slider-settings:before {
    height: 15px;
    width: 15px;
  }

  .slider-settings-disable {
    height: 15px;
    width: 15px;
  }

  input:checked + .slider-settings:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(19px);
    background-color: #0e0333;
  }

  input:checked + .slider-settings-disable:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(19px);
    background-color: #0e0333;
  }
  // * -----------Slider Input-----------

  .drag-drop-text-container {
    margin-bottom: 22px;
  }

  .drag-drop-input-container-branding {
    height: 52vh;
    gap: 28px;
  }

  .drag-drop-container-circle {
    width: 110px;
    height: 110px;
  }

  //------------ user's logos ----------
  .drag-drop-display {
    gap: 40px;
  }

  .logo-image-container {
    width: 150px;
    height: 170px;
    margin-right: 0;
    margin-bottom: 0;
  }

  .dd-image-logo {
    width: 100px;
    height: 100px;
  }

  .dd-text-logo {
    font-size: 12px;
  }
  //------------ user's logos ----------

  //------------- delete modal -----------------------
  .ipad-portrait-container {
    height: 450px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  // --------- content ----------
  .settings-information-container {
    width: 100%;
    height: 85%;
    margin: 30px 0 0 0;
    padding: 38px 38px;
  }

  .panelhook-container {
    gap: 28px;
  }

  .bottom-set-settings {
    height: 100%;
    display: flex;
    padding: 0;
  }

  .drag-drop-text-container {
    margin-bottom: 22px;
  }

  .drag-drop-input-container-branding {
    height: 40vh;
    gap: 38px;
  }

  .drag-drop-container-circle {
    width: 130px;
    height: 130px;
  }

  //------------ user's logos ----------
  .drag-drop-display {
    gap: 20px;
  }

  .logo-image-container {
    width: 130px;
    height: 165px;
    margin-right: 0;
    margin-bottom: 0;
  }

  .dd-image-logo {
    width: 130px;
    height: 130px;
  }

  .dd-text-logo {
    font-size: 12px;
  }

  .delete-account-btn {
    padding: 5px 15px;
    width: 214px;
    height: 42px;

    font-size: 15px;
  }

  .delete-modal-middle-text {
    width: 75%;
  }

  #modal-btn-container-id {
    margin-top: 0px;
  }
}
