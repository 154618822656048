.menu-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-container-edit {
  position: relative;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
}

.menu-trigger {
  background: #ffffff;
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  margin-left: auto; /* Strictly for positioning */
}

.menu-trigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.menu-trigger span {
  font-weight: 700;
  vertical-align: middle;
  font-size: 14px;
  margin: 0 10px;
}

.menu-trigger img {
  border-radius: 90px;
}

.menu {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  bottom: 110px;
  width: 300px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
}

.menu.active {
  opacity: 1;
  visibility: visible;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-bottom: 1px solid #dddddd;
}

.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}

.btn-dots {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top: 3px;
  left: 151px;
  background-color: white;
  width: 16px;
  height: 22px;
  border: 1px solid #0e0333;
  box-sizing: border-box;
  border-radius: 8px;
  background-image: url('../../../../images/dashboard-icons/inactive-menu-editor.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.btn-dots:hover {
  background-image: url('../../../../images/dashboard-icons/active-menu-editor.svg');
}

////////////////////////@at-root
.context-menu-container {
  width: 200px;
  height: 100px;
  display: flex;
  border-radius: 4px;
  margin: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10%;
}

.custom-context-menu {
  position: fixed;
  width: 175px;
  z-index: 10;
  bottom: 125px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 8px;
  padding: 8px 0;
}

.custom-context-menu-edit {
  position: absolute;
  width: 195px;
  z-index: 10;
  padding: 8px 0;
  top: 115px;
  left: -117px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 8px;
}

.right-options:hover {
  background: #0e0333;
  border-radius: 5%;
  color: white;
}

.right-options {
  width: 100%;
  height: 37px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.right-options-disabled {
  width: 100%;
  height: 37px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.right-options-icons {
  padding: 2px;
  width: 25px;
  height: 25px;
  margin-left: 8px;
}

.right-options-icons-edit {
  padding: 2px;
  width: 25px;
  height: 25px;
  margin-left: 12px;
}

.right-options-text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  margin-left: 8px;
  font-size: 1rem;
}

.rights-options:hover {
  background-color: #0e0333;
  color: #ffffff;
}

.right-options:hover {
  .view {
    background-image: url('../../../../images/menu-editor-icons/best-view-active.svg');
    background-repeat: no-repeat;
  }
  .delete {
    background-image: url('../../../../images/menu-editor-icons/delete-image-active.svg');
    background-repeat: no-repeat;
  }
  .start {
    background-image: url('../../../../images/menu-editor-icons/starting-image-active.svg');
    background-repeat: no-repeat;
  }
  .go-to-studio {
    background-image: url('../../../../images/drop-down-icons/go-to-studio-drop-down-white.svg');
    background-repeat: no-repeat;
  }

  .view-image {
    background-image: url('../../../../images/drop-down-icons/view-image-white.svg');
    background-repeat: no-repeat;
  }

  .download360 {
    background-image: url('../../../../images/drop-down-icons/download-360-white.svg');
    background-repeat: no-repeat;
  }

  .download2d {
    background-image: url('../../../../images/drop-down-icons/download-2d-white.svg');
    background-repeat: no-repeat;
  }
}

.delete {
  background-image: url('../../../../images/menu-editor-icons/delete-image.svg');
  background-repeat: no-repeat;
}

.start {
  background-image: url('../../../../images/menu-editor-icons/starting-image.svg');
  background-repeat: no-repeat;
}

.view {
  background-image: url('../../../../images/menu-editor-icons/best-view.svg');
  background-repeat: no-repeat;
}

.go-to-studio {
  background-image: url('../../../../images/drop-down-icons/go-to-studio-drop-down.svg');
  background-repeat: no-repeat;
}

.view-image {
  background-image: url('../../../../images/drop-down-icons/view-image.svg');
  background-repeat: no-repeat;
}

.download360 {
  background-image: url('../../../../images/drop-down-icons/download-360.svg');
  background-repeat: no-repeat;
}

.download2d {
  background-image: url('../../../../images/drop-down-icons/download-2d.svg');
  background-repeat: no-repeat;
}
