// ---------------- header --------------------
.analytics-header {
  width: 100%;
  min-width: 250px;
  display: flex;
}

.analytics-header span {
  position: relative;
  left: -1rem;
}

.analytics-header-tabs-container {
  display: flex;
  align-items: center;
  gap: 40px;
  height: 50px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.analytics-header-tabs-container li {
  display: flex;
  align-items: center;
  color: #0e0333;
  justify-content: center;
  height: 50px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.analytics-header-tabs-container li.current {
  border-bottom: 2px solid #0e0333;
  color: #0e0333;
  font-weight: 700;
}
// ---------------- header --------------------

.content-wrapper-analytics {
  height: 100%;
  grid-area: content;
  display: flex;
  flex-direction: column;
}

.empty-analytics {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 33px 35px;
}

.no-analytics-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 30%;
}

.title-text-no-analytics {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #0e0333;
  width: 350px;
}

.sub-text-no-analytics {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #757575;
  width: 250px;
}

.button-no-analytics {
  width: 170px;
  height: 42px;
  border-radius: 8px;
  color: #818aa6;
  background-color: #fff;
  margin-top: 86px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  border: 1px #0e0333 solid;
  padding: 0 12px 0 12px;
}

.button-download-stats-invalide {
  height: 42px;
  border-radius: 8px;
  color: #818aa6;
  background-color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  border: 1px #0e0333 solid;
  position: absolute;
  top: 34px;
  right: 34px;
  padding: 0 12px 0 12px;
}

.button-download-stats {
  height: 42px;
  border-radius: 8px;
  color: white;
  min-width: 170px;
  background-color: #0e0333;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 34px;
  right: 34px;
  padding: 0 12px 0 12px;
}

.empty-lead-img {
  width: 65%;
  position: relative;
  bottom: 100px;
  left: 100px;
}

.container-empty-lead {
  display: flex;
  justify-content: flex-start;
}

.title-text-no-leads {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #0e0333;
  width: 422px;
}

.sub-text-no-leads {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #757575;
  width: 286px;
}

.button-no-leads {
  width: 170px;
  height: 42px;
  border-radius: 8px;
  color: #818aa6;
  background-color: #fff;
  margin-top: 86px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  border: 1px #0e0333 solid;
  padding: 0 12px 0 12px;
}

.container-text-empty-lead {
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.analytics-tours-analytics-container,
.analytics-leads-generated-container {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 70vh;
  margin: 30px 0 0 0;
  padding: 48px 48px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: (1px 1px 16px rgba(0, 0, 0, 0.16));
  border-radius: 16px;
}

.controls {
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sort-by-date-container {
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  cursor: pointer;
}

.button-container {
  position: absolute;
  right: 34px;
  top: 34px;
}

.leads-analytics-container {
  position: relative;
  height: calc(100% - 88px);
}

.columns {
  width: 80%;
  min-width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.countries-views-container {
  width: 85%;
  display: flex;
  flex-direction: column;
  gap: 29px;
  margin-top: 46px;
}

.countries-views {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 50px;
  margin-right: 50px;
}

.info-tag {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.8px;
  cursor: default;
}

.info-tag-title {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.8px;
  cursor: default;
}

.item-views-info {
  display: flex;
  width: 81%;
  justify-content: space-between;
  gap: 20px;
}

.item-views-info-countries {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.relative-position {
  position: relative;
  left: 100px;
}

.text-specs-source {
  color: #0e0333;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.tour-item-text {
  text-decoration: underline;
  cursor: pointer;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.item-views {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.item-text {
  cursor: default;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.text-specs {
  font-size: 14px;
  width: 100%;
  overflow: hidden;
  font-weight: 400;
  color: #0e0333;
}

.btn-download-analytics {
  height: 42px;
  color: #ffffff;
  background-color: #0e0333;
  border-radius: 8px;
  min-width: 170px;
  outline: none;
  border: none;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0 12px 0 12px;
}

.disable-btn-download-analytics {
  height: 42px;
  color: #818aa6;
  min-width: 170px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(44, 46, 74, 0.64);
  outline: none;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0 12px 0 12px;
}

.no-analytics-large-img {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-leads-large-img {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 53%;
  transform: translate(-50%, -50%);
}

.free-account-leads {
  gap: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-custom-select {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
}

.analytics-dt-label-container {
  width: 100%;
  height: 40px;
  display: grid;
  grid-template-columns: 1fr 1.6fr 1fr 1fr 1fr 1fr;
  gap: 5px;
  border-bottom: 2px solid #0e0333;
  color: #0e0333;
}

.analytics-dt-label {
  min-width: 100px;
  color: #0e0333;
  align-self: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.analytics-dt-data-container {
  width: 100%;
  height: auto;
  padding: 15px 0;
  color: #0e0333;
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1.6fr 1fr 1fr 1fr 1fr;
}

.analytics-dt-data {
  min-width: 100px;
  align-self: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0e0333;
}

.bar-chart-analytics {
  background: #00b1e0;
  border-radius: 2px;
  height: 24px;
}

.views-number {
  display: flex;
  gap: 6px;
  font-size: 14px;
  font-weight: 400;
}

.views-eyes {
  height: 15px;
}

.dot {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 24px;
}

/* Animation */
@keyframes first {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(100deg);
  }
}

@keyframes second {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(150deg);
  }
}

@keyframes third {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(111deg);
  }
}

// rules outside of @media queries apply to devices 1600px and above
// rules here apply to devices from 1024px to 1600px (inclusive)
@media only screen and (max-width: 1600px) {
  .content-wrapper-analytics {
    display: flex;
    flex-direction: column;
  }
}

// rules here apply to devices from 0px to 1024px (inclusive)
@media only screen and (max-width: 1024px) {
  .analytics-header-tabs-container {
    gap: 20px;
  }

  .content-wrapper-analytics {
    height: 100%;
    grid-area: content;
    display: flex;
    flex-direction: column;
  }

  .analytics-tours-analytics-container,
  .analytics-leads-generated-container {
    min-width: fit-content;
    margin: 20px 0 0 0;
    padding: 28px 28px;
    gap: 22px;
  }

  .info-tag {
    font-size: 18px;
  }

  .btn-download-analytics {
    width: 163px;
    font-size: 14px;
  }

  .disable-btn-download-analytics {
    width: 163px;
    font-size: 14px;
  }

  .button-download-stats-invalide {
    width: 163px;
    font-size: 14px;
  }

  .analytics-dt-label {
    font-size: 16px;
  }

  .analytics-dt-data-container {
    padding: 10px 0;
  }

  .analytics-dt-data {
    font-size: 13px;
  }
}

// tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1023.9px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .analytics-header-tabs-container {
    width: 350px;
  }

  .analytics-header-tabs-container li {
    width: fit-content;
    padding: 0 10px;
    font-size: 13px;
  }

  .analytics-header-tabs-container li.current {
    font-weight: 400;
  }

  .content-wrapper-analytics {
    height: 100%;
    grid-area: content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .analytics-tours-analytics-container,
  .analytics-leads-generated-container {
    padding: 38px 38px;
  }

  .countries-views-container {
    gap: 24px;
  }

  .info-tag {
    font-size: 17px;
  }

  .item-views-info {
    gap: 15px;
  }

  .btn-download-analytics {
    width: 163px;
    font-size: 13px;
  }

  .disable-btn-download-analytics {
    width: 163px;
    font-size: 13px;
  }

  .analytics-dt-label-container {
    grid-template-columns: 1fr 1.6fr 1.2fr 1fr 1fr;
  }

  .analytics-dt-label {
    font-size: 15px;
  }

  .analytics-dt-data-container {
    grid-template-columns: 1fr 1.6fr 1.2fr 1fr 1fr;
  }

  .analytics-dt-data {
    min-width: 100px;
    font-size: 13px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023.9px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .analytics-header-tabs-container {
    width: 350px;
  }

  .analytics-header-tabs-container li {
    width: fit-content;
    padding: 0 10px;
    font-size: 13px;
  }

  .analytics-header-tabs-container li.current {
    font-weight: 400;
  }
}
