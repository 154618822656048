.editor-modal-container-download {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 85%;
  width: 80%;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  z-index: 20;
}

.editor-modal-container-maps {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 85%;
  width: 70%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  z-index: 3;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.editor-modal-smaller-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 77%;
  width: 60%;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  cursor: default;
  z-index: 2;
}

.media-control-options-img {
  vertical-align: middle;
  margin-right: 5px;
}

.media-controls-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.media-control-options {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 15px;
}

.links-map-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 75%;
  width: 80%;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  z-index: 2;
  cursor: default;
}

.editor-modal-note {
  position: fixed;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80%;
  width: 70%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  z-index: 2;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  cursor: default;
}

.editor-modal-medium-container {
  z-index: 3;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 597px;
  width: 1060px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
}

.editor-hotspot-container {
  z-index: 3;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 597px;
  width: 80%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
}

.modal-full-screen {
  z-index: 3;
  position: absolute;
  background: #0e033352;
}

.editor-modal-share-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  width: 57%;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  z-index: 2;
}

.editor-modal-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #0e0333;
  width: 100%;
  height: 62px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.editor-modal-title-share {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #0e0333;
  width: 100%;
  height: 56px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.links-map-modal-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #0e0333;
  width: 100%;
  height: 56px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.edit-modal-headers {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  padding: 2px 0;
}

.header-floorplan {
  display: flex;
  flex-direction: row;
  gap: 60px;
}

.editor-modal-header-title {
  font-weight: 400;
  font-size: 20px;
  cursor: default;
}

.editor-modal-content {
  height: 542px;
}

.modal-big-floorplan {
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 87%;
  height: 87%;
  background: #ffffff;
  text-align: center;
  border-radius: 16px;
}

.modal-floorplan {
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  background: #ffffff;
  text-align: center;
  border-radius: 16px;
}

.modal-header-floor {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #0e0333;
  height: 67px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-header-title {
    font-size: 1.25rem;
    color: white;
  }
  button {
    height: 20px;
    width: 20px;
    position: fixed;
    top: 3vh;
    right: 3vw;
  }
}

.modal-headers-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  padding: 32px auto;
}

.modal-content-container {
  height: 336px;
  display: flex;
}

.btn-modal-slider {
  margin: 0 30px;
}

.panorama-preview-modal {
  position: relative;
  width: 196px;
  height: 138px;
  margin: 0 10px 24px 10px;
}

.thumbnail-imagen-container {
  position: relative;
}

.thumbnail-preview-modal {
  width: 196px;
  height: 111px;
  border-radius: 8px;
}

.thumbnail-preview-modal-text {
  font-size: 14px;
}

.selected-panorama {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 50%;
}

.close-button {
  position: absolute;
  top: 27px;
  right: 21px;
  transform: translate(0, -40%);
}

.add-annotation-title {
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #0e0333;
  height: 42px;
  border-top-left-radius: 5px;
}

.annotation-options-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: 65%;
  margin: 10px 0px 0 8px;
}

.annotation-toggle {
  position: absolute;
  bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.annotation-options {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 5px;
}

.select-annotation {
  cursor: pointer;
  padding: 0px 15px;
  font-weight: 400;
  font-size: 15px;
  width: 140px;
  height: 28px;
  border: 1px solid #0e0e0e;
  background: #ffffff;
  border-radius: 8px;
}

.add-an-image-block {
  display: flex;
  justify-content: center;
  height: 88px;
  width: 158px;
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  margin: 27px 12px 0 12px;
  position: relative;
}

.update-tour-container {
  height: 80%;
  width: 90%;
  margin: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: default;
}

.settings-branding-logo {
  display: flex;
  gap: 40px;
  align-items: center;
  margin-bottom: 28px;
}

.settings-modal-container-profile-branding {
  align-self: flex-start;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 24px;
}

.update-tour-input-dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 52px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
}

.update-tour-input-dropdown-option {
  background: none;
  border: none;
  width: 100%;
  color: #0e03338f;
  padding: 12px 24px;
  text-align: left;
  font-size: 16px;
}

.update-tour-input-dropdown-option:hover {
  color: #0e0333;
  background: #f3f5f9;
}

.settings-modal-container {
  align-self: flex-start;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.form-input {
  position: relative;
  box-sizing: border-box;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  background: #f2f6ff;
  border: none;
  border-bottom: 2px solid #0e0333;
  margin-right: 5px;
  margin-bottom: 24px;
  height: 43px;
  width: 100%;
  padding: 11px 16px;
}

.btn-lead-active-settings {
  width: 279px;
  height: 42px;
  background: #0e0333;
  border-radius: 8px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-config {
  min-width: 170px;
  background: #0e0333;
  border-radius: 8px;
  height: 42px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-confirm {
  min-width: 170px;
  background: #0e0333;
  border-radius: 8px;
  height: 42px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: none;
  gap: 10px;
}

.settings-drag-drop-container {
  width: 150px;
  height: 150px;
  background-color: #f2f6ff;
  border: 1px solid #d5d7df;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.choose-file-button {
  color: #0e0333;
  margin-top: 6px;
  background: #fff;
  border: 1px solid #0e0333;
  padding: 3px;
  border-radius: 8px;
}

.edit-modal-inside-container {
  align-self: flex-start;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.edit-modal-inside-container-floorplan {
  align-self: flex-start;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.edit-modal-inside-container-share {
  position: relative;
  height: 100%;
  left: 50%;
  width: 69%;
  transform: translate(-50%, 0);
  padding-top: 20px;
}

.edit-modal-inside-container-embed-tour {
  align-self: flex-start;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.list-panoramas-dots-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: 12px;
  margin: auto 0;
}

.dot-navigation,
.dot-navigation-active {
  content: '';
  width: 8px;
  height: 8px;
  margin: 0 16px;
  background-color: #0e033333;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s linear;
}

.dot-navigation-active {
  width: 12px;
  height: 12px;
  background-color: #0e0333;
  transition: all 0.2s linear;
}

.hide {
  visibility: hidden;
  pointer-events: none;
}

.show {
  visibility: visible;
}

.edit-modal-inside-container-download {
  position: relative;
  width: 80%;
  margin: auto;
  border-radius: 8px;
}

.modal-btn-container {
  height: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.modal-btn-container-note {
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100%;
  position: relative;
  bottom: 20px;
}

.modal-btn-container-download {
  display: flex;
  justify-content: center;
  margin: 25px 0;
}

.modal-btn-container-links {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 90%;
  justify-content: center;
  align-items: center;
}

.editor-modal-btn {
  font-family: Inter;
  width: fit-content;
  min-width: 136px;
  height: 42px;
  padding: 0 12px 0 12px;
  font-weight: 400;
  font-size: 16px;
  outline: none;
  border-radius: 8px;
  border: none;
  border: 1px solid rgba(44, 46, 74, 0.64);
  background: #fff;
  color: rgba(44, 46, 74, 0.64);
}

.editor-modal-btn-delete {
  width: 136px;
  height: 42px;
  font-weight: 400;
  font-size: 16px;
  outline: none;
  border-radius: 8px;
  border: none;
  color: #0e0333;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #0e0333;
  box-sizing: border-box;
  border-radius: 8px;
}

.button-cancel {
  width: 136px;
  height: 42px;
  font-weight: 400;
  font-size: 16px;
  outline: none;
  border-radius: 8px;
  border: none;
  padding: 0 12px 0 12px;
  color: #0e0333;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #0e0333;
  box-sizing: border-box;
  border-radius: 8px;
}

.editor-modal-btn-disable {
  background: #ffffff;
  color: #0e0333a3;
  border: 1px solid #0e0333a3;
}

.editor-modal-btn-active {
  background: #0e0333;
  color: white;
  font-family: Inter;
  width: fit-content;
  min-width: 136px;
  height: 42px;
  padding: 0 12px 0 12px;
  font-weight: 400;
  font-size: 16px;
  outline: none;
  border-radius: 8px;
  border: none;
  border: 1px solid rgba(44, 46, 74, 0.64);
  color: #ffff;
}

.settings-editor-tabs-text {
  display: flex;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: left;
}

ul.settings-editor-tabs {
  font-weight: 400;
  cursor: pointer;
  margin: 15px 5% 0 5%;
  display: flex;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
}

ul.settings-editor-tabs li {
  align-items: center;
  border-bottom: 2px solid transparent;
  color: #0e0333;
  cursor: pointer;
  display: flex;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  height: 50px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}

ul.settings-editor-tabs li.current {
  border-bottom: 2px solid #0e0333;
  color: #0e0333;
  font-weight: 700;
}

.note-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  height: 90%;
  align-items: flex-end;
  flex: 1;
}

.error-wrong-url {
  position: absolute;
  left: 0;
  bottom: -14px;
  color: red;
  font-size: 10px;
}

.options {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-container-note {
  margin-left: 40px;
  width: 85%;
  flex: 1;
}

.main-container-note {
  width: 100%;
  height: 70%;
  padding-top: 15px;
  display: flex;
  justify-content: center;
}

.note-option {
  width: 100px;
  height: 100px;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.type-note-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: 50%;
  width: 85%;
  padding-right: 40px;
}

.size-container {
  height: 50%;
  width: 85%;
  padding-right: 40px;
}

.still-image-container {
  display: flex;
}

.add-note-label {
  font-weight: 500;
  font-size: 14px;
  color: #0e0333;
  margin-top: 20px;
  margin-bottom: 8px;
}

.add-note-label-title {
  font-weight: 500;
  font-size: 14px;
  color: #0e0333;
  margin-top: 20px;
  margin-bottom: 8px;
}

.add-note-label-url {
  font-weight: 500;
  font-size: 14px;
  color: #0e0333;
  margin-top: 20px;
  margin-bottom: 8px;
}

.add-note-label-description {
  font-weight: 500;
  font-size: 14px;
  color: #0e0333;
  margin-top: 20px;
  margin-bottom: 8px;
}

.add-media-label {
  font-weight: 500;
  font-size: 14px;
  color: #0e0333;
  margin: 5px;
}

.add-media-description-label {
  font-weight: 500;
  font-size: 14px;
  color: #0e0333;
  margin: 5px;
}

.add-image-label {
  font-weight: 500;
  font-size: 14px;
  color: #0e0333;
  margin: 5px;
}

.add-image-description-label {
  font-weight: 500;
  font-size: 14px;
  color: #0e0333;
  margin: 5px;
}

.drag-image-container {
  position: relative;
  flex: 1 1;
  margin-right: 60px;
}

.address-input-setting .rbt-input-main {
  position: relative;
  box-sizing: border-box;
  padding: 11px 16px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  background: #f2f6ff;
  border: none;
  border-bottom: 2px solid #0e0333;
  margin-right: 5px;
  height: 43px;
  width: 100%;
}

.add-note-input-title {
  box-sizing: border-box;
  padding: 11px 16px;
  font-size: 16px;
  background: #f2f6ff;
  border: none;
  border-bottom: 1px solid #0e0333;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-right: 5px;
  height: 42px;
  width: 70%;
}

.add-note-input-url {
  box-sizing: border-box;
  padding: 11px 16px;
  font-size: 16px;
  background: #f2f6ff;
  border: none;
  border-bottom: 1px solid #0e0333;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-right: 5px;
  height: 42px;
  width: 70%;
}

.add-note-input-title:focus {
  border-bottom: #0038ff 2px solid;
}

.add-note-input-url:focus {
  border-bottom: #0038ff 2px solid;
}

.add-note-input-inactive {
  box-sizing: border-box;
  padding: 11px 16px;
  font-size: 16px;
  background: #f2f3f5;
  border: none;
  border-bottom: 1px solid #818aa6;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-right: 5px;
  height: 42px;
  width: 70%;
}

.add-share-input-email {
  font-size: 16px;
  width: 70%;
  height: 42px;
  background: #f5f7fb;
  border: none;
  border-bottom: 2px solid rgba(44, 46, 74, 0.54);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  box-sizing: border-box;
  margin-top: 8px;
  padding: 5px 15px;
  outline: none;
  appearance: none;
}

.add-media-input {
  box-sizing: border-box;
  padding: 20px 5px;
  font-size: 16px;
  background: #f2f6ff;
  border: none;
  border-bottom: 1px solid #0e0333;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-right: 5px;
  height: 42px;
  width: 100%;
}

.add-image-input {
  box-sizing: border-box;
  padding: 20px 5px;
  font-size: 16px;
  background: #f2f6ff;
  border: none;
  border-bottom: 1px solid #0e0333;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-right: 5px;
  height: 42px;
  width: 100%;
}

.add-media-input:focus {
  border-bottom: 2px solid #0038ff;
}

.add-image-input:focus {
  border-bottom: 2px solid #0038ff;
}

.image-container-title:focus-within .add-image-label {
  color: #0038ff;
}

.media-container-title:focus-within .add-media-label {
  color: #0038ff;
}

.image-container-description:focus-within .add-image-description-label {
  color: #0038ff;
}

.media-container-description:focus-within .add-media-description-label {
  color: #0038ff;
}

.add-note-text-area {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  width: 70%;
  height: 121px;
  background: #f2f6ff;
  border: none;
  border-bottom: 1px solid #0e0333;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  box-sizing: border-box;
  padding: 5px 15px;
  outline: none;
  appearance: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.add-note-text-area:focus {
  border-bottom: #0038ff 2px solid;
}

.container-note-title:focus-within .add-note-label-title {
  color: #0038ff;
}

.container-note-url:focus-within .add-note-label-url {
  color: #0038ff;
}

.container-note-description:focus-within .add-note-label-description {
  color: #0038ff;
}

.add-media-text-area {
  width: 100%;
  height: 120px;
  padding: 11px 16px;
  margin-right: 5px;
  background: #f2f6ff;
  border: none;
  border-bottom: 1px solid #0e0333;
  border-radius: 8px 8px 0px 0px;
  outline: none;
  resize: none;
  box-sizing: border-box;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.add-image-text-area {
  width: 100%;
  height: 120px;
  padding: 11px 16px;
  margin-right: 5px;
  background: #f2f6ff;
  border: none;
  border-bottom: 1px solid #0e0333;
  border-radius: 8px 8px 0px 0px;
  outline: none;
  resize: none;
  box-sizing: border-box;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.add-media-text-area:focus {
  border-bottom: #0038ff 2px solid;
}

.add-image-text-area:focus {
  border-bottom: #0038ff 2px solid;
}

.upload-still-image-subtitle {
  margin: 20px 0;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: #757575;
}

.viewer-social-media-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
}

.viewer-social-media-icons {
  background-color: white;
  border-radius: 50px;
  margin: 10px;
}

.viewer-social-media-icons:hover {
  fill: #fff;
  background-color: #1abc9c;
}

.viewer-social-media-icons:hover path {
  fill: #fff;
}

.viewer-share-title {
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin: 15px 0;
}

.viewer-copy-link-embed {
  margin-top: 10px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  border: none;
  outline: none;
  width: 173px;
  height: 42px;
  position: relative;
  background: #0e0333;
  border-radius: 8px;
  cursor: pointer;
}

.viewer-share-container {
  position: relative;
  display: flex;
  margin: 10px auto;
  width: 80%;
}

.italic-share-message-error {
  font-style: italic;
  position: absolute;
  top: 40px;
  font-size: 14px;
  font-weight: 300;
  color: #e74c3c;
}

.viewer-embed-tour {
  position: relative;
  font-size: 14px;
  width: 90%;
  height: 190px;
  margin: 10px;
  padding: 20px 20px 0px 20px;
  background: #e6ebf4;
  border-radius: 8px;
  border: 0;
  resize: none;
}

.viewer-embed-copy {
  color: #0e0333;
  padding: 8px 10px;
  position: absolute;
  bottom: 15%;
  right: 7%;
  border: 1px solid #0e0333;
  background: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.share-send-emails {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.share-by-email {
  color: #818aa6;
  padding: 8px 10px;
  border: none;
  width: 173px;
  height: 42px;
  background: #c7ccda;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  cursor: pointer;
}

.address-input-setting {
  flex: 1;
  margin: 0px 20px;
}

.add-icon-colour path {
  fill: #0e0333;
}

.icon-upload-image {
  display: block;
  margin: 60px auto 10px auto;
}

.icon-sounds-image {
  display: block;
  margin: 4px auto 10px auto;
}

.viewer-tick {
  position: absolute;
}

.presets-btn-remove-default {
  position: relative;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.embed-tour-viewer {
  box-sizing: border-box;
  padding: 15px 15px;
  font-size: 16px;
  background: #bcc2cd29;
  border: none;
  border-bottom: 2px solid #808292;
  margin-right: 5px;
  height: 50px;
  width: 100%;
  resize: none;
  overflow: hidden;
}

.sent-email-viewer {
  height: 62px;
  width: 446px;
  background: #1abc9c14;
  border: 2px solid rgba(26, 188, 156, 0.72);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  margin: auto;
}

.edit-modal-subtitle-download {
  font-size: 1.125rem;
  text-align: center;
  font-weight: 400;
  padding: 20px 0;
}

.annotation-title {
  font-size: 16px;
  font-weight: 300;
  display: flex;
}

.select-hotspot-image,
.select-return-link {
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.create-hotspot-steps {
  border: 2px solid white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-panoramas-return-link {
  width: 884px;
  height: 300px;
  display: flex;
  flex-wrap: wrap;
}

.add-notes-subtitle {
  font-weight: 400;
  font-size: 18px;
  color: #0e0333;
  margin-top: 24px;
  text-align: center;
}

.media-upload-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 6%;
}

.save-changes-add-media {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.still-image-drop-zone {
  overflow: hidden;
  height: 100%;
  background: #f2f6ff;
  border: 1px solid rgba(200, 201, 209, 0.7);
  margin-top: 5px;
  border-radius: 8px;
  overflow: hidden;
}

.drop-media-img {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 142px;
  height: 32px;
}

.upload-media-button {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 142px;
  background: #fff;
  height: 32px;
  border: 1px solid #0e0333;
  border-radius: 8px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.still-image-helper-text {
  font-weight: 400;
  font-size: 16px;
  color: #0e0333;
  padding-top: 70px;
  text-align: center;
}

u {
  padding-bottom: 1px;
  text-decoration: none;
  border-bottom: 1px solid #000;
}

.cross {
  position: absolute;
  right: -20px;
  top: 8px;
}

.still-media-not-compatible {
  height: 40px;
  text-align: center;
  background: #e74c3c14;
  border: 2px solid #e74c3cb8;
  padding: 5px 10px;
  border-radius: 8px;
}

.uploading-image-spinner-order {
  position: absolute;
  top: 4px;
  right: -42px;
}

.links-map-error {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 12px;
  text-align: center;
  height: 55px;
  padding: 0 10px;
  border-radius: 8px;
  background: rgba(231, 76, 60, 0.08);
  border: 2px solid #e74c3c;
}

.missing-links-image {
  margin: 10px;
}

.links-map-success {
  margin-top: 10px;
  text-align: center;
  font-size: 18px;
  line-height: 25px;
  color: #0e0333;
}

.links-map-error-text {
  font-size: 18px;
  color: #0e0333;
}

.links-map-panoramas {
  margin-top: 15px;
  height: 400px;
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.links-paginated-container {
  width: 85%;
  display: flex;
  justify-content: center;
}

.links-btn-change-panorama {
  position: relative;
  margin: auto;
  background: #e74c3c;
  outline: none;
  border: none;
  height: 32px;
  width: 172px;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
}

.small-link-img {
  position: absolute;
  left: 25px;
}

.panorama-links-container {
  height: 261px;
  width: 260px;
  border: 1px solid #d5d7df;
  background: #f2f6ff;
  margin: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-link-icon {
  position: absolute;
  padding-top: 25px;
  border-radius: 8px;
  top: 0;
  left: 0;
  background: #e74c3c3d;
  width: 205px;
  height: 102px;
}

.outside-area {
  height: 100%;
  z-index: 10;
  background: #00000040;
}

.property-address-input-maps {
  width: 26%;
  margin: 30px 0;
  height: 52px;
  background: #f5f7fb;
  border: none;
  border-bottom: 2px solid rgba(44, 46, 74, 0.54);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  box-sizing: border-box;
  padding: 5px 15px;
}

.property-address-input-maps-inside {
  width: 35%;
  position: absolute;
  bottom: -15px;
  left: 1vw;
  margin: 30px 0;
  height: 52px;
  color: white;
  background-color: #0e0333;
  border: none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  box-sizing: border-box;
  padding: 5px 15px;
}

.remove-input {
  display: none;
}

.search-maps-location {
  padding-bottom: 3px;
  margin-left: 24px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  width: 117px;
  height: 42px;
  outline: none;
  border-radius: 4px;
  border: none;
}

.map-container {
  height: 50vh;
}

#map {
  height: 100%;
}
.text-map-container {
  background: rgba(44, 46, 74, 0.64);
  color: white;
  position: absolute;
  top: 16vh;
  right: 19%;
  height: 34px;
  opacity: 0.7;
}

.text-map {
  text-align: center;
  padding: 1vh;
  font-size: 1rem;
}

.maps-btn-settings {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  right: 0px;
  height: 40px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.links-map-image-container {
  position: relative;
  margin: 10px 15px;
  display: flex;
}

.links-map-thumbnail-preview {
  margin: 0 auto;
  height: 102px;
  border-radius: 8px;
}

.no-link-img {
  left: 95px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 30px;
  top: 30px;
}

.media-drop-or {
  font-weight: 400;
  font-size: 12px;
  width: 50%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  margin: 8px auto;
}

.media-drop-or:before,
.media-drop-or:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 50%;
  height: 1px;
  content: '\a0';
  background-color: #e6ebf4;
}

.media-drop-or:before {
  margin-left: -50%;
  text-align: right;
}

.links-map-pano-name {
  margin: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: #0e0333;
}

/*--------------------------------------   Big Screens   -------------------------------------------*/
// @media (min-width: 1750px) {
.dd-cross-container {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('../../../images/viewer-icons/editor-logo-cross.svg');
  background-repeat: no-repeat;
  position: absolute;
  top: -14px;
  right: 60px;
  visibility: hidden;
}

.dd-image-container {
  position: relative;
}

.dd-image-container:hover .dd-cross-container {
  visibility: visible;
}

.dd-image {
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  object-fit: contain;
}

.dd-text {
  margin-top: 0.5rem;
  font-size: 14px;
  cursor: pointer;
}

.default {
  text-decoration: underline;
  text-underline-offset: 0.25rem;
}

.drag-drop-input-container {
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// input[type='file'] {
//   color: transparent;
//   position: relative;
//   cursor: pointer;
//   width: 100%;
//   height: 100%;
//   border-radius: 50%;
//   opacity: 0;
// }

.settings-drag-drop-text-container {
  position: absolute;
  width: fit-content;
  height: fit-content;
  pointer-events: none;
  text-align: center;
}

.update-tour-container-branding {
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
}

.settings-display-tripods-branding {
  width: 100%;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
}

.drag-drop-text {
  color: #0e0333;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.underlined {
  text-decoration: underline;
  text-underline-offset: 0.25rem;
  text-align: center;
}

.line-between-text {
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;
}
.line-between-text:before,
.line-between-text:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 50%;
  height: 1px;
  content: '\a0';
  background-color: #e6ebf4;
}

.line-between-text:before {
  margin-left: -50%;
  text-align: right;
}

.line-between-text-links {
  font-weight: 400;
  font-size: 12px;
  width: 90%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  margin: 8px auto;
}
.line-between-text-links:before,
.line-between-text-links:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 50%;
  height: 1px;
  content: '\a0';
  background-color: #e6ebf4;
}

.line-between-text-links:before {
  margin-left: -50%;
  text-align: right;
}

.add-image-container-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-state-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.text-container-link {
  margin-left: 55px;
  margin-top: 93px;
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 31px;
}

.title-no-link {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #0e0333;
}

.sub-text-link {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #757575;
}

.no-link-button {
  margin-top: 57px;
  width: 60%;
  height: 42px;
  background: #0e0333;
  border-radius: 8px;
  color: white;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
}

.no-link-large {
  width: 100%;
  position: relative;
  height: 90%;
}

@media only screen and (max-width: 1024px) {
  .settings-editor-tabs-text {
    font-size: 15px;
  }
}

@media only screen and (max-width: 650px) {
  .settings-editor-tabs-text {
    font-size: 12px;
  }
}
