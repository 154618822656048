.edit-hotspot-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tabs {
  grid-area: tabs;
}

ul.edit-hotspot-tabs {
  width: 708px;
  height: 42px;
  color: #0e0333;
  cursor: pointer;
  margin: 12px auto;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

ul.edit-hotspot-tabs li {
  font-weight: 400;
  text-align: end;
  margin-bottom: -1px;
  border-bottom: 2px solid transparent;
  transition: all 0.2s linear;
}

ul.edit-hotspot-tabs li.current {
  font-weight: 500;
  margin-bottom: -1px;
  border-bottom: 2px solid #0e0333;
  transition: all 0.2s linear;
}

.edit-hotspot-tab-first {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 231px;
  height: 42px;
}

.edit-hotspot-tab-second {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 42px;
}

.edit-hotspot-tab-third {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 187px;
  height: 42px;
}

.auto-enhancement-container-tab {
  display: flex;
  margin: 10px 0 20px 0;
  justify-content: center;
}

.preview-edit {
  border-radius: 8px;
  height: 144px;
  width: 288px;
}

.auto-enhance-image {
  width: 288px;
  height: 42px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  background: #00269a;
  cursor: pointer;
}

.edit-hotspot-panelhook {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.edit-hotspot-description {
  min-height: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
}

.edit-hotspot-content {
  margin: 20px auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.ehc-center {
  justify-content: center;
  align-items: center;
}

.edit-hotspot-panoramas,
.edit-hotspot-icons,
.edit-hotspot-colours {
  width: 500px;
  height: 300px;
  overflow-y: scroll;
  display: flex;
  //   justify-content: center;
  flex-wrap: wrap;
}

.edit-hotspot-icons {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.edit-hotspot-colours {
  width: 470px;
  height: 300px;
  margin: 0 50px 0 auto;
}

.dhi-text {
  padding: 5px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.edit-hotspot-panorama-container {
  width: 196px;
  height: 138px;
  margin: 0px 20px 24px 0px;
}

.preview-tick-link{
  width: 32px;
  height: 32px;
}

.dhp-image-container {
  position: relative;
  width: 196px;
  height: 111px;
}

.dhp-image {
  width: 100%;
  height: 111px;
  object-fit: cover;
  border-radius: 8px;
}

.ehp-text {
  margin-top: 8px;
  font-size: 14px;
}

.ehc-text {
  font-size: 14px;
  font-weight: 500;
}

.fa-container {
  width: 40px;
  height: 40px;
  margin-bottom: 26px;
  margin-right: 32px;
  border-radius: 8px;
  background: none;
  border: none;
  outline: none;
}

.fa-container-selected {
  background: #0e0333;
}

.fa {
  color: #0e0333;
}

.ehi-size-preview {
  margin-top: 24px;
  width: 308px;
  height: 156px;
  display: flex;
  justify-content: space-between;
}

.ehi-preview-item {
  width: 80px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.icon-size-preview-small {
  text-align: center;
  min-width: 40px;
  width: auto;
  height: auto;
  margin-bottom: 15px;
}

.icon-size-preview-medium {
  text-align: center;
  min-width: 56px;
  width: auto;
  height: auto;
  margin-bottom: 15px;
}

.icon-size-preview-large {
  text-align: center;
  min-width: 80px;
  width: auto;
  height: auto;
  margin-bottom: 15px;
}

.ehi-preview-text {
  font-size: 12px;
}

#ehi-radio {
  margin-top: 15px;
  accent-color: #0e0333;
}

.ehc-colours-container {
  display: flex;
  flex-wrap: wrap;
}

.ehc-colour {
  position: relative;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  margin: 0px 32px 24px 0px;
  border: none;
}

.ehc-colour-selected {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-margin {
  margin: 0 0 0 0;
}

.edit-hotspot-preview,
.edit-hotspot-size-selector,
.edit-hotspot-own-colour {
  position: relative;
  // border: 1px solid red;
  //   margin: 0 80px 0 30px;
  width: 412px;
  height: 273px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.edit-hotspot-preview {
  background-color: rgba(188, 194, 205, 0.4);
  color: #ffffff;
}

.edit-hotspot-size-selector {
  color: #0e0333;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.edit-hotspot-own-colour {
  align-self: flex-start;
  width: 176px;
  height: 149px;
  margin: 0 auto 0 40px;
}

.edit-hotspot-own-colour p {
  align-self: flex-start;
}

.ehc-controls {
  margin-top: 24px;
  width: 176px;
  height: 108px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ehc-controls-selector {
  display: flex;
  justify-content: space-between;
}

.ehc-input-colour-select {
  height: 42px;
  width: 119px;
  padding: 11px 16px;
  font-size: 14px;
  background: #f2f6ff;
  border: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid #757575;
}

.ehc-controls-button {
  align-self: center;
}

.edit-hotspot-buttons {
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 15px auto;
}

.edit-hotspot-btn,
.edit-hotspot-btn-secondary,
.edit-hotspot-btn-primary-active,
.edit-hotspot-btn-primary-inactive {
  width: 172px;
  height: 42px;
  font-size: 16px;
  border-radius: 8px;
  background: none;
  outline: none;
  color: #0e0333;
}

.edit-hotspot-btn-secondary {
  border: 1px solid #0e0333;
  background-color: #ffffff;
}

.edit-hotspot-btn-primary-active {
  border: none;
  background-color: #0e0333;
  color: #ffffff;
}

.edit-hotspot-btn-primary-inactive {
  border: none;
  background-color: #fff;
  border: 1px solid rgba(44, 46, 74, 0.64);
  color: #818aa6;
}

.notification-box {
  width: auto;
  height: 52px;
  display: flex;
  align-items: center;
  background-color: rgba(26, 188, 156, 0.1);
  border-radius: 8px;
  border: 2px solid rgba(78, 199, 175, 1);
}

.notification-box img {
  margin: 12px 14px;
}

.notification-box p {
  margin: 12px 14px 12px 0;
}

.empty-state-edit {
  left: 0;
  background: #bcc2cd00;
  width: 100%;
  height: 273px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-destination-viewer {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  margin: 0 108px;
}

.modal-background-layer {
  z-index: 2;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100%;
  background: rgba(44, 46, 74, 0.32);
}

.delete-confirmation-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  color: black;
  width: 520px;
  height: 255px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  z-index: 5;
}

.delete-confirmation-modal-header {
  width: 100%;
  height: 56px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #0e0333;
  color: #ffffff;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-confirmation-modal-body {
  width: 100%;
  height: 199px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.delete-confirmation-modal-body p {
  width: 219px;
  height: 49px;
  font-size: 18px;
  line-height: 21.78px;
  letter-spacing: 0em;
  text-align: center;
}

.delete-buttons-group {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.delete-buttons-group button {
  font-size: 16px;
  line-height: 19.36px;
}

.delete-hotspot-btn-cancel,
.delete-hotspot-btn-delete {
  width: 174px;
  height: 42px;
  outline: none;
  border-radius: 8px;
}

.delete-hotspot-btn-cancel {
  border: 1px solid #0e0333;
  background-color: #ffffff;
  color: #0e0333;
}

.delete-hotspot-btn-delete {
  border: none;
  background-color: #0e0333;
  color: #ffffff;
}
