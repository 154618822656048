.editor-modal-container-settings {
  position: fixed;
  width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  z-index: 2;
}

.branding-input-settings {
  position: absolute;
  cursor: pointer;
  left: 50%;
  height: 200px;
  width: 100%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  color: transparent;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
}

.settings-tabs-container {
  height: 598px;
}

.settings-choose-logo-text {
  font-size: 16px;
  color: #0e0333;
  font-weight: 500;
}

.arrow-country-settings {
  position: absolute;
  right: 1rem;
  top: 4.7rem;
  pointer-events: none;
}

.logo-tripod-requirements {
  color: #757575;
  font-weight: 400;
}

.settings-modal-container-profile {
  align-self: flex-start;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: default;
}

.modal-btn-container-profile {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 470px;
}

.modal-btn-container-branding {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 30px;
}

.modal-btn-container-branding-config {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 68px;
}

.editor-modal-btn-profile {
  width: fit-content;
  min-width: 170px;
  height: 42px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  outline: none;
  border-radius: 8px;
  border: none;
  background: #c7ccda;
  color: black;
  cursor: pointer;
  padding: 10px 32px;
}

.editor-modal-btn-branding {
  width: fit-content;
  min-width: 170px;
  height: 42px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  outline: none;
  border-radius: 8px;
  padding: 11.5px 32px;
}

.editor-modal-btn-active-profile {
  background: #0e0333;
  color: white;
  pointer-events: visible;
}
.editor-modal-btn-active-branding {
  background: #0e0333;
  color: white;
  pointer-events: visible;
}

.list-panoramas-dots-navigation-branding {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: 12px;
  margin: 36px 0 0 0;
}

.btn-lead-settings {
  width: fit-content;
  min-width: 170px;
  height: 42px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  outline: none;
  border-radius: 8px;
  border: none;
  background: #c7ccda;
  color: #818aa6;
  cursor: pointer;
  padding: 10px 32px;
}

.btn-lead-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.btn-lead-active-settings {
  background: #0e0333;
  color: white;
  pointer-events: visible;
}

.viewer-config-container {
  margin: 40px 0;
}

@media only screen and (max-width: 1024px) {
  .settings-tabs-container {
    height: 550px;
  }

  .modal-btn-container-profile {
    top: 450px;
  }

  .modal-btn-container-branding {
    top: 450px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .settings-tabs-container {
    height: 550px;
  }

  .settings-editor-tabs-text {
    font-size: 15px;
  }

  .form-input {
    font-size: 12px;
  }

  .dd-image {
    width: 125px;
    height: 125px;
  }

  .settings-drag-drop-container {
    width: 125px;
    height: 125px;
  }

  .drag-drop-text {
    color: #0e0333;
    font-size: 11px;
    font-weight: 400;
    line-height: 22px;
  }

  .choose-file-button {
    font-size: 11px;
    text-align: center;
    width: 80px;
    height: 40px;
  }

  .dd-text {
    font-size: 11px;
  }
}
