@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap');
// $fa-font-path: '~font-awesome/fonts';
// @import '~font-awesome/scss/font-awesome';
// $fa-font-path: '~font-awesome/fonts';
// @import '~font-awesome/scss/font-awesome';
// font awesome 5 pro
@import 'node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss';
@import 'node_modules/@fortawesome/fontawesome-pro/scss/solid.scss';
@import 'node_modules/@fortawesome/fontawesome-pro/scss/light.scss';
@import 'node_modules/@fortawesome/fontawesome-pro/scss/brands.scss';
@import 'node_modules/@fortawesome/fontawesome-pro/scss/regular.scss';
@import 'node_modules/@fortawesome/fontawesome-pro/scss/duotone.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  background-color: #f9fafe;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

a {
  color: inherit;
}

input {
  outline: none;
  text-decoration: none;
  border-radius: 0;
}

.button-hover:hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14);
  transform: scale(1.01);
  transition: all 0.1s linear;
  cursor: pointer;
}

button:focus {
  outline: none;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3 {
  font-weight: bolder;
  padding: 0;
  margin: 0;
}

h4 {
  font-weight: 400;
}

.display-flex {
  display: flex;
  justify-content: space-between;
}

.position-relative {
  position: relative;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-column {
  flex-direction: column;
}

.center {
  margin: auto;
}

.hide {
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s linear;
}

.show {
  opacity: 1;
  visibility: visible;
  transition: all 0.1s linear;
  z-index: 200;
}

.img-align {
  vertical-align: middle;
}

.initial-pages-bg {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  background-color: #f2f6ff;
  overflow: hidden;
}

.active-font {
  color: #fff;
  align-items: center;
  justify-content: space-between;
}

.inactive-font {
  color: #ffffff6b;
}

.btn-remove-default {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.default-link {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle scrollbar */
::-webkit-scrollbar-thumb {
  background: #0e0333;
  border-radius: 50px;
  margin-top: 10px;
}

::-webkit-scrollbar {
  width: 15px;
}
::-webkit-scrollbar-thumb {
  height: 10px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

.pwd-check-status {
  position: relative;
  top: 3px;
  padding: 1px 10px 1px 5px;
}

/*-----  Dashboard   -----*/

.font-lato-generic {
  font-weight: 400;
  font-size: 1rem;
}

.ping-test {
  font-weight: 300;
  color: #8b8d8e;
}

.general-btn-delete {
  cursor: pointer;
  height: 42px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  padding: 10px 32px 10px 32px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  outline: none;
  border-radius: 8px;
  border: none;
}

.general-btn-delete-active {
  background: #ffffff;
  color: #2c2e4a;
  border: 1px solid #2c2e4a;
}

.page-prev {
  transition: all 0.2s linear;
  margin-top: 12px;
  margin-right: 10px;
}

.page-prev.disabled {
  transition: all 0.2s linear;
  opacity: 0;
}

.page-next.disabled {
  transition: all 0.2s linear;
  opacity: 0;
}

.page-next {
  transition: all 0.2s linear;
  margin-top: 12px;
  margin-left: 10px;
  cursor: pointer;
}

ul.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 64px;
  // margin: 13px 0 0 0;
  font-size: 16px;
  position: relative;
  bottom: 0;
  left: 0;
  gap: 5px;
}

ul.pagination li {
  text-align: center;
  height: 32px;
  min-width: 32px;
  color: #0e0333;
}

.page-number a {
  vertical-align: -webkit-baseline-middle;
  cursor: pointer;
}

ul.pagination li.page-number.active {
  font-size: 16px;
  border-radius: 50%;
  color: #fff;
  background: #0e0333;
}

.pagination-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*--------------------------------------   Tooltip - intro.js  -------------------------------------------*/
.tooltip-icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.1s ease-in;
}
.tooltip-icon:hover {
  -webkit-box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.25);
  box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.25);
  transform: scale(1.2);
  transition: all 0.1s ease-in;
}
.tooltip-light {
  background-image: url('../images/general-icons/tooltipLight.svg');
}
.tooltip-light-active {
  background-image: url('../images/general-icons/tooltipLightActive.svg');
}
.tooltip-dark {
  background-image: url('../images/general-icons/tooltipDark.svg');
}
.tooltip-dark-active {
  background-image: url('../images/general-icons/tooltipDark.svg');
}

.btn-new-design {
  font-family: Inter;
  font-size: 16px;
  line-height: 19px;
  min-width: 170px;
  height: 42px;
  letter-spacing: 0em;
  text-align: center;
  font-weight: 400;
  padding: 0 12px 0 12px;
  border: 1px solid #0e0333;
  border-radius: 8px;
  background: #fff;
}

.btn-new-cancel {
  background: #fff;
  border: 1px solid #0e0333;
  cursor: pointer;
}

.btn-new-inactive {
  background: #fff;
  color: rgba(44, 46, 74, 0.64);
}

.btn-new-active {
  background: #0e0333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.btn-new-active-red {
  background: #e74c3c;
  color: #fff;
  border: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.general-field-input-disable {
  color: #818aa6;
  height: 52px;
  min-width: 250px;
  padding: 18px 15px;
  background: #f2f3f5 !important;
  border: none;
  box-shadow: inset 0px -1px 0px #818aa6;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  pointer-events: none;
}

.general-field-input {
  height: 52px;
  min-width: 250px;
  width: 100%;
  padding: 18px 15px;
  background: #f2f6ff;
  border-top: none;
  border-left: none;
  border-right: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  cursor: pointer;
  text-align: left;
}

.general-field-disable {
  height: 52px;
  min-width: 250px;
  width: 100%;
  padding: 18px 15px;
  background: #f2f3f5;
  border: none;
  border-bottom: 2px solid #818aa6;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  color: #818aa6;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  pointer-events: none;
  text-align: left;
  pointer-events: none;
}

.general-field-input-text-area {
  height: 52px;
  min-width: 250px;
  width: 100%;
  padding: 18px 15px;
  background: #f2f6ff;
  border: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  cursor: text;
  text-align: left;
}

.general-field-input-text {
  height: 52px;
  min-width: 250px;
  width: 100%;
  padding: 18px 15px;
  background: #f2f6ff;
  border-top: none;
  border-left: none;
  border-right: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  cursor: text;
  text-align: left;
}

.general-field-input-text-date {
  height: 52px;
  min-width: 250px;
  width: 100%;
  padding: 18px 15px;
  background: #f2f6ff;
  border-top: none;
  border-left: none;
  border-right: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  cursor: pointer;
  text-align: left;
  border-bottom: 2px solid #0e0333;
}

.general-field-input-text-date-focus {
  height: 52px;
  min-width: 250px;
  width: 100%;
  padding: 18px 15px;
  background: #f2f6ff;
  border-top: none;
  border-left: none;
  border-right: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  cursor: pointer;
  text-align: left;
  border-bottom: 2px solid #0038ff;
}

.general-field-input-empty {
  color: #818aa6;
  text-overflow: ellipsis;
  border-bottom: 1px solid #0e0333;
  white-space: nowrap;
  overflow: hidden;
}

.general-field-complete {
  border-bottom: 2px solid #0e0333;
  height: 52px;
  min-width: 250px;
  width: 100%;
  padding: 18px 15px;
  background: #f2f6ff;
  border-top: none;
  border-left: none;
  border-right: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  cursor: text;
  text-align: left;
}
.general-field-input:focus {
  border-bottom: 1px solid #0038ff;
}

.general-field-focus {
  height: 52px;
  min-width: 250px;
  width: 100%;
  padding: 18px 15px;
  background: #f2f6ff;
  border: none;
  border-bottom: 2px solid #0038ff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  cursor: pointer;
  text-align: left;
}

.general-field-input-text:focus {
  border-bottom: 2px solid #0038ff;
}

.general-field-input:hover {
  background: #e8efff;
}
.general-field-input-text:hover {
  background: #e8efff;
}

.general-field-input::placeholder {
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  color: #818aa6;
}
.general-field-input-text::placeholder {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  color: #818aa6;
}

// ---- nav-topbar onboarding views -----
.onboarding-topbar-vieweet-logo {
  position: fixed;
  top: 3vh;
  left: 3%;
  max-width: 100%;
  height: auto;
}

.onboarding-topbar-links-section {
  position: fixed;
  height: 5vh;
  top: 1vh;
  right: 3%;
  display: flex;
  gap: 30px;
  align-items: center;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.onboarding-topbar-link-no-border-below {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.onboarding-topbar-link-no-border-below a {
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  color: inherit;
}

.onboarding-topbar-link-border-below {
  height: 5vh;
  display: flex;
  border-bottom: 1px #0e0333 solid;
  align-items: center;
  justify-content: center;
}

.onboarding-topbar-link-border-below h3 {
  font-size: 18px;
  font-weight: 700;
}

.new-feature-text {
  position: relative;
  display: flex;
  gap: 12px;
}

/*--------------------------------------   Big Screens   -------------------------------------------*/
// @media (min-width: 1600px) {

// }

// tablet
// @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
// }
// @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
// }

// phone
@media only screen and (min-width: 320px) and (max-width: 480px) {
  // ---- nav-topbar onboarding views -----
  .onboarding-topbar-vieweet-logo {
    position: fixed;
    top: 3vh;
    left: 3%;
    max-width: 100%;
    height: auto;
  }

  .onboarding-topbar-links-section {
    position: fixed;
    top: 0vh;
    right: 3%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 200px;
    height: 10vh;
  }

  .onboarding-topbar-link-no-border-below {
    width: 35%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid transparent;
  }

  .onboarding-topbar-link-no-border-below a {
    text-decoration: none;
    font-weight: 400;
    font-size: 15px;
    color: inherit;
  }
  .onboarding-topbar-link-border-below {
    width: 35%;
    border-bottom: 2px solid #0e0333;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .onboarding-topbar-link-border-below h3 {
    font-size: 15px;
    font-weight: 700;
  }
}
