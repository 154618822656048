.login-eye {
  width: 24px;
  height: 24px;
}

.login-initial-pages-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  width: 546px;
  height: 478px;
  padding: 60px 48px 44px 48px;
  border-radius: 4px;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
}

.login-initial-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.login-input-section {
  display: flex;
  flex-direction: column;
  margin: 0 0 20px 0;
}

.login-input-section label {
  color: #0e0333;
  width: auto;
  margin: 0 0 10px 0;
  display: inline-block;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.login-input {
  max-width: 100%;
  color: #0e0333;
  height: 56px;
  padding: 18px 15px;
  position: relative;
  margin: 0 0 0 0;
  background: #f2f6ff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: none;
  border-left: none;
  border-right: none;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.login-input:hover {
  background: #e8efff;
}

.login-input:focus {
  border-bottom: #0038ff 2px solid;
}

.input-wrong-state {
  border-bottom: 2px solid #e7433c;
}

.input-valid-state {
  border-bottom: 2px solid rgba(44, 46, 74, 1);
}

.input-neutral-state {
  border-bottom: 1px solid #0e0333;
}

.login-input-section:focus-within .label-login {
  color: #0038ff;
}

.login-input-section .label-login-error {
  color: #e7433c;
}

.signup-input-section:focus-within .label-login {
  color: #0038ff;
}

.signup-input-section .label-signup-error {
  color: #e7433c;
}

.forgot-password-input-section:focus-within .label-login {
  color: #0038ff;
}

.forgot-password-input-section .label-password-error {
  color: #e7433c;
}

.login-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login-show-password {
  border: none;
  position: absolute;
  cursor: pointer;
  bottom: 39%;
  right: 12%;
  padding: 0;
  margin: 0;
  width: 26px;
  height: 25px;
  background: none;
}

.login-error-notification {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -120%);
  width: 90%;
  height: 50px;
  padding: 12px 20px;
  border-radius: 8px;
  border: 2px solid rgba(231, 76, 60, 0.72);
  background: rgba(231, 76, 60, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.login-forgot-pwd-link {
  width: 100%;
  text-align: right;
  position: relative;
  bottom: 58px;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.login-forgot-pwd-link-a {
  font-family: Inter;
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
  color: #0e0333;
}

.login-container-btn {
  margin: 0 auto;
}

.btn-login {
  position: absolute;
  left: 31%;
  bottom: 48px;
  padding: 0 12px 0 12px;
  border: 1px solid rgba(44, 46, 74, 0.64);
  border-radius: 8px;
  height: 42px;
  min-width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}

.btn-inactive-login {
  transition: all 0.1s linear;
  background-color: #ffffff;
  color: #818aa6;
}

.btn-active-login {
  background-color: #0e0333;
  color: #ffffff;
  cursor: pointer;
  border: 1px #0e0333 solid;
}

// rules outside of @media queries apply to devices 1600px and above
// rules here apply to devices from 1024px to 1600px (inclusive)
@media only screen and (max-width: 1600px) {
  .login-initial-pages-container {
    height: 400px;
    width: 446px;
    padding: 50px 38px 34px 38px;
  }

  .login-input-section {
    margin: 0 0 10px 0;
  }

  .login-input-section label {
    margin: 0 0 10px 0;
    font-size: 15px;
  }

  .login-input {
    height: 46px;
    padding: 0 15px;
    margin: 0 0 0 0;
    font-size: 13px;
  }

  .login-error-notification {
    height: 52px;
    padding: auto 20px;
    font-size: 15px;
  }

  .login-forgot-pwd-link {
    bottom: 45px;
  }

  .login-forgot-pwd-link-a {
    font-size: 14px;
    font-weight: 400;
  }
}

// rules here apply to devices from 0px to 1024px (inclusive)
@media only screen and (max-width: 1024px) {
  .login-initial-pages-container {
    height: 380px;
    width: 420px;
    padding: 50px 38px 34px 38px;
  }

  .login-input-section label {
    margin: 0 0 5px 0;
    font-size: 15px;
  }

  .login-input {
    height: 46px;
    margin: 0 0 0 0;
    font-size: 13px;
  }

  .login-input-section {
    margin: 0 0 5px 0;
  }

  .login-show-password {
    bottom: 40%;
  }

  .login-forgot-pwd-link {
    bottom: 44px;
    text-underline-offset: 3px;
  }

  .login-forgot-pwd-link-a {
    font-size: 14px;
    font-weight: 300;
  }

  .login-error-notification {
    height: 52px;
    font-size: 15px;
    font-weight: 300;
  }
}

// tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .login-initial-pages-container {
    width: 446px;
    height: 378px;
    padding: 45px 38px 34px 38px;
  }

  .login-input-section {
    margin: 0 0 0 0;
  }

  .login-input-section label {
    margin: 0 0 7px 0;
    font-size: 15px;
  }

  .login-input {
    height: 46px;
    padding: 10px 15px;
    font-size: 13px;
  }

  .login-error-notification {
    padding: auto 15px;
    font-size: 15px;
  }

  .login-forgot-pwd-link {
    bottom: 46px;
  }

  .login-forgot-pwd-link-a {
    font-size: 13px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .login-initial-pages-container {
    width: 446px;
    height: 378px;
    padding: 45px 38px 34px 38px;
  }

  .login-input-section {
    margin: 0 0 0 0;
  }

  .login-input-section label {
    margin: 0 0 7px 0;
    font-size: 15px;
  }

  .login-input {
    height: 46px;
    padding: 10px 15px;
    font-size: 13px;
  }

  .login-show-password {
    bottom: 39%;
  }

  .login-error-notification {
    padding: auto 15px;
    font-size: 15px;
  }

  .login-forgot-pwd-link {
    bottom: 40px;
  }

  .login-forgot-pwd-link-a {
    font-size: 13px;
  }

  .btn-login {
    bottom: 35px;
    padding: 0 15px;
    width: 110px;
    height: 42px;
    font-size: 15px;
  }
}

// phone
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .login-initial-pages-container {
    width: 346px;
    height: 350px;
    padding: 25px 20px 25px 20px;
  }

  .login-input-section {
    margin: 0 0 0 0;
  }

  .login-input-section label {
    margin: 0 0 7px 0;
    font-size: 14px;
  }

  .login-input {
    height: 46px;
    padding: 0 15px;
    font-size: 13px;
  }

  .login-error-notification {
    height: 40px;
    padding: auto 20px;
    font-size: 14px;
  }

  .login-forgot-pwd-link {
    bottom: 50px;
  }

  .login-forgot-pwd-link-a {
    font-size: 12px;
  }

  .login-container-btn {
    margin: 0 auto;
  }

  .btn-login {
    bottom: 28px;
    padding: 15px 32px;
    width: 110px;
    height: 42px;
    font-size: 14px;
  }
}
