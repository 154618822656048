.info-box {
  z-index: 2;
  height: 52px;
  width: auto;
  padding: 15px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 150%);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 8px;
  background: #0e0333;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  cursor: default;
}

.info-box-active {
  cursor: pointer;
}

.info-box img {
  margin-right: 15px;
}

.notification-container-global {
  z-index: 20;
  font-family: Inter;
  font-size: 18px;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  padding: 22px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.notification-cross {
  position: absolute;
  right: 16px;
}
