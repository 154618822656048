.custom-select {
  width: 201px;
  height: 56px;
  border: none;
  border-bottom: 2px solid #646779;
  text-align: left;
  padding: 18px 13px;
  position: relative;
  background: #f5f7fb;
  font-size: 16px;
  cursor: pointer;
}

.custom-select-arrow {
  position: absolute;
  right: 13px;
  top: 14px;
}

.custom-dropdown {
  position: absolute;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}

.custom-dropdown-option {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  background: none;
  border: none;
  width: 100%;
  height: 42px;
  color: #0e03338f;
  padding: 12px 16px;
  text-align: left;
}

.custom-dropdown-option:hover {
  color: #0e0333;
  background: #f2f6ff;
}
