.first-tour-container {
    display: flex;
    flex-direction: column;
    width: 47%;
    right: 0px;
    position: absolute;
    top: 80px;
    gap: 35px;
    height: 80%;
}

.first-tour-container-users {
    display: flex;
    flex-direction: column;
    width: 47%;
    right: 0px;
    position: absolute;
    top: 80px;
    justify-content: center;
    gap: 35px;
    height: 65%;
}

.title-first-tours {
    font-family: Inter;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0333;
    width: 100%;
}

.text-first-tours {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    width: 75%;
    color: #0e0333;
}

.text-start {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #757575;
    width: 41%;
}

.empty-tours-container {
    position: absolute;
    text-align: center;
    margin: 3rem auto;
    width: 90%;
    height: 70%;
    background: #ffffff;
    border-radius: 4px;
    padding: 45px 34px;
    box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
}

.empty-search-container {
    position: absolute;
    text-align: center;
    width: 90%;
    height: 70%;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.16);
    padding: 18px 22px;
}

.we-cannot-find {
    width: 100%;
    height: 100%;
}

.new-tours {
    width: 50%;
    position: absolute;
    left: 10px;
    height: 90%;
}

.container-button {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 60%;
    gap: 20px;
}

.btn-empty-create-tour {
    color: white;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 22px;
    height: 42px;
    width: 173px;
    background-color: #0e0333;
    box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border: none;
    cursor: pointer;
}

.example-tour {
    text-decoration: none;
}

.btn-view-tutorial {
    color: #0e0333;
    border: 1px solid #0e0333;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 22px;
    height: 42px;
    width: 173px;
    background-color: white;
    box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.title-tours {
    font-family: Inter;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0333;
}

.text-tours {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    width: 200px;
    text-align: left;
    color: #757575;
}

.no-tours-container {
    display: flex;
    flex-direction: column;
    width: 24%;
    right: 118px;
    position: absolute;
    gap: 20px;
    top: 78px;
}

@media (min-width: 1750px) {
    .empty-tours-container {
        height: 563px;
        position: relative;
        top: 70px;
    }
}

// rules outside of @media queries apply to devices 1600px and above
// rules here apply to devices from 1024px to 1600px (inclusive)
@media only screen and (max-width: 1600px) {
}

// rules here apply to devices from 0px to 1024px (inclusive)
@media only screen and (max-width: 1024px) {
    .title-tours {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
    }

    .no-tours-container {
        display: flex;
        flex-direction: column;
        width: 24%;
        right: 70px;
        position: absolute;
        grid-gap: 20px;
        gap: 20px;
        top: 78px;
    }
}

// tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .empty-tours-container {
        top: 130px;
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}
