.modal-tripod-container {
  width: 100vw;
  height: 100vh;
  background: #0e03334d;
  position: absolute;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-tripod-box {
  width: 80%;
  height: 90%;
  background: #fff;
  border-radius: 16px;
  position: relative;
}

.tripod-popup-title {
  position: relative;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background: #0e0333;
  display: flex;
  height: 56px;
  display: flex;
  height: 56px;
  color: #fff;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tripod-popup-cross {
  position: absolute;
  right: 16px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.box-text-modal {
  display: flex;
  margin-top: 12px;
  flex-direction: column;
  gap: 5px;
}

.box-text-modal-config {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  gap: 5px;
}

.text-blue-modal {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #0e0333;
}

.text-grey-modal {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #757575;
}

.box-image {
  margin-top: 12px;
  width: 400px;
  height: 400px;
  margin-right: auto;
  margin-left: auto;
}

.tripod-image-container {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  background-size: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.tripod-image {
  width: 100%;
  height: 100%;
}

.manual-editing-tripod {
  position: relative;
  font-weight: 400;
  font-size: 14px;
  width: 250px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
}

.range-custom-snail-trail-tripod {
  pointer-events: none;
  width: 8px;
  height: 4px;
  background: #0e0333;
  border-radius: 1px;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.slider-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.slider-tripod {
  -webkit-appearance: none;
  width: 100%;
  height: 12px;
  border-radius: 12px;
  background: #b9bbcf;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}

.slider-tripod::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #d50057;
  cursor: pointer;
}

.slider-tripod::-moz-range-thumb {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #d50057;
  cursor: pointer;
}

.slider-value {
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 16px;
}

.tripod-mask {
  color: #d50057;
  border-radius: 50%;
  width: 30%;
  height: 30%;
  border: 4px #d50057 dashed;
  position: absolute;
}

.tripod-mask-square {
  color: #d50057;
  width: 30%;
  height: 30%;
  border: 4px #d50057 dashed;
  position: absolute;
}

.container-save-button {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-save {
  width: 206px;
  height: 42px;
  padding: 10px 32px 10px 32px;
  border-radius: 8px;
  gap: 10px;
  background-color: #0e0333;
  color: white;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}

.button-square {
  height: 42px;
  position: absolute;
  right: 270px;
  padding: 10px 32px 10px 32px;
  border-radius: 8px;
  background-color: #0e0333;
  color: white;
  cursor: pointer;
}
