.tittle {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  padding-top: 20px;
  border-radius: 15px 15px 0 0;
  padding-bottom: 30px;
  background: #0e0333;
  color: white;
  height: 56px;
}

.main-text {
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  height: 30%;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  white-space: pre-wrap;
}

.btn-accept {
  color: #fff;
  text-decoration: none;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  min-width: 170px;
  border: 1px #0e0333 solid;
  height: 42px;
  padding: 0 12px 0 12px;
  background-color: #0e0333;
  border-radius: 8px;
  cursor: pointer;
}

.btn-cancel {
  color: #0e0333;
  text-decoration: none;
  font-family: Inter;
  border: 1px #0e0333 solid;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  min-width: 170px;
  height: 42px;
  padding: 0 12px 0 12px;
  background-color: #fff;
  margin-right: 9%;
  margin-left: 4%;
  cursor: pointer;
  border-radius: 8px;
}

.modal-pop {
  background: #fff;
  border-radius: 20px;
  z-index: 999;
  max-width: 420px;
  margin: auto;
  width: 520px;
  cursor: default;
  // height: 199px;
  /* Center position */
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #000;
  opacity: 0.75;
}
