.MyDatePicker {
  height: 52px;
  width: 100%;
  background: #f2f6ff;
  display: flex;
  align-items: center;
  border: none;
  border-bottom: 2px solid rgba(44, 46, 74, 0.42);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
}
.MyDatePicker * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.MyDatePickerDisabled {
  height: 52px;
  width: 100%;
  background: #f2f6ff;
  display: flex;
  border: none;
  border-bottom: 2px solid rgba(44, 46, 74, 0.42);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  cursor: default;
  pointer-events: none;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.header-date-container {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.header-date-container input:focus {
  outline: none;
}

.header-date-container input {
  width: 100%;
  border: none;
  height: 32px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
}

.current-date {
  margin-left: 16px;
}

.calendar-click {
  position: absolute;
  right: 14px;
}
.calendar-click-disabled {
  position: absolute;
  right: 14px;
  bottom: 15px;
}

.mdp-container {
  float: left;
  bottom: 90px;
  position: absolute;
  width: 100%;
  height: 363px;
  background: #fff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.12);
  border: 1px solid #0e0333;
  border-radius: 8px;
  z-index: 999;
  overflow: hidden;
}
.mdpc-head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #0e0333;
  height: 53px;
  padding: 0 35px;
}
.mdpc-body {
  width: 100%;
}

.mdpch-button {
  height: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
}

.mdpchb-inner {
  cursor: pointer;
  font-size: 15px;
  color: #fff;
}

.mdpch-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}
.mdpchc-year {
  font-weight: 400;
  font-size: 18px;
  font-weight: 200px;
  text-align: center;
  color: #fff;
}
.mdpchc-month {
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #fff;
  margin-right: 5px;
}

/**
   *  Calendar
   */
.cc-month,
.cc-head,
.cch-name,
.cc-body,
.cdc-day span,
.cdc-day-next-month span,
.cdc-day,
.cdc-day-next-month,
.c-day-container,
.c-container {
  position: relative;
  display: block;
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.c-day-container-next-month {
  position: relative;
  display: block;
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: grey;
}

.c-container {
  width: 100%;
  height: 100%;
}

.cc-month {
  height: 30px;
  width: 100%;
  font-size: 16px;
  line-height: 30px;
  color: #666;
}
.cc-head {
  height: 30px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.cch-name {
  width: 100%;
  line-height: 30px;
  font-weight: 400;
  color: #000;
  font-size: 12px;
  text-align: center;
}

.cc-body {
  height: 270px;
  width: 100%;
}
.c-day-container {
  width: 14.285%;
  height: 18.6666%;
}

.c-day-container-next-month {
  width: 14.285%;
  height: 18.6666%;
}
.cdc-day {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
}

.cdc-day-next-month {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
}

.cdc-day span {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  font-size: 16px;
  color: #0e0333;
}
.cdc-day-next-month span {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.4);
}

.cdc-day span:hover {
  cursor: pointer;
  background: #eee;
}
.cdc-day span {
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  left: 50%;
  top: 50%;
  font-weight: 400;
  border-radius: 100%;
  line-height: 30px;
}
.cdc-day-next-month span {
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  left: 50%;
  top: 50%;
  font-weight: 400;
  border-radius: 100%;
  line-height: 30px;
}
.c-day-container.disabled {
  pointer-events: none;
}

.c-day-container.disabled .cdc-day span {
  color: #ddd;
}

.c-day-container.disabled .cdc-day span {
  background: #fff !important;
}

.c-day-container.highlight .cdc-day span {
  background: #efdbca;
}

.c-day-container.highlight-green .cdc-day span {
  background: #0e0333;
  color: #fff;
}
