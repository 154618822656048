.editor-modal-container-add {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  z-index: 2;
  cursor: default;
}

.upload-title-modal-image {
  margin-top: 10px;
  margin-bottom: 5px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #0e0333;
}

.upload-title-modal-image-second {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #757575;
  margin-bottom: 10px;
}

.content-modal-add {
  width: 1060px;
  padding: 8px 20px;
  height: 476px;
}

.content-modal-audio {
  width: 100%;
  padding: 8px 20px;
  height: 100%;
}

.modal-btn-container-add {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 25px;
}

.modal-btn-container-audio {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 35px;
  gap: 30px;
}

.title-add-modal {
  margin-bottom: 8px;
}

.viewer-drop-zone {
  overflow: hidden;
  background: #f2f6ff;
  width: 80%;
  height: 300px;
  border-radius: 16px;
  padding: 8px 10px;
  border: 1px solid rgba(200, 201, 209, 0.7);
}

.viewer-upload-preview {
  width: 80%;
  display: flex;
  margin-bottom: 25px;
  justify-content: space-evenly;
  overflow-x: hidden;
  flex-wrap: wrap;
  position: relative;
  padding-top: 15px;
  gap: 20px;
  height: 120px;
}

.viewer-upload-preview-floorplan {
  width: 80%;
  display: flex;
  margin-bottom: 25px;
  justify-content: space-evenly;
  overflow-x: hidden;
  flex-wrap: wrap;
  position: relative;
  padding-top: 15px;
  gap: 20px;
  height: 230px;
}

.viewer-preview-image-add {
  position: relative;
  width: 159px;
  height: 88px;
  border-radius: 8px;
}

.viewer-preview-image-add-floorplan {
  position: relative;
  width: 222px;
  height: 170px;
  border-radius: 8px;
  border: 1px solid rgba(200, 201, 209, 0.7);
  object-fit: contain;
}

.preview-images-container-viewer-add .btn-cross-add {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  left: 140px;
  bottom: 80px;
  height: 24px;
}

.preview-images-container-viewer-add:hover .btn-cross-add {
  visibility: visible;
}

.preview-images-container-viewer-add-container .btn-cross-add-container {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  right: -10px;
  top: -10px;
  height: 24px;
}

.preview-images-container-viewer-add-container:hover .btn-cross-add-container {
  visibility: visible;
}

.helper-text-image-upload {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  margin: 20px 0px;
  position: relative;
  text-align: center;
}

.select-an-image-file {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #0e0333;
  width: 174px;
  height: 32px;
  outline: none;
  border: 1px solid #0e0333;
  border-radius: 8px;
  margin: auto;
  background: #fff;
  display: block;
}

.editor-modal-header-title-add {
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.upload-images-editor-modal-btn {
  height: 42px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  padding: 10px 32px 10px 32px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  outline: none;
  border: #0e0333 1px solid;
  border-radius: 8px;
  background: #ffff;
  color: #818aa6;
  pointer-events: none;
}

.uploading-image-spinner-create-add {
  position: absolute;
  top: 25px;
  left: 65px;
  z-index: 2;
}

.editor-modal-btn-active-add {
  background: #0e0333;
  height: 42px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  padding: 10px 32px 10px 32px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  outline: none;
  border-radius: 8px;
  border: none;
  pointer-events: none;
  color: white;
  pointer-events: visible;
  cursor: pointer;
}

.btn-retry-add {
  z-index: 1;
  position: absolute;
  left: 140px;
  bottom: 80px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: white;
  border: none;
}

.media-drop-or-add {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  width: 50%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin: 8px auto;
}

.media-drop-or-add:before,
.media-drop-or-add:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 50%;
  height: 1px;
  content: '\a0';
  background-color: #e6ebf4;
}

.media-drop-or-add:before {
  margin-left: -50%;
  text-align: right;
}

// rules outside of @media queries apply to devices 1600px and above
// rules here apply to devices from 1024px to 1600px (inclusive)
@media only screen and (max-width: 1600px) {
}

// rules here apply to devices from 0px to 1024px (inclusive)
@media only screen and (max-width: 1024px) {
  .content-modal-add {
    width: 720px;
    padding: 8px 8px;
  }

  .viewer-upload-preview {
    padding-top: 12px;
    gap: 10px;
  }

  .helper-text-image-upload {
    font-size: 18px;
  }

  .media-drop-or-add {
    font-size: 16px;
  }

  .media-drop-or-add:before,
  .media-drop-or-add:after {
    height: 2px;
  }

  .select-an-image-file {
    font-size: 18px;
  }
}

// tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .content-modal-add {
    width: 720px;
    padding: 8px 8px;
  }

  .viewer-upload-preview {
    padding-top: 20px;
  }

  .helper-text-image-upload {
    font-size: 18px;
  }

  .select-an-image-file {
    font-size: 18px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .content-modal-add {
    width: 800px;
    padding: 8px 8px;
  }

  .viewer-upload-preview {
    padding-top: 20px;
  }

  .helper-text-image-upload {
    font-size: 18px;
  }

  .select-an-image-file {
    font-size: 18px;
  }
}
