.full-screen {
  z-index: 2;
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: #00000065;
}

.modal {
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 43.5vw;
  height: 41vh;
  background: #ffffff;
  text-align: center;
  border-radius: 16px;
}

.modal-header {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #0e0333;
  height: 67px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;

  .modal-header-title {
    font-size: 1.25rem;
    color: white;
  }
  button {
    height: 20px;
    width: 20px;
    position: fixed;
    right: 1vw;
  }

  button:hover {
    transition: all 0.1s linear;
    transform: scale(1.1);
    color: white;
  }
}

.modal-body {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  width: 100%;
  height: calc(39vh - 8vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.modal-body-floorplan {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  width: 100%;
  height: 87%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.modal-body-share {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
  height: calc(39vh - 8vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
}

.share-link-tabs {
  height: 4vh;
  margin: 0 auto;
  gap: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);

  li {
    font-size: 1.125rem;

    height: 4vh;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }
  li.current {
    font-weight: 700;

    height: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #0e0333;
  }
}

.share-link-container {
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0vh 4vw 3vh 4vw;
  margin: 0;
}

.share-link-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .share-link-text {
    margin-top: 0.5vh;
    margin-bottom: 2.5vh;
    font-size: 1.125rem;
    color: #0e0333;
    width: 80%;
    cursor: default;
  }

  .ellipsis-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .share-link-copy {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .copy-link-input {
      width: 85%;
      height: 42px;
      padding: 1vh 1vw;
      margin-top: 0;
      background: #f2f6ff;
      border: none;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom: 1px solid #0e0333;
      font-size: 1rem;
      color: #0e0333;
      cursor: default;
    }

    .copy-link-icon {
      margin-top: 0.5vh;
    }
  }

  .copy-email-button {
    width: 173px;
    background: #0e0333;
    color: white;
    height: 42px;
    border-radius: 8px;
    cursor: pointer;
  }

  .share-link-copy-media {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    height: 90px;
  }

  .share-link-social-media {
    display: flex;
    justify-content: center;
    gap: 30px;
  }

  .share-link-email {
    width: 91%;
    display: flex;
    flex-direction: column;
    gap: 18px;
    align-items: center;
    font-size: 14px;

    .email-link-input {
      width: 95%;
      height: 42px;
      padding: 1vh 1vw;
      background: #f2f6ff;
      border: none;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom: 1px solid #0e0333;
      font-size: 14px;
    }
  }

  .share-link-embed {
    .embed-link-input {
      width: 38vw;
      height: 20vh;
      background: #f2f6ff;
      border-radius: 8px;
      border: none;
      resize: none;
      padding: 2.5vh 1.4vw;
      margin-top: 0rem;
      font-size: 0.875rem;
      outline: none;
      cursor: default;
    }

    .copy-link-embed-icon {
      position: fixed;
      bottom: 4vh;
      right: 3.5vw;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      height: 32px;
      border: 1px solid #0e0333;
      border-radius: 8px;
      width: 90px;
    }
  }
}

.share-link-email-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;

  .share-link-text {
    margin-top: 0.5vh;
    margin-bottom: 1.25vh;
    font-size: 1.125rem;
    width: 80%;
    color: #0e0333;
    cursor: default;
  }

  .ellipsis-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .share-link-copy {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .copy-link-input {
      width: 85%;
      height: 42px;
      padding: 1vh 1vw;
      margin-top: 0;
      background: #f2f6ff;
      border: none;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom: 1px solid #0e0333;
      font-size: 1rem;
      cursor: default;
      color: #0e0333;
    }
  }

  .copy-email-button {
    width: 173px;
    background: #0e0333;
    color: white;
    height: 42px;
    border-radius: 8px;
    cursor: pointer;
  }

  .share-link-copy-media {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    height: 90px;
  }

  .share-link-social-media {
    display: flex;
    justify-content: center;
    left: 10%;
    gap: 30px;

    .social-media-icon svg {
      width: 3.2vw;
      height: 6.8vh;
      padding: 0;
    }
  }

  .share-link-email {
    width: 91%;
    display: flex;
    flex-direction: column;
    gap: 22px;
    align-items: center;
    font-size: 14px;

    .email-link-input {
      width: 95%;
      height: 42px;
      padding: 1vh 1vw;
      background: #f2f6ff;
      border: none;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom: 1px solid #0e0333;
      font-size: 14px;
    }
  }

  .share-link-embed {
    .embed-link-input {
      width: 38vw;
      height: 18vh;
      background: #e6ebf4;
      border-radius: 4px;
      border: none;
      resize: none;
      padding: 2.5vh 1.4vw;
      margin-top: 0rem;
      font-size: 0.875rem;
      outline: none;
    }
    .copy-link-embed-icon {
      position: fixed;
      bottom: 4.4vh;
      right: 3.2vw;
    }
  }
}

.copy-active path {
  fill: #0e0333;
}
.copy-inactive path {
  fill: #828596;
}

.email-link-button,
.protect-password-button,
.remove-protect-password-button,
.delete-tour-button {
  min-width: 170px;
  height: 42px;
  font-size: 1rem;
  padding: 0 12px;
  border-radius: 8px;
}

.btn-active {
  background-color: #0e0333;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.btn-inactive {
  background-color: #fff;
  color: #818aa6;
  border: 1px solid #0e0333;
}

.password-protect-text {
  width: 30vw;
  font-size: 1.125rem;
  color: #0e0333;
  cursor: default;
}

.password-protect-input-container {
  width: 17vw;
  position: relative;

  .password-protect-input {
    width: 16.4vw;
    height: 4.8vh;
    font-size: 1rem;
    padding: 0.625rem 2.25rem 0.625rem 1rem;
    background: #f2f6ff;
    border: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 2px solid rgba(128, 130, 146, 1);
  }
  .password-protect-show-icon {
    position: absolute;
    top: 1vh;
    right: 0.75vw;
  }
}

.delete-tour-container {
  height: 100%;
  width: 94%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: #0e0333;
  cursor: default;

  .delete-tour-input {
    width: 30vw;
    text-align: left;
    height: 5vh;
    padding: 1vh 1vw;
    background: #e6ebf4;
    border: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 2px solid #808292;
    font-size: 1rem;
  }
}

.alert-info-message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26vw;
  height: 8vh;
  border-radius: 8px;
  background: rgba(26, 188, 156, 0.08);
  border: 2px solid rgba(26, 188, 156, 0.72);

  #alert-icon {
    width: 1.8vw;
    height: auto;
  }
  #alert-text {
    margin-left: 1vw;
    font-size: 1.125rem;
  }
}

.remove-default {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.grid-container {
  display: flex;
  justify-content: center;
}

.download-select-grid {
  -webkit-appearance: none;
  appearance: none;
  padding: 2px 7px;
  font-weight: 400;
  font-size: 15px;
  height: 32px;
  border: none;
  color: #ffffffcc;
  background: #00000080;
  border-radius: 8px;
  outline: none;
}

.grid-container {
  position: absolute;
  pointer-events: none;
  height: 100%;
  width: 100%;
  display: flex;
}

.grid {
  position: absolute;
  height: 100%;
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  z-index: 10;
}

.grid-box {
  border: 2px solid #0e0333;
}

.outside-area {
  height: 100%;
  z-index: 10;
  background: #00000040;
}

@media only screen and (max-width: 1367px) {
  .share-link-content {
    .share-link-embed {
      .copy-link-embed-icon {
        bottom: 9.5vh;
        right: 4.5vw;
      }
    }
  }

  .share-link-tabs li {
    width: 10vw;
  }

  .share-link-tabs li.current {
    width: 10vw;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .modal {
    width: 60vw;
    height: 30vh;
  }
  .modal-header {
    height: 6vh;
    .modal-header-title {
      font-size: 1.125rem;
    }
    button {
      top: 2.25vh;
      right: 2vw;
    }
  }
  .modal-body {
    height: calc(30vh - 6vh);
  }
  .share-link-tabs {
    color: #0e0333;
    width: 100%;
    height: 5vh;
    li {
      font-size: 0.875rem;
      width: 11vw;
    }
    li.current {
      width: 11vw;
    }
  }
  .share-link-container {
    width: 100%;
    height: 19vh;
    padding: 0vh 4vw 2vh 4vw;
  }

  .share-link-content {
    height: 17vh;
    justify-content: flex-start;

    .share-link-text {
      margin-top: 1vh;
      font-size: 0.875rem;
      margin-bottom: 1.5vh;
      cursor: default;
    }

    .share-link-social-media {
      bottom: 2vh;
      width: 20vw;

      .social-media-icon svg {
        width: 6vw;
        height: auto;
        padding: 1px;
      }
    }

    .share-link-email {
      width: 50vw;
      margin: 1vh auto 0 auto;

      .email-link-input {
        width: 30vw;
        height: 4vh;
      }
    }

    .share-link-embed {
      .embed-link-input {
        width: 45vw;
        height: 16vh;
        padding: 1.5vh 3vw;
        margin-top: 0.25rem;
      }
      .copy-link-embed-icon {
        bottom: 2.1rem;
        right: 4.25rem;
      }
    }
  }

  .email-link-button,
  .protect-password-button,
  .remove-protect-password-button,
  .delete-tour-button {
    width: 18vw;
    height: 4vh;
    font-size: 0.875rem;
  }

  .password-protect-text {
    width: 50vw;
    font-size: 1rem;
  }

  .password-protect-input-container {
    width: 35vw;

    .password-protect-input {
      width: 35vw;
      height: 4vh;
    }
    .password-protect-show-icon {
      right: 1vw;
    }
  }

  .delete-tour-container {
    width: 96%;

    .delete-tour-input {
      width: 45vw;
      height: 4vh;
    }
  }

  .alert-info-message {
    width: 40vw;
    height: 7vh;

    #alert-icon {
      width: 2rem;
    }
    #alert-text {
      margin-left: 0.5rem;
      font-size: 0.875rem;
    }
  }
}

@media only screen and (min-width: 1600px) {
  .share-link-content .share-link-copy-media {
    align-items: flex-end;
  }

  .modal-body-share {
    gap: 40px;
  }

  .share-link-email-container .share-link-email {
    gap: 46px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .modal {
    width: 50vw;
    height: 45vh;
  }

  .modal-body {
    height: calc(45vh - 8vh);
    justify-content: space-evenly;
  }

  .share-link-tabs {
    width: 80%;
    height: 8vh;

    li {
      font-size: 1rem;
    }
  }

  .share-link-container {
    height: calc(45vh - 16vh);
    padding: 0vh 2vw 3vh 2vw;
  }

  .share-link-content {
    height: calc(45vh - 18vh);
    justify-content: flex-start;

    .share-link-text {
      margin-top: 1rem;
      margin-bottom: 2rem;
      font-size: 1.125rem;
      cursor: default;
    }

    .share-link-copy {
      width: 35vw;

      .copy-link-input {
        width: 25vw;
      }
    }

    .share-link-social-media {
      bottom: 2vh;
      width: 14vw;

      .social-media-icon svg {
        width: 5vw;
        height: auto;
        padding: 2px;
      }
    }

    .share-link-email {
      width: 42vw;
      margin: 3vh auto 0 auto;

      .email-link-input {
        width: 24vw;
      }
    }

    .share-link-embed {
      .embed-link-input {
        width: 40vw;
        height: 25vh;
        padding: 2vh 3vw 2vh 1vw;
        margin-top: 0.5rem;
        font-size: 1rem;
      }
      .copy-link-embed-icon {
        bottom: 1.75rem;
        right: 4.5rem;
      }
    }
  }

  .email-link-button,
  .protect-password-button,
  .remove-protect-password-button,
  .delete-tour-button {
    width: 16vw;
  }

  .password-protect-text {
    width: 35vw;
  }
  .password-protect-input-container {
    width: 35vw;

    .password-protect-input {
      width: 30vw;
      height: 5vh;
      font-size: 1rem;
      padding: 0.625rem 2.5rem 0.625rem 1rem;
    }
    .password-protect-show-icon {
      right: 3.5vw;
    }
  }
  .delete-tour-container {
    width: 96%;
    .delete-tour-input {
      width: 35vw;
    }
  }

  .alert-info-message {
    width: 40vw;
    height: 10vh;

    #alert-icon {
      width: 2rem;
    }
    #alert-text {
      margin-left: 2rem;
    }
  }
}

.move-tour-input {
  width: 20vw;
  text-align: left;
  height: 5vh;
  padding: 1vh 1vw;
  background: #f2f6ff;
  border: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid #0e0333;
  font-size: 1rem;
}

.move-tour-input:focus {
  border-bottom: 2px solid #0038ff;
}
